import { WsWeddingStates } from 'entities/wedding';
import {
  Ceremony,
  Certificate,
  Connection,
  FianceCode,
  Home,
  Models,
  Payment,
  RingPrice,
  VowsSuccess,
  RingsPreview,
  SelectRing,
  SelectWitness,
  WitnessConnection, RedirectToUrl, RedirectToUrlAnother, RedirectToTelegramBot, Partnership, Partnershipeng, VowsAdd, CheckPromo
} from 'pages';
import { RoutesProps } from 'shared/types';
import {Chakras} from "../../../pages/home/Chakras";

export const routes: RoutesProps = {
  home: {
    path: '/',
    component: <Home />,
  },
  partnership: {
    path: '/venues',
    component: <Partnership />,
  },
  partnershipeng: {
    path: '/venues/eng',
    component: <Partnershipeng />,
  },
  connection: {
    path: '/connection',
    component: <Connection />,
    isProtected: true,
  },
  fianceCode: {
    path: '/fiance-code',
    component: <FianceCode />,
    isProtected: true,
  },
  model: {
    path: '/model',
    component: <Models />,
    isProtected: true,
  },
  witnessConnection: {
    path: '/invite',
    component: <WitnessConnection />,
  },
  selectRing: {
    path: '/select-ring',
    component: <SelectRing />,
    isProtected: true,
  },
  ringPrice: {
    path: '/ring-price',
    component: <RingPrice />,
    isProtected: true,
  },
  ringsPreview: {
    path: '/rings-preview',
    component: <RingsPreview />,
    isProtected: true,
  },
  payment: {
    path: '/payment',
    component: <Payment />,
    isProtected: true,
  },
  chakras: {
    path: '/chakras',
    component: <Chakras />,
  },
  checkPromo: {
    path: '/check-promo',
    component: <CheckPromo />,
    isProtected: true,
  },
  vows: {
    path: '/vows',
    component: <VowsSuccess />,
    isProtected: true,
  },
  vowsAdd: {
    path: '/vows-add',
    component: <VowsAdd />,
    isProtected: true,
  },
  witness: {
    path: '/witness',
    component: <SelectWitness />,
    isProtected: true,
  },
  ceremony: {
    path: '/ceremony',
    component: <Ceremony />,
    isProtected: true,
  },
  certificate: {
    path: '/certificate',
    component: <Certificate />,
    isProtected: true,
  },
  marriageOverview: {
    path: 'video/marriage-overview',
    component: <RedirectToUrl />,
  },
  ag: {
    path: '/ag',
    component: <RedirectToTelegramBot />,
  },
  marriageBenefits: {
    path: 'video/marriage-benefits',
    component: <RedirectToUrl />,
  },
  vowsLove: {
    path: 'video/vows-love',
    component: <RedirectToUrl />,
  },
  vowsGuide: {
    path: 'video/vows-guide',
    component: <RedirectToUrl />,
  },
  quantumLove: {
    path: 'video/quantum-love',
    component: <RedirectToUrl />,
  },
  weddingIdeas: {
    path: 'video/wedding-ideas',
    component: <RedirectToUrl />,
  },
  weddingHowto: {
    path: 'video/wedding-howto',
    component: <RedirectToUrl />,
  },
  marriageGuide: {
    path: 'video/marriage-guide',
    component: <RedirectToUrl />,
  },
  weddingAll: {
    path: 'video/*',
    component: <RedirectToUrlAnother />,
  },
};

export const wsWeddingRoutes = {
  [WsWeddingStates.init]: routes.connection.path,
  [WsWeddingStates.selectCollection]: routes.selectRing.path,
  [WsWeddingStates.selectDiamond]: routes.ringPrice.path,
  [WsWeddingStates.showRoom]: routes.ringsPreview.path,
  [WsWeddingStates.vows]: routes.vows.path,
  [WsWeddingStates.vowsAdd]: routes.vowsAdd.path,
  [WsWeddingStates.payment]: routes.payment.path,
  [WsWeddingStates.selectWitness]: routes.witness.path,
  [WsWeddingStates.ceremony]: routes.ceremony.path,
  [WsWeddingStates.final]: routes.certificate.path,
};
