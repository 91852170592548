import { toast } from 'react-toastify';
import { weddingActions } from 'entities/wedding';
import { error, request, socket, success } from 'shared/api';
import { Emits } from 'shared/types';
import { SagaPayload } from 'shared/types/globals';
import { logger, snakeize } from 'shared/utils';
import { put, takeLatest } from 'typed-redux-saga';

export function* selectVowsSaga({ type, payload }: SagaPayload<typeof weddingActions.selectVows>) {
    yield* put(request(type));
    try {
        const data = { emit: Emits.VowsStart, vowsData: { text: payload.text, videoUrl: payload.videoUrl } };

        console.log('selectVows',data)
        socket?.send(JSON.stringify(data));

        yield* put(success(type));
    } catch (err) {
        yield* put(error(type, err));
        toast.error('Something went wrong');
        logger('selectVows', err);
    }
}

export default function* listener() {
    yield takeLatest(weddingActions.selectVows.type, selectVowsSaga);
}
