import { FC } from 'react';
import { SvgIcon, SvgIconProps } from '@mui/material';

export const InfinityNewIcon: FC<SvgIconProps> = ({ sx, ...props }) => (
    <SvgIcon
        {...props}
        viewBox="0 0 7000 3500"
        sx={{
            width: '190px',
            height: '80px',
            color: '#fff',
            ...sx,
        }}
    >
        <path d="M5750.207,2595.258c-74.972,0-151.683-3.232-229.987-9.708c-232.332-19.216-476.782-66.594-726.562-140.819
		c-210.073-62.427-422.757-144.073-615.055-236.113c-455.216-217.882-774.991-325.146-1038.981-338.647
		c-43.535,26.753-85.946,51.211-128.133,73.029c-120.113,62.122-230.814,98.259-348.382,113.724
		c-65.103,8.565-132.411,10.981-205.771,7.379c-75.287-3.694-155.178-13.624-244.24-30.357
		c-176.881-33.232-392.187-94.599-658.223-187.603c-567.171-198.28-937.072-156.884-1147.506-87.244
		c-118.814,39.321-200.406,91.59-247.932,128.515c-51.497,40.011-75.213,71.133-75.446,71.443
		c-4.153,5.508-11.985,6.615-17.499,2.471c-5.513-4.145-6.63-11.966-2.492-17.483c1.005-1.341,25.235-33.307,78.663-75.053
		c48.889-38.198,132.773-92.269,254.758-132.931c136.638-45.546,292.411-65.393,463-58.992
		c212.599,7.978,449.022,56.99,702.704,145.675c866.855,303.048,1166.619,261.479,1530.32,46.015
		c-70.761-0.678-137.657,5.624-202.371,18.585c-71.928,34.614-141.715,62.766-210.498,84.995
		c-135.969,43.942-244.045,54.009-340.12,31.673c-84.248-19.586-164.212-66.253-231.245-134.956
		c-38.466-39.426-70.934-85.257-96.501-136.22c-24.886-49.608-42.661-102.89-52.83-158.368
		c-10.169-55.476-12.449-111.599-6.777-166.809c5.828-56.717,19.928-111.084,41.911-161.591
		c21.982-50.507,52.16-97.876,89.697-140.793c36.538-41.775,79.165-78.354,126.694-108.718
		c47.53-30.364,98.637-53.667,151.901-69.262c54.72-16.02,110.388-23.496,165.455-22.209
		c78.833,1.837,161.152,20.494,244.671,55.453c71.453,29.907,143.206,70.934,219.36,125.425
		c155.27,111.104,291.533,249.038,423.309,382.429c11.366,11.505,22.781,23.06,34.249,34.64c1.545-1.112,3.094-2.227,4.639-3.338
		c203.223-146.218,413.364-297.414,645.292-398.766c149.148-65.178,281.33-96.905,403.831-96.905c1.876,0,3.737,0.007,5.607,0.023
		c98.604,0.781,188.817,23.07,268.136,66.249c81.181,44.192,147.693,108.566,197.691,191.335
		c58.446,96.754,91.482,222.572,90.637,345.189c-0.44,64.073-9.922,124.649-28.177,180.045
		c-19.685,59.729-49.068,111.638-87.336,154.281c-40.771,45.434-92.183,84.749-152.804,116.855
		c-57.043,30.21-120.522,53.197-188.678,68.324c-66.199,14.693-133.796,21.341-200.882,19.746
		c-67.784-1.606-131.633-11.546-189.774-29.544c-126.298-39.099-253.79-108.718-389.76-212.838
		c-112.302-85.994-217.61-185.946-315.1-282.336c-28.094,19.988-56.367,39.627-84.732,58.587
		c-22.3,14.905-44.122,29.563-65.226,43.739c-66.362,44.576-128.103,86.049-187.368,123.571
		c54.352,4.422,110.883,12.659,170.519,24.795c220.79,44.933,488.51,144.778,842.529,314.224
		c185.019,88.557,859.351,385.794,1567.623,385.794c404.106,0,819.171-96.692,1160.936-384.085
		c5.283-4.443,13.168-3.763,17.612,1.521c4.442,5.283,3.762,13.169-1.522,17.611c-96.631,81.259-203.959,150.793-319.001,206.673
		c-108.338,52.624-226.084,94.389-349.97,124.134C6105.532,2576.024,5933.074,2595.257,5750.207,2595.258z M3829.546,1872.816
		c122.704,95.917,253.607,175.43,396.381,219.628c213.579,66.115,536.103,21.278,706.136-168.196
		c153.079-170.586,130.652-470.162,22.085-649.892c-94.718-156.8-252.622-243.991-444.627-245.512
		c-120.5-0.976-251.083,30.051-399.229,94.792c-229.494,100.289-438.539,250.696-640.702,396.15
		c-0.47,0.338-0.942,0.678-1.413,1.017c19.702,19.827,39.576,39.697,59.622,59.46c41.796-30.18,83.176-60.934,123.823-91.142
		c132.283-98.312,269.069-199.972,415.457-278.699c181.053-97.371,451.885-157.095,648.809-71.023
		c60.478,26.434,115.093,67.089,157.938,117.571c39.157,46.136,69.247,100.242,89.435,160.813
		c19.464,58.399,28.762,119.737,27.639,182.312c-2.422,134.915-54.105,261.182-141.798,346.425
		c-91.84,89.276-209.449,123.732-291.938,136.916c-38.38,6.134-77.373,9.195-116.885,9.196
		c-113.42,0.002-231.183-25.224-351.522-75.423C4002.369,1981.172,3915.647,1932.837,3829.546,1872.816z M3545.897,1598.029
		c48.644,47.531,98.378,94.28,149.491,138.46c204.613,176.857,519.534,376.321,857.828,322.258
		c78.927-12.615,191.305-45.435,278.458-130.155c82.999-80.682,131.924-200.578,134.228-328.949
		c2.186-121.701-38.322-240.706-111.138-326.498c-40.396-47.596-91.882-85.924-148.891-110.841
		c-189.225-82.706-451.231-24.369-626.954,70.133c-144.781,77.864-280.823,178.97-412.386,276.748
		C3626.93,1538.619,3586.631,1568.568,3545.897,1598.029z M2516.534,929.742c-210.623,0-414.437,132.731-498.598,326.101
		c-85.71,196.928-40.817,441.828,109.169,595.552c63.691,65.278,139.424,109.563,219.011,128.064
		c91.471,21.265,195.303,11.379,326.771-31.111c16.045-5.186,32.002-10.654,47.829-16.373
		c-76.407,16.663-155.931,21.143-233.381,12.901c-105.805-11.257-203.749-46.236-283.244-101.158
		c-97.772-67.549-161.503-175.83-179.453-304.896c-16.955-121.901,9.615-249.508,72.895-350.102
		c58.048-92.276,147.9-160.9,259.842-198.449c104.029-34.896,220.622-39.854,328.305-13.963
		c82.809,19.911,165.573,53.017,248.915,99.74c-144.203-95.271-277.565-143.168-406.417-146.171
		C2524.299,929.786,2520.409,929.742,2516.534,929.742z M2544.446,984.531c-175.515,0-339.523,80.428-425.752,217.5
		c-116.956,185.92-104.841,479.867,99.607,621.118c150.622,104.062,365.539,129.845,560.885,67.288
		c34.681-11.106,69.256-20.2,104.055-27.341c189.594-91.781,365.063-215.103,535.83-337.776
		c-22.996-23.939-46.127-48.486-69.247-73.489c-232.128-251.038-451.283-398.631-669.989-451.217
		C2634.705,989.764,2589.185,984.531,2544.446,984.531z M3436.618,1543.497c-112.01,80.485-227.395,162.331-345.372,234.133
		c-43.227,26.307-85.442,50.404-126.874,72.399c25.705-3.023,51.659-5.035,77.972-6.054c29.828-1.154,60.156-1.04,91.125,0.353
		c67.861-41.891,138.653-89.444,216.307-141.606c21.115-14.183,42.949-28.848,65.273-43.77
		c26.951-18.014,53.848-36.667,80.604-55.666C3476.158,1583.898,3456.45,1563.936,3436.618,1543.497z"/>
    </SvgIcon>
);


