import { Box, Typography } from '@mui/material';
import { Stack, styled } from '@mui/system';
import { format, getYear } from 'date-fns';
import { HeartWithUsers } from 'entities/heart-with-users';
import { User, UserRole } from 'entities/user';
import { weddingSelector } from 'entities/wedding';
import { logoImage } from 'shared/assets';
import { BORDER_MAIN_BOLD, BORDER_RADIUS_XXL } from 'shared/config/theme/common';
import { useShallowSelector } from 'shared/hooks';
import { PolygonIcon } from 'shared/ui/components';
import { useTranslation } from 'react-i18next';

export const BorderWrapper = styled(Stack)(({ theme }) => ({
  position: 'relative',
  border: BORDER_MAIN_BOLD,
  borderRadius: BORDER_RADIUS_XXL,
  alignItems: 'center',
  padding: theme.spacing(3, 4, 2.25),
  width: 380,
  minHeight: 660,
  [theme.breakpoints.down('md')]: {
    minWidth: 'unset',
      width: 380,
      minHeight: 660,
  },
  '*': {
    textAlign: 'center',
  },
}));
type CertificateUserProps = {
  firstName: string;
  lastName: string;
  avatar: string;
  ringImg: string;
};

type CertificateContainerProps = {
  femaleProps: CertificateUserProps;
  manProps: CertificateUserProps;
};
export const CertificateContainer = ({ femaleProps, manProps }: CertificateContainerProps) => {
  const { t, i18n } = useTranslation();
  const { witness, certificate, marriedAt } = useShallowSelector(weddingSelector.getWedding);
  const date = new Date(marriedAt || 0);
  console.log('femaleProps',femaleProps.ringImg);
  console.log('manProps',manProps.ringImg);
  console.log('witness',witness);
  const witnessDisplayData = witness
    ? {
        firstName: witness.user?.firstName || '',
        lastName: witness.user?.lastName || '',
        avatar: witness.user?.profileImage || '',
      }
    : null;
  return (
    <BorderWrapper pb={{xs: '120px'}} overflow="hidden">
      <Stack direction="row" justifyContent="space-between" width="100%">
        <Typography className="secondary">#{certificate?.externalTokenId || ''}</Typography>
          <Typography className="center primary">
              <Box component="img" src={logoImage} alt="web3wed logo" sx={{ width: 120, position: 'relative', left: '0', bottom: '0', mb: 'auto', mt: 'auto' }} />

          </Typography>
        <PolygonIcon />
      </Stack>
      <Stack spacing={2} alignItems="center" mt={3}>
        <Typography variant="h3" className="center primary" sx={{lineHeight: '35px', fontSize: {
                //md: '30px',
                xs: '22px',
            }, textTransform: 'uppercase',fontWeight: '400'}}>
          {t('certificate_Container_1')} {t('certificate_Container_1_1')}
        </Typography>
        <Typography variant="h6" className="center" sx={{lineHeight: '35px',
        fontSize: {
            xs: '0.825rem',
        }}}>
            {t('certificate_Container_2')}
        </Typography>
      </Stack>
      <Stack spacing={2} alignItems="center" my={3} mt={{
          xs: 4.5,
          //md: 7
      }}>

        <HeartWithUsers size="mlarge" femaleProps={femaleProps} manProps={manProps} />
          {/*<Typography variant="body2" className="center">
            {t('certificate_Container_7')}
          <br />
          <Typography component="span" variant="h4" className="secondary blue" fontSize={30}>
              {t('certificate_Container_8')}
          </Typography>
        </Typography>
        <Typography variant="body2" className="center">
            {t('certificate_Container_9')} <br /> {t('certificate_Container_10')}
        </Typography>
        {witnessDisplayData ? (
          <>
            <User {...witnessDisplayData} role={UserRole.Witness} simple size="medium" />
            <Typography variant="subtitle1" className="primary" fontSize={16}>
                {t('certificate_Container_11')} {`${witnessDisplayData?.firstName || ''} ${witnessDisplayData?.lastName || ''}`}
            </Typography>
          </>
        ) : (
          <Typography variant="subtitle1" className="primary" fontSize={16}>
              {t('certificate_Container_12')}
          </Typography>
        )}*/}
      </Stack>
        {/*<Box component="img" src={femaleProps.ringImg} alt="ring" sx={{
            width: {
                xs: 250,
                md: 475,
            },
            height: {
                xs: 250,
                md: 475,
            },
            position: 'absolute',
            left: {
                xs: '-140px',
                md: '-220px',
            },
            bottom: {
                xs: '290px',
                md: '230px',
            },
            mb: '35px',
            mt: 'auto'
        }}/>
        <Box component="img" src={manProps.ringImg} alt="ring " sx={{

            width: {
                xs: 250,
                md: 475,
            },
            height: {
                xs: 250,
                md: 475,
            },
            position: 'absolute',
            right: {
                xs: '-140px',
                md: '-220px',
            },
            bottom: {
                xs: '290px',
                md: '230px',
            },
            mb: '35px',
            mt: 'auto'}} />*/}
        <Typography className="primary center" sx={{fontSize: '15px', mb: 2, position: 'absolute', bottom: '10px', display: 'flex', alignItems: 'center', flexDirection: 'column'}}>
            <Box
                component="img"
                src={certificate?.qrCodeUrl || ''}
                alt="qr"
                sx={{
                    width: 80,
                    height: 80,
                    borderRadius: '5px',
                    mb: '15px'
                }}
            />
            {format(date, 'do')} {t('certificate_Container_5')} {format(date, 'LLLL')} {getYear(date)}
        </Typography>
    </BorderWrapper>
  );
};
