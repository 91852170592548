import { weddingActions } from 'entities/wedding';
import { error, request, socket, success } from 'shared/api';
import { Emits } from 'shared/types';
import { SagaPayload } from 'shared/types/globals';
import {logger, snakeize} from 'shared/utils';
import { put, takeLatest } from 'typed-redux-saga';

export function* startEngagementSaga({ type }: SagaPayload<typeof weddingActions.startEngagement>) {
  yield* put(request(type));
  try {
    const data = { emit: Emits.EngagementStart };
    socket?.send(JSON.stringify(snakeize(data)));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('startEngagement', err);
  }
}

export default function* listener() {
  yield takeLatest(weddingActions.startEngagement.type, startEngagementSaga);
}
