import {
    StyledWhy,
    CardWrapper,
    FirstCardItem,
    SecondCardItem,
    ThirdCardItem,
    CardHeading,
    CardBottom,
    CardText,
    BlackCardText,
} from "./styles/StyledWhy";
import { useTranslation } from 'react-i18next';
import InstagramIcon from '@mui/icons-material/Instagram';

const WhyRestEng = () => {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <StyledWhy>
                <h2>Blockchain Marriage: A New Romantic Tradition</h2>
                <span>
            Now your restaurant can offer more than just a dinner or celebration; it can offer a unique moment for guests - the opportunity to record their love forever using blockchain technology.<br/>
            Couples celebrating an anniversary, wedding, or simply a special evening will not only receive excellent service but also the opportunity to preserve that moment in digital eternity by choosing your restaurant.
        </span>
            </StyledWhy>
            <StyledWhy>
                <h2>Your Restaurant as a Digital Registry Office</h2>
                <p>We offer you the opportunity to become the exclusive Web3Wed venue in your city.</p>
                <span>
            - Exclusive partner status for 1 year.<br/>
            - Trial period of 3 months with no obligations.<br/>
            - Flexibility – couples can register their marriage directly at your restaurant or later, in a comfortable setting.
        </span>
            </StyledWhy>
            <StyledWhy>
                <h2>How does the registration process work?</h2>
                <p>We do not store guest data, do not have access to their videos, and cannot change or delete anything.</p>
                <span>
            - Guests scan a QR code and access Web3Wed.io through convenient authorization services (Google, Meta, etc.).<br/>
            - The registration process is automatic — personal data is not transmitted to the service.<br/>
            - The couple records a video vow, which is immediately published to the blockchain and decentralized services.
        </span>
                <p>Thanks to the blockchain, everything is secure and immutable, making the process transparent and reliable.</p>
            </StyledWhy>
            <StyledWhy>
                <h2>How does this work for the restaurant?</h2>
                <p>You become the only restaurant in the city offering this service.</p>
                <span>
            - Guests register their blockchain marriage for free using your promotional code.<br/>
            - You set your price for the service, which includes your service fees.<br/>
            - Promotional codes are provided to you free of charge as part of a marketing partnership.
        </span>
            </StyledWhy>
            <StyledWhy>
                <h2>Web3Wed Promotion Agreement</h2>
                <p>Our Dubai office concludes an agreement with you to promote the Web3Wed.io service in your city.</p>
                <span>
            - Promotional codes are provided to the restaurant free of charge – they are used to promote the service.<br/>
            - You commit to reinvesting a portion of the profit into promoting the service.<br/>
            - The budget is split 50/50:<br/>
                - 50% for promoting the service itself in your city.<br/>
                - 50% for promoting your service within the restaurant.<br/>
            - We estimate that the reinvested amount will be approximately $50 (as the standard retail price of the service is $111).<br/>
            - The money remains in your account, but promotion decisions are made by our team.<br/>
            - Your marketing department implements the promotion, with a focus on SMM and digital marketing.
        </span>
            </StyledWhy>
            <StyledWhy>
                <h2>What does your restaurant get?</h2>
                <span>
        - A unique service – your restaurant becomes a place where digital unions are formed.<br/>
        - Additional value for guests – couples choose your restaurant for special occasions.<br/>
        - A new revenue stream – the opportunity to monetize the service without upfront investment.<br/>
        - Exclusive rights to branded certificates – only you will have a unique design.<br/>
        - Organic marketing – guests spread the word themselves, creating natural advertising.<br/>
        - Expanded collaboration with wedding agencies – the new service will help attract more couples.<br/>
    </span>
            </StyledWhy>
            <StyledWhy>
                <h2>Try it risk-free</h2>
                <p>You get exclusive Web3Wed status in your city, with the first three months as a trial period.</p>
                <span>
        You pay nothing, and our share of the profit is reinvested in promoting the service in your city through your marketing department, focusing on SMM.<br/>

        If you are interested in offering your guests new value and a unique experience, let's discuss the details.
    </span>
            </StyledWhy>
            <StyledWhy style={{textAlign:'center'}}>
                <h2>Contact Us</h2>
                <p>Yuri</p>
                <span>
        <a href="https://www.instagram.com/yury.reut">
            <InstagramIcon sx={{fontSize: 'xxx-large'}}/>
        </a>
    </span>
            </StyledWhy>
        </div>
    );
};

export default WhyRestEng;
