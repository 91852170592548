import { toast } from 'react-toastify';
import { weddingActions } from 'entities/wedding';
import { error, request, socket, success } from 'shared/api';
import { Emits } from 'shared/types';
import { SagaPayload } from 'shared/types/globals';
import { logger, snakeize } from 'shared/utils';
import { put, takeLatest } from 'typed-redux-saga';

export function* getPaymentLinkSaga({
  type,
  payload,
}: SagaPayload<typeof weddingActions.getPaymentLink>) {
  yield* put(request(type));

  try {
    //const data = referralCodeOwnerAddress
    //  ? { emit: Emits.PaymentPay, data: { referralCodeOwnerAddress } }
    //  : { emit: Emits.PaymentPay };
    const data = { emit: Emits.PaymentPay, data: { promo_code_id: payload.promocodeCodeId } }
    socket?.send(JSON.stringify(snakeize(data)));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    toast.error('Something went wrong');
    logger('weddingActions.getPaymentLink.type', err);
  }
}

export default function* listener() {
  yield takeLatest(weddingActions.getPaymentLink.type, getPaymentLinkSaga);
}
