import { useLocation, useNavigate } from 'react-router-dom';
import {Box, Button, Stack, styled} from '@mui/material';
import { routes } from 'app/providers/router/routes';
import { useWeb3Auth } from 'app/providers/Web3AuthProvider';
import { userSelector } from 'entities/user';
import { ConnectButton } from 'features/connect-button';
import { logoImage } from 'shared/assets';
import { useShallowSelector } from 'shared/hooks';
import { useTranslation } from 'react-i18next';
import {
    StyledHeader,
    Nav,
    Logo,
    LangWrapper,
    MobileLang,
    SelectedLang,
    Dropdown,
} from "../../pages/home/ui/MainPage/styles/StyledHeader";
import { ContainerMain } from "../../pages/home/ui/MainPage/styles/ContainerMain";
import logo from "../../shared/assets/img/header/logo.svg";
import { useState, useEffect, useRef } from "react";

const ButtonM = styled(Button)`
  background: radial-gradient(
    95.29% 75.23% at 15.56% 11.62%,
    #4bfffc 0%,
    #1a9082 100%
  );
  padding: 12px 20px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  border-radius: 63px;
  font-family: "Montserrat", sans-serif;
  margin-left: 20px;
  cursor: pointer;
  height: 43px;
  transition: all 0.3s ease;
  &:hover {
    color: #000;
    background: #fff;
  }
  @media (max-width: 768px) {
    padding: 8px 16px;
    margin-left: 0px;
  }
`;

export const Header = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isHomePage = pathname === '/';
  const { id: userId } = useShallowSelector(userSelector.getUser);
  const { logout } = useWeb3Auth();

  const { t, i18n } = useTranslation();
  const handleLogoClick = () => {
    navigate(routes.home.path);
  };

  const changeLanguageRU = () => {
    i18n.changeLanguage("ru")
  };
  const changeLanguageEN = () => {
    i18n.changeLanguage("en")
  };
  const changeLanguageID = () => {
    i18n.changeLanguage("id")
  };
    const [isOpen, setIsOpen] = useState(false);
    const [selectedLang, setSelectedLang] = useState("RU");

    const dropdownRef = useRef(null);
    const selectedLangRef = useRef(null);

    const handleLangChange = (lang: any) => {
        setSelectedLang(lang);
        i18n.changeLanguage(lang);
        setIsOpen(false);
    };

    useEffect(() => {
        const handleClickOutside = (event: any) => {
            // @ts-ignore
            if (dropdownRef.current && !dropdownRef.current.contains(event.target) && !selectedLangRef.current.contains(event.target)
            ) {
                setIsOpen(false);
            }
        };

        document.addEventListener("click", handleClickOutside);
        return () => {
            document.removeEventListener("click", handleClickOutside);
        };
    }, []);



    return (
        <StyledHeader>
            <ContainerMain>
                <Nav>
                    <Logo src={logo} alt="Web3Wed" onClick={handleLogoClick} />
                    <div>
                        <LangWrapper>
                            <button onClick={changeLanguageRU}>RU</button>
                            <button onClick={changeLanguageEN}>EN</button>
                        </LangWrapper>
                        <MobileLang>
                            <SelectedLang
                                onClick={() => setIsOpen(!isOpen)}
                                ref={selectedLangRef}
                            >
                                {selectedLang}
                            </SelectedLang>
                            {isOpen && (
                                <Dropdown ref={dropdownRef}>
                                    {["ru", "en"].map(
                                        (lang) =>
                                            lang !== selectedLang && (
                                                <button
                                                    key={lang}
                                                    onClick={() => handleLangChange(lang)}
                                                >
                                                    {lang}
                                                </button>
                                            )
                                    )}
                                </Dropdown>
                            )}
                        </MobileLang>
                        {userId && !isHomePage ? (
                            <ButtonM
                                variant="outlined"
                                onClick={logout}
                            >
                                {t('header_button_logout')}
                            </ButtonM>
                        ) : (
                            <ConnectButton />
                        )}
                    </div>
                </Nav>
            </ContainerMain>
        </StyledHeader>
    );
};
