import { FC } from 'react';
import { Box, styled, Typography } from '@mui/material';
import { Ring, RingProps } from 'entities/ring';
import { User, UserProps } from 'entities/user';
import { COLOR_ACCENT, COLOR_STROKE } from 'shared/config';
import { Nullable } from 'shared/types';

type RingWithUserProps = {
  userProps: UserProps;
  ringProps: RingProps;
  tokenValue?: Nullable<number>;
  usdValue?: Nullable<number>;
  isCertificate?: boolean;
  active?: boolean;
  size?: 'small' | 'medium' | 'large' | 'extra-large';
};

const StyledGrid = styled(Box)<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  max-width: 268px;

  * {
    box-sizing: border-box;
  }

  border: 2px solid;
  border-color: ${({ active }) => (active ? COLOR_ACCENT : COLOR_STROKE)};
  border-radius: 16px;

  &.extra-large {
    width: 100%;
    max-width: 100%;
  }

  ${({ theme }) =>
    `
      ${theme.breakpoints.down('md')} {
          width: 154px;
        } 
    `}
`;

const StyledInner = styled(Box)<{ backgroundImage?: string }>`
  position: relative;
  display: flex;
  justify-content: center;
  width: fit-content;

  border-radius: 16px;
  border-top: 2px solid #5c5a75;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  &.extra-large {
    width: 100%;
  }

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
`;

const StyledInfo = styled(Box)`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing(1.25)};
  gap: ${({ theme }) => theme.spacing(1)};
`;

export const RingWithUser: FC<RingWithUserProps> = ({
  userProps,
  ringProps,
  tokenValue = null,
  isCertificate = false,
  usdValue = null,
  active = false,
  size = 'medium',
}) => {
  return (
    <StyledGrid active={active} className={size}>
      {(isCertificate) ? (
          <Typography variant="h3" textAlign='center' padding='22px'>Your Unique NFT Wedding Ring</Typography>
      ) : (
          <User {...userProps} noBorder withRing />
      )}

      <StyledInner className={size}>
        <Ring {...ringProps} simple noBorder size={size} />
      </StyledInner>
      {(!!tokenValue || !!usdValue) && (
        <StyledInfo>
          <Typography variant="h4" fontSize={{ xs: 18, md: 24 }}>
            {parseFloat(String(tokenValue || 0)).toLocaleString('en-US')} WBTC
          </Typography>
          <Typography variant="body2">${parseFloat(String(usdValue || 0)).toLocaleString('en-US')}</Typography>
        </StyledInfo>
      )}
    </StyledGrid>
  );
};
