import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Button, CircularProgress, IconButton, Stack, Typography } from '@mui/material';
import { routes } from 'app/providers/router/routes';
import { CodeBlockWrapper, CodeTextField } from 'entities/connection';
import { coupleActions, coupleSelector } from 'entities/couple';
import { userSelector } from 'entities/user';
import { COLOR_WHITE } from 'shared/config';
import { useShallowSelector, useValidateInputField, ValidationTypes } from 'shared/hooks';
import { ArrowIcon, CopyTextField } from 'shared/ui/components';

import { useTranslation } from 'react-i18next';

import { ProposalBlock } from '../ui/ProposalBlock';

export const FianceCodeContainer = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [fiancePromoCode, handleChangeFiancePromoCode, setFiancePromoCode] = useValidateInputField({
    type: ValidationTypes.int,
  });

  const {
    partner: { promoCode: partnerPromoCode },
  } = useShallowSelector(coupleSelector.getCouple);
  const { promoCode } = useShallowSelector(userSelector.getUser);

  const handleMatch = () => {
    dispatch(coupleActions.addPartner({ promoCode: fiancePromoCode }));
    setFiancePromoCode('');
  };

  const handleBack = () => {
    navigate(routes.connection.path);
  };

  return (
    <Stack sx={{display: 'flex', alignItems: 'center', padding: '0 30px'}}>
      <Stack maxWidth={550}>
        <IconButton size="small" color="info" onClick={handleBack}>
          <ArrowIcon />
        </IconButton>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={3} mt={{ xs: 4, md: 8 }} mb={1.5}>
          <CodeBlockWrapper>
            <Typography variant="body2" className="center">
              {t('connection_fiance_p1')}
            </Typography>
            <CopyTextField variant="code" text={promoCode} />
          </CodeBlockWrapper>
          <CodeBlockWrapper>
            <Typography variant="body2" className="center">
              {t('connection_fiance_p2')}
              <br />
              {t('connection_fiance_p2_1')}
            </Typography>
            <CodeTextField
              inputValue={fiancePromoCode || partnerPromoCode}
              placeholder="00000"
              disabled={Boolean(partnerPromoCode)}
              onChange={handleChangeFiancePromoCode}
            />
          </CodeBlockWrapper>
        </Stack>
        {/*<ProposalBlock />*/}
        <Stack mt={3} spacing={3.5} alignItems="center">
          {partnerPromoCode && <Typography>Bringing hearts together</Typography>}
          <Button
            className={partnerPromoCode ? 'active-bg-disabled' : 'disabled-opacity'}
            variant="contained"
            onClick={handleMatch}
            disabled={fiancePromoCode.length < 5}
            sx={{ width: 231 }}
          >
            {partnerPromoCode ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : t('connection_fiance_next')}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};
