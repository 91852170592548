import { FC } from 'react';
import { Box, styled } from '@mui/material';
import { User, UserRole } from 'entities/user';
import {HeartIcon, InfinityNewIcon} from 'shared/ui/components';

type HeartUserProps = {
  avatar: string;
  firstName: string;
  lastName: string;
  email?: string;
};

type HeartWithUsersProps = {
  femaleProps: HeartUserProps;
  manProps: HeartUserProps;
  size?: 'medium' | 'large' | 'mlarge' | 'x-large';
  type?: 'simple' | 'simpleWithName' | 'connection';
};

const StyledGrid = styled(Box)`
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  &.x-large {
    width: 495px;
    height: 100%;
  }
  &.mlarge {
    width: 360px;
    height: 100%;
  }
  &.large {
    width: 327px;
    height: 128px;
  }
  &.medium {
    width: 238px;
    height: 106px;
  }

  * {
    box-sizing: border-box;
  }

  > * {
    z-index: 1;
  }
`;

export const HeartWithUsers: FC<HeartWithUsersProps> = ({ femaleProps, manProps, size = 'mlarge', type }) => {
    return (
    <StyledGrid className={size}>
      <User {...femaleProps} role={UserRole.Female} simpleWithName size={size} noBorder={true} withRing={true} withFullName={true} email={femaleProps.email} firstName={femaleProps.firstName} lastName={femaleProps.lastName}  />
        {type == 'connection' ?
            <InfinityNewIcon
                sx={{
                    position: 'absolute',
                    zIndex: -1,
                    left: {
                        md: '-191px',
                        xs: '-192px',
                    },
                    top: {
                        md:'-133px',
                        xs:'-157px',
                    },
                    transform:  {
                        md: 'rotate(-4deg)',
                        xs: 'rotate(-4deg)',
                    },
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: '880px',
                    height: '539px',
                }}
            />
            :
            <InfinityNewIcon
                sx={{
                    position: 'absolute',
                    zIndex: -1,
                    left: {
                        md: '-185px',
                        xs: '-135px',
                    },
                    top: {
                        xs:'-84px',
                        md:'-71px',
                    },
                    transform:  {
                        md: 'rotate(-4deg)',
                        xs: 'rotate(-4deg)',
                    },
                    right: 0,
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    width: {
                        md: '728px',
                        xs: '627px',
                    },
                    height: {
                        md: '341px',
                        xs: '318px',
                    },
                }}
            />
        }
      <User {...manProps} role={UserRole.Man} simpleWithName size={size} noBorder={true} withRing={true} withFullName={true}  email={manProps.email} firstName={manProps.firstName} lastName={manProps.lastName}/>
    </StyledGrid>
  );
};
