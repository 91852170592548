import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {Button, styled, SxProps} from '@mui/material';
import { routes, wsWeddingRoutes } from 'app/providers/router/routes';
import { modalActions, ModalType } from 'entities/modal';
import { userSelector } from 'entities/user';
import { weddingSelector } from 'entities/wedding/model';
import { useShallowSelector } from 'shared/hooks';
import { useTranslation } from 'react-i18next';

type ConnectButtonProps = {
  size?: 'large' | 'small';
  sx?: SxProps;
};

const ButtonM = styled(Button)`
  background: radial-gradient(
    95.29% 75.23% at 15.56% 11.62%,
    #4bfffc 0%,
    #1a9082 100%
  );
  padding: 12px 20px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  border-radius: 63px;
  font-family: "Montserrat", sans-serif;
  margin-left: 20px;
  cursor: pointer;
  height: 43px;
  transition: all 0.3s ease;
  &:hover {
    color: #000;
    background: #fff;
  }
  @media (max-width: 768px) {
    padding: 8px 16px;
    margin-left: 0px;
  }
`;

export const ConnectButton = ({ size = 'small', sx }: ConnectButtonProps) => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const { id: userId } = useShallowSelector(userSelector.getUser);
  const { state } = useShallowSelector(weddingSelector.getWedding);

  const handleButtonClick = () => {
    if (!userId) {
      dispatch(
        modalActions.openModal({
          type: ModalType.ConnectWallet,
        }),
      );
    } else {
      navigate(state ? wsWeddingRoutes[state] : routes.connection.path);
    }
  };

  return (
    <ButtonM
      variant="outlined"
      onClick={handleButtonClick}
    >
      {userId ? t('header_button_profile'): t('header_button_signin')}
    </ButtonM>
  );
};
