//import styled from "styled-components";

import { styled } from '@mui/material';

export const ContainerMain = styled('div')`
    max-width: 1206px;
    padding: 0px 15px;
    margin: 0 auto;
    box-sizing: border-box;
`

