import { toast } from 'react-toastify';
import { weddingActions } from 'entities/wedding';
import { error, request, socket, success } from 'shared/api';
import { Emits } from 'shared/types';
import { SagaPayload } from 'shared/types/globals';
import { logger, snakeize } from 'shared/utils';
import { put, takeLatest } from 'typed-redux-saga';

export function* setPaymentSuccessSaga({ type }: SagaPayload<typeof weddingActions.setPaymentSuccess>) {
    yield* put(request(type));
    try {
        const data = { emit: Emits.PaymentSuccess};

        console.log(JSON.stringify(snakeize(data)))
        socket?.send(JSON.stringify(snakeize(data)));

        yield* put(success(type));
    } catch (err) {

        console.log('setPaymentSuccessError');
        yield* put(error(type, err));
        toast.error('Something went wrong');
        logger('setPaymentSuccess', err);
    }
}

export default function* listener() {
    yield takeLatest(weddingActions.setPaymentSuccess.type, setPaymentSuccessSaga);
}


