import { useEffect } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { yupResolver } from '@hookform/resolvers/yup';
import { Box, Button, CircularProgress, Stack, TextField, Typography } from '@mui/material';
import { userActions } from 'entities/user';
import { Close } from 'pages/home/ui/Icons';
import { COLOR_LIGHT_GRAY_INPUT, uiSelector } from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import { FeedbackRequest, RequestStatus } from 'shared/types';
import { checkRequestStatusEquality } from 'shared/utils';
import * as Yup from 'yup';

import { modalActions, ModalType } from '../model';

const validationSchema = Yup.object({
  name: Yup.string().required('Name field is required'),
  email: Yup.string().email('Email address is not valid').required('Email field is required'),
  tiktok: Yup.string().notRequired(),
  instagram: Yup.string().notRequired(),
  text: Yup.string()
    .min(10, 'Your message is too short')
    .max(500, 'Your message is too long')
    .required('Message field is required'),
});

const defaultValues: FeedbackRequest = {
  name: '',
  email: '',
  tiktok: '',
  instagram: '',
  text: '',
};

export interface ITellAboutYourselfPayload {
  type: ModalType.TellAboutYourself;
  data?: null;
}

export const TellAboutYourself = () => {
  const dispatch = useDispatch();

  // @ts-ignore
  const {
    handleSubmit,
    control,
    reset,
    formState: { errors, isValid, isSubmitting },
  } = useForm<FeedbackRequest>({
    // @ts-ignore
    resolver: yupResolver(validationSchema),
    defaultValues,
    mode: 'all',
    reValidateMode: 'onBlur',
  });
  const uiState = useShallowSelector(uiSelector.getUI);

  const isSendFeedbackSucsess = checkRequestStatusEquality(
    uiState[userActions.sendFeedback?.type],
    RequestStatus.SUCCESS,
  );
  const onSubmit: SubmitHandler<FeedbackRequest> = (data) => {
    dispatch(userActions.sendFeedback(data));
  };

  useEffect(() => {
    if (isSendFeedbackSucsess) {
      reset(defaultValues);
      dispatch(modalActions.closeModal());
    }
  }, [reset, isSendFeedbackSucsess, dispatch]);

  return (
    <Stack
      sx={{
        backgroundColor: 'rgb(25, 24, 54)',
        width: '100%',
        padding: '24px',
        borderRadius: '12px',
        maxWidth: '504px',
      }}
      spacing={2}
    >
      <Stack width="100%" direction="row" justifyContent="space-between">
        <Typography variant="h3" className="semi-bold">
          Tell us about yourself
        </Typography>
        <Close
          sx={{ color: COLOR_LIGHT_GRAY_INPUT, cursor: 'pointer' }}
          onClick={() => dispatch(modalActions.closeModal())}
        />
      </Stack>
      <Box component="form" onSubmit={handleSubmit(onSubmit)} width="100%">
        <Stack spacing={3} width="100%">
          <Controller
            name="name"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Name*"
                error={!!errors.name?.message}
                helperText={errors.name ? errors.name.message : ''}
              />
            )}
          />
          <Controller
            name="email"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="E-mail*"
                error={!!errors.email?.message}
                helperText={errors.email ? errors.email.message : ''}
              />
            )}
          />
          <Stack direction="row" spacing={3} width="100%">
            <Controller
              name="tiktok"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Tik-tok"
                  error={!!errors.tiktok?.message}
                  helperText={errors.tiktok ? errors.tiktok.message : ''}
                />
              )}
            />
            <Controller
              name="instagram"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Instagram"
                  error={!!errors.instagram?.message}
                  helperText={errors.instagram ? errors.instagram.message : ''}
                />
              )}
            />
          </Stack>
          <Controller
            name="text"
            control={control}
            render={({ field }) => (
              <TextField
                {...field}
                label="Message*"
                multiline
                error={!!errors.text?.message}
                helperText={errors.text ? errors.text.message : ''}
              />
            )}
          />
          <Button
            variant="outlined"
            type="submit"
            disabled={!isValid || isSubmitting}
            sx={{ mt: 1.375, width: '100%' }}
          >
            {isSubmitting ? <CircularProgress size={30} /> : 'Send'}
          </Button>
        </Stack>
      </Box>
    </Stack>
  );
};
