
import { styled } from '@mui/material';
export const StyledWhatIs = styled('section')`
  margin-top: 200px;
  @media (max-width: 480px) {
    margin-top: 160px;
  }
  display: flex;
  justify-content: space-between;
  align-items: center;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const HeadingWhatIs = styled('div')`
  max-width: 697px;
  width: 100%;
  h2 {
    font-size: 48px;
    font-weight: 700;
    text-align: left;
    @media (max-width: 725px) {
      text-align: center;
      line-height: 54px;
    }
    @media (max-width: 480px) {
      font-size: 26px;
      line-height: 29px;
    }
  }
  p {
    margin-top: 12px;
    font-family: "Tektur", serif;
    font-size: 24px;
    font-weight: 400;
    background: radial-gradient(circle, #2df6de 20%, #3c5dfe 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    line-height: 30px;
    @media (max-width: 992px) {
      text-align: center;
    }
    @media (max-width: 480px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
`;

export const VideoWhatIs = styled('div')`
  display: flex;
  align-items: center;
`;
export const TextWrapper = styled('div')`
  h4 {
    font-size: 24px;
    font-family: "Tektur", serif;
    font-weight: 400;
    text-align: center;
    margin-bottom: 6px;
    @media (max-width: 480px) {
      font-size: 18px;
      text-align: left;
      line-height: 24px;
    }
  }
  span {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    opacity: 0.8;
    @media (max-width: 480px) {
      font-size: 15px;
      text-align: left;
    }
  }
`;
