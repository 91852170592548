import {
  StyledLove,
  SliderWrapper,
  SliderItem,
  SliderText,
  GradientOverlay,
  SafeWrapper,
  TopBrands,
  LastBrands,
  BodyLove,
  DisneyIcon,
  JPMIcon,
  STBIcon,
  AdobeIcon,
  VKIcon,
  MasterIcon,
  CocaIcon,
  InstIcon,
  AdidasIcon,
  RedditIcon,
  AboutWrapper,
  MediaBrands,
  YahooIcon,
  BenzIcon,
  KTLAIcon,
  NBCIcon,
  APIcon,
  CBSIcon,
  ChronicleIcon,
} from "./styles/StyledLove";
import { SubtitleText } from "./styles/StyledVow";
import { useRef } from "react";
import play from "../../../../shared/assets/img/love/love-play.svg";
import DisneyIconSrc from "../../../../shared/assets/img/love/disney.svg";
import STBIconSrc from "../../../../shared/assets/img/love/sbux.svg";
import AdobeIconSrc from "../../../../shared/assets/img/love/adb.svg";
import JPMIconSrc from "../../../../shared/assets/img/love/jpm.svg";
import VKIconSrc from "../../../../shared/assets/img/love/vk.svg";
import MasterIconSrc from "../../../../shared/assets/img/love/master.svg";
import CocaIconSrc from "../../../../shared/assets/img/love/coca.svg";
import InstIconSrc from "../../../../shared/assets/img/love/insta.svg";
import AdidasIconSrc from "../../../../shared/assets/img/love/adidas.svg";
import RedditIconSrc from "../../../../shared/assets/img/love/reddit.svg";
import YahooIconSrc from "../../../../shared/assets/img/love/yahoo.svg";
import BenzIconSrc from "../../../../shared/assets/img/love/benz.svg";
import KTLAIconSrc from "../../../../shared/assets/img/love/ktla.svg";
import NBCIconSrc from "../../../../shared/assets/img/love/nbc.svg";
import APIconSrc from "../../../../shared/assets/img/love/ap.svg";
import CBSIconSrc from "../../../../shared/assets/img/love/cbs.svg";
import ChronicleIconSrc from "../../../../shared/assets/img/love/chronicle.svg";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import { useState, useEffect } from "react";
import firstPoster from "../../../../shared/assets/img/posters/W3W_short_vert_04.jpg";
import secondPoster from "../../../../shared/assets/img/posters/W3W_short_vert_03.jpg";
import thirdPoster from "../../../../shared/assets/img/posters/W3W_short_vert_05.jpg";
import videoFirst from '../../../../shared/assets/video/cards/W3W_short_vert_04.mp4';
import videoSecond from '../../../../shared/assets/video/cards/W3W_short_vert_03.mp4';
import videoThird from '../../../../shared/assets/video/cards/W3W_short_vert_05.mp4';
import { useTranslation } from 'react-i18next';

const Love = () => {
  const { t, i18n } = useTranslation();
  const videoRefs = [useRef(null), useRef(null), useRef(null)];
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseEnter = (index:any) => {
    if (videoRefs[index].current) {
      // @ts-ignore
      videoRefs[index].current.play();
    }
  };

  const handleMouseLeave = (index:any) => {
    if (videoRefs[index].current) {
      // @ts-ignore
      videoRefs[index].current.pause();
      // @ts-ignore
      videoRefs[index].current.currentTime = 0;
    }
  };

  const items = [
    { video: videoFirst, text: t('love_items_1'), poster: firstPoster },
    { video: videoSecond, text: t('love_items_2'), poster: secondPoster },
    { video: videoThird, text: t('love_items_3'), poster: thirdPoster },
  ];

  return (
    <StyledLove>
      <h2>{t('love_title_1')}</h2>
      <SubtitleText>{t('love_desc_1')}</SubtitleText>
      {isMobile ? (
        <Splide
          options={{
            arrows: false,
            pagination: true,
            drag: "free",
            gap: "1rem",
            breakpoints: {
              1200: {
                perPage: 2,
              },
              992: {
                perPage: 1,
              },
            },
          }}
        >
          {items.map((item, index) => (
            <SplideSlide key={item.video}>
              <SliderItem
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <video
                  ref={videoRefs[index]}
                  src={item.video}
                  muted
                  loop
                  playsInline
                  poster={item.poster}
                ></video>
                <GradientOverlay className="gradient" />
                <img src={play} alt="" />
                <SliderText>{item.text}</SliderText>
              </SliderItem>
            </SplideSlide>
          ))}
        </Splide>
      ) : (
        <SliderWrapper>
          {items.map((item, index) => (
            <div key={item.video} style={{ textAlign: "center" }}>
              <SliderItem
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <video
                  ref={videoRefs[index]}
                  src={item.video}
                  muted
                  loop
                  playsInline
                  poster={item.poster}
                ></video>
                <GradientOverlay className="gradient" />
                <img src={play} alt="" />
                <SliderText>{item.text}</SliderText>
              </SliderItem>
            </div>
          ))}
        </SliderWrapper>
      )}
      <BodyLove>
        <SafeWrapper>
          <h4>{t('love_title_2')}</h4>
          <p>{t('love_desc_2')}</p>
          <TopBrands>
            <DisneyIcon src={DisneyIconSrc} alt="Disney Icon" />
            <STBIcon src={STBIconSrc} alt="Starbucks Icon" />
            <AdobeIcon src={AdobeIconSrc} alt="Adobe Icon" />
            <JPMIcon src={JPMIconSrc} alt="JPM Icon" />
            <VKIcon src={VKIconSrc} alt="VK Icon" />
          </TopBrands>
          <LastBrands>
            <MasterIcon src={MasterIconSrc} alt="Master Card Icon" />
            <CocaIcon src={CocaIconSrc} alt="Coca Cola Icon" />
            <InstIcon src={InstIconSrc} alt="Instagram Icon" />
            <AdidasIcon src={AdidasIconSrc} alt="Adidas Icon" />
            <RedditIcon src={RedditIconSrc} alt="Reddit Icon" />
          </LastBrands>
        </SafeWrapper>
        <AboutWrapper>
          <h4>{t('love_smi')}</h4>
          <MediaBrands>
            <a href="https://finance.yahoo.com/news/revolutionizing-marriage-web3wed-io-launches-202500022.html" target="_blank"><YahooIcon src={YahooIconSrc} alt="Yahoo Icon" /></a>

            <a href="https://www.benzinga.com/amp/content/32257053" target="_blank"><BenzIcon src={BenzIconSrc} alt="Benzinga Icon" /></a>
            <a href="https://ktla.com/business/press-releases/ein-presswire/616693463/nft-based-blockchain-marriage-service-on-valentines-day-launched-by-w3wed-io/" target="_blank"><KTLAIcon src={KTLAIconSrc} alt="KTLA Icon" /></a>
            <a href="https://www.nbc4i.com/business/press-releases/ein-presswire/616693463/nft-based-blockchain-marriage-service-on-valentines-day-launched-by-w3wed-io/" target="_blank"><NBCIcon src={NBCIconSrc} alt="NBC 24 Icon" /></a>
            <a href="https://apnews.com/press-release/ein-presswire-newsmatics/privacy-ein-presswire-newsmatics-weddings-marriage-26f7ea28ec21716c985b32133f1fed09" target="_blank"><APIcon src={APIconSrc} alt="AP Icon" /></a>
            <a href="https://www.cbs42.com/business/press-releases/ein-presswire/616693463/nft-based-blockchain-marriage-service-on-valentines-day-launched-by-w3wed-io/" target="_blank"><CBSIcon src={CBSIconSrc} alt="CBS42 Icon" /></a>
            <a href="http://markets.chroniclejournal.com/chroniclejournal/news/article/marketersmedia-2023-2-19-w3wedio-launches-revolutionary-blockchain-marriage-service-with-nft-based-rings" target="_blank"><ChronicleIcon
              src={ChronicleIconSrc}
              alt="The Chronicle Journal Icon"
            /></a>
          </MediaBrands>
        </AboutWrapper>
      </BodyLove>
    </StyledLove>
  );
};

export default Love;
