import {
    StyledWhy,
    CardWrapper,
    FirstCardItem,
    SecondCardItem,
    ThirdCardItem,
    CardHeading,
    CardBottom,
    CardText,
    BlackCardText,
} from "./styles/StyledWhy";
import { useTranslation } from 'react-i18next';
import InstagramIcon from '@mui/icons-material/Instagram';

const WhyRest = () => {
    const { t, i18n } = useTranslation();

    return (
        <div>
            <StyledWhy>
                <h2>Блокчейн-брак — новая романтическая традиция</h2>
                <span>
                    Теперь ваш ресторан может предложить не просто ужин или праздник, а уникальный момент для гостей — возможность зафиксировать свою любовь навсегда с помощью блокчейн-технологий.<br/>
                    Пары, отмечающие годовщину, свадьбу или просто особенный вечер, получат не только отличный сервис, но и возможность сохранить этот момент в цифровой вечности, выбрав именно ваш ресторан.
                </span>
            </StyledWhy>
            <StyledWhy>
                <h2>Ваш ресторан как цифровой ЗАГС</h2>
                <p>Мы предлагаем вам стать эксклюзивной площадкой Web3Wed в вашем городе.  </p>
                <span>
                    - Статус эксклюзивного партнёра на 1 год.<br/>
                    - Тестовый период 3 месяца без обязательств.<br/>
                    - Гибкость – пары могут зарегистрировать брак прямо в вашем ресторане или позже, в удобной обстановке.
                </span>
            </StyledWhy>
            <StyledWhy>
                <h2>Как проходит процесс регистрации?</h2>
                <p>Мы не храним данные гостей, не имеем доступа к их видео и не можем что-либо изменить или удалить.</p>
                <span>
                     Гости сканируют QR-код и заходят на Web3Wed.io через удобные сервисы авторизации (Google, Meta и др.).<br/>
                    - Процесс регистрации автоматический — персональные данные не передаются сервису.<br/>
                    - Пара записывает видео-клятву, которая сразу публикуется в блокчейн и децентрализованные сервисы.
                </span>
                <p>Благодаря блокчейну всё защищено и неизменно, что делает процесс прозрачным и надёжным.</p>
            </StyledWhy>
            <StyledWhy>
                <h2>Как это работает для ресторана?</h2>
                <p> Вы становитесь единственным рестораном в городе, предлагающим такую услугу.</p>
                <span>
                    - Гости регистрируют блокчейн-брак бесплатно, используя ваш промокод.<br/>
                    - Вы устанавливаете свою цену на услугу, которая включает ваш сервис.<br/>
                    - Промокоды предоставляются вам бесплатно в рамках маркетингового партнёрства.
                </span>
            </StyledWhy>
            <StyledWhy>
                <h2>Соглашение о продвижении Web3Wed</h2>
                <p>Наш дубайский офис заключает с вами соглашение на продвижение сервиса Web3Wed.io в вашем городе.  </p>
                <span>
                    - Промокоды передаются ресторану бесплатно – они используются для продвижения услуги.<br/>
                    - Вы берёте обязательство реинвестировать часть прибыли в продвижение сервиса.<br/>
                    - Бюджет делится 50/50:<br/>
                      - 50% на продвижение самого сервиса в вашем городе.<br/>
                      - 50% на продвижение вашей услуги внутри ресторана.<br/>
                    - Мы рассчитываем, что реинвестируемая сумма будет составлять примерно $50 (так как стандартная розничная стоимость услуги — $111).<br/>
                    - Деньги остаются на вашем счету, но решения по продвижению принимает наша команда.<br/>
                    - Ваш отдел маркетинга реализует продвижение, основное внимание уделяется SMM и digital-маркетингу.


                </span>
                <p>Этот формат партнёрства создаёт дополнительный поток клиентов и повышает узнаваемость как вашего ресторана, так и сервиса Web3Wed.</p>
            </StyledWhy>
            <StyledWhy>
                <h2>Что получает ваш ресторан?</h2>
                <span>
                    - Уникальный сервис – ваш ресторан становится местом, где заключают цифровые союзы.<br/>
                    - Дополнительная ценность для гостей – пары выбирают ваш ресторан для особых событий.<br/>
                    - Новый источник дохода – возможность монетизировать услугу без стартовых вложений.<br/>
                    - Эксклюзивное право на фирменные сертификаты – только у вас будет уникальное оформление.<br/>
                    - Органический маркетинг – гости сами распространяют информацию, создавая естественную рекламу.<br/>
                    - Расширение сотрудничества с свадебными агентствами – новый сервис поможет привлечь больше пар.<br/>
                </span>
            </StyledWhy>
            <StyledWhy>
                <h2>Попробуйте без риска</h2>
                <p>Вы получаете эксклюзивный статус Web3Wed в вашем городе, первые три месяца — тестовые.  </p>
                <span>
                    Вы ничего не платите, а наша часть прибыли реинвестируется в продвижение сервиса в вашем городе через ваш маркетинговый отдел, с фокусом на SMM.<br/>

                    Если вам интересно предложить вашим гостям новую ценность и уникальный опыт, давайте обсудим детали.
                </span>
            </StyledWhy>
            <StyledWhy style={{textAlign:'center'}}>
                <h2>Связаться с нами</h2>
                <p>Юрий</p>
                <span>
                    <a href="https://www.instagram.com/yury.reut">
                        <InstagramIcon sx={{fontSize: 'xxx-large'}}/>
                    </a>
                </span>
            </StyledWhy>
        </div>
    );
};

export default WhyRest;
