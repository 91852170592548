
import { styled } from '@mui/material';
import ring from "../../../../../shared/assets/img/cards/card-ring.png";
import buble from "../../../../../shared/assets/img/cards/buble.png";

export const StyledCards = styled('div')`
  margin-top: -100px;
  display: flex;
  flex-direction: column;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
  @media (max-width: 540px) {
    margin-top: 100px;
  }
`;

export const TopCards = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1108px;
  width: 100%;
  margin: 0px auto 0px auto;
  position: relative;
  z-index: 500;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const RingCard = styled('div')`
  background-image: url(${ring});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 458px;
  height: 278px;
  border-radius: 32px;
  box-sizing: border-box;
  /*padding: 40px 60px 40px 20px;*/
  transform: rotate(5deg);
  transition: transform 0.3s ease;
  @media (any-hover: hover) {
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 10px 30px rgba(255, 255, 255, 0.2);
    }
  }
  div {
    padding: 40px 60px 40px 40px;
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
    color: #000;
    line-height: 25px;
  }
  p {
    font-size: 18px;
    color: #5e5e6c;
    max-width: 270px;
    margin-top: 20px;
    line-height: 20px;
  }
  @media (max-width: 992px) {
  }
  @media (max-width: 500px) {
    transform: rotate(0deg);
    max-width: 100%;
    width: 100%;
    height: 238px;
    padding: 20px;
    div {
      padding: 0px;
    }
  }
`;

export const InfoCard = styled('div')`
  background-image: url(${buble});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  max-width: 468px;
  height: 303px;
  padding: 40px 60px 40px 40px;
  transform: rotate(-5deg);
  box-sizing: border-box;
  transition: transform 0.3s ease;
  @media (any-hover: hover) {
    &:hover {
      transform: translateY(-10px);
    }
  }
  h3 {
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    line-height: 25px;
  }
  p {
    font-size: 18px;
    margin-top: 20px;
    color: #fff;
    opacity: 0.8;
    line-height: 22px;
  }

  @media (max-width: 992px) {
    margin-top: 20px;
  }
  @media (max-width: 500px) {
    transform: rotate(0deg);
    max-width: 100%;
    height: auto;
    margin-top: 30px;
    display: none;
  }
`;

export const BubbleWrapper = styled('div')`
  margin-top: 30px;
  display: none;
  img {
    max-width: 100%;
  }
  @media (max-width: 500px) {
    display: block;
  }
  position: relative;
`;
export const BubbleContent = styled('div')`
  position: absolute;
  top: 0px;
  padding: 20px;
  h3 {
    font-weight: 600;
    font-size: 20px;
    color: #fff;
    line-height: 25px;
    @media (max-width: 500px) {
      font-size: 18px;
    }
  }
  p {
    font-size: 18px;
    margin-top: 20px;
    color: #fff;
    opacity: 0.8;
    line-height: 22px;
    @media (max-width: 500px) {
      font-size: 14px;
      margin-top: 10px;
    }
  }
`;
