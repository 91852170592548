import {useEffect, useState} from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { coupleActions, coupleSelector } from 'entities/couple';
import {userActions, userSelector} from 'entities/user';
import { useShallowSelector } from 'shared/hooks';
import { EngagementType, EngagementTypePath, SexType } from 'shared/types';
import {Button, Stack, TextField, Typography} from "@mui/material";
import {CodeBlockWrapper} from "../../entities/connection";
import {pinata} from "../../shared/utils";
import {WsWeddingStates} from "../../entities/wedding";
import {wsWeddingRoutes} from "../../app/providers/router/routes";
import crypto from "crypto";
import PromoCodeDecoder from "../../features/connection/containers/CheckPromoContainer";

export const CheckPromo = () => {
    const navigate = useNavigate();
    const user = useShallowSelector(userSelector.getUser);
    const { engagementType, partner } = useShallowSelector(coupleSelector.getCouple);
    const isPartnerMale = partner?.sex === SexType.man;
    const partnerDisplayData = {
        firstName: partner?.firstName || '',
        lastName: partner?.lastName || '',
        avatar: partner?.profileImage || '',
    };
    const currentUserDisplayData = {
        firstName: user?.firstName || '',
        lastName: user?.lastName || '',
        avatar: user?.profileImage || '',
    };

    const dispatch = useDispatch();
    const [promoCode, setPromoCode] = useState('');

    useEffect(() => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let interval: any;
        if (partner?.promoCode && !engagementType) {
            interval = setInterval(() => dispatch(coupleActions.getCouple()), 2000);
        } else if (interval) {
            clearInterval(interval);
            interval = null;
        }
        return () => {
            clearInterval(interval);
        };
    }, [partner, dispatch, engagementType]);

    useEffect(() => {
        if (engagementType === EngagementType.Model) {
            navigate(EngagementTypePath[engagementType]);
        }
    }, [engagementType, navigate]);


    const handleBack = async (e:any) => {
        navigate(wsWeddingRoutes[WsWeddingStates.init]);
    }

    const handleInputChange = (event: any) => {
        setPromoCode(event.target.value);
    };

    const handleTestPromocode = async (e:any) => {
        console.log(123);
    }

    return (
        <Stack sx={{display: 'flex', alignItems: 'center', padding: '0 30px'}}>
            <Stack maxWidth={550}>
                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={3} mt={{ xs: 4, md: 8 }} mb={1.5}>
                    <Typography>Check Chakrascope Promo</Typography>
                    <TextField label={'Promo'}
                               placeholder={'Promo'}
                               id="outlined-basic"
                               variant="outlined"
                               value={promoCode}
                               onChange={handleInputChange}/>

                    <PromoCodeDecoder promoCode={promoCode} />
                </Stack>
                <Stack direction={{ xs: 'column', sm: 'row' }} mt={3} spacing={3.5} alignItems="center">
                    <Button
                        onClick={handleBack}
                        variant="contained"
                        sx={{ width: 120 }}
                    >
                        back
                    </Button>
                </Stack>
            </Stack>
        </Stack>
    );
};
