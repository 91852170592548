
import { styled } from '@mui/material';
import first from "../../../../../shared/assets/img/why/1.png";
import second from "../../../../../shared/assets/img/why/2.png";

export const StyledWhy = styled('section')`
  margin-top: 100px;
  @media (max-width: 480px) {
    margin-top: 60px;
  }
  h2 {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    @media (max-width: 725px) {
      text-align: center;
      line-height: 54px;
    }
    @media (max-width: 480px) {
      font-size: 26px;
      line-height: 29px;
    }
  }
  h4 {
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
  p {
    margin-top: 12px;
    font-family: "Tektur", serif;
    font-size: 24px;
    font-weight: 400;
    background: radial-gradient(circle, #2df6de 20%, #3c5dfe 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    margin-bottom: 20px !important;
    @media (max-width: 992px) {
      text-align: center;
    }
    @media (max-width: 480px) {
      font-size: 18px;
      line-height: 24px;
    }
  }
  span {
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    opacity: 0.8;
  }
`;

export const CardWrapper = styled('div')`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 20px;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;
export const FirstCardItem = styled('div')`
  background-image: url(${first});
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 389px;
  height: 360px;
  padding: 32px 24px;
  border-radius: 24px;
  @media (max-width: 480px) {
    width: 320px;
    height: 261px;
  }
`;
export const SecondCardItem = styled('div')`
  background-image: url(${second});
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  width: 389px;
  height: 360px;
  padding: 32px 24px;
  border-radius: 24px;
  h4 {
    font-size: 28px;
    font-weight: 700;
    text-align: left;
    color: #000;
    @media (max-width: 480px) {
      font-size: 24px;
    }
  }
  @media (max-width: 480px) {
    width: 320px;
    height: 261px;
  }
`;
export const ThirdCardItem = styled('div')`
  background: #16161f;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  width: 389px;
  height: 360px;
  padding: 32px 24px;
  border-radius: 24px;
  @media (max-width: 480px) {
    width: 320px;
    height: 261px;
  }
`;
export const CardHeading = styled('div')`
  img {
    @media (max-width: 480px) {
      width: 42px;
      height: 42px;
    }
  }
`;
export const CardBottom = styled('div')``;
export const CardText = styled('div')`
  margin-top: 10px;
  font-size: 18px;
  font-weight: 300;
  text-align: left;
  opacity: 0.8;
  @media (max-width: 480px) {
    font-size: 15px;
    line-height: 20px;
  }
`;

export const BlackCardText = styled('div')`
  margin-top: 10px;
  font-size: 18px;
  font-weight: 400;
  text-align: left;
  opacity: 0.8;
  color: #5e5e6c;
  max-width: 200px;
  @media (max-width: 480px) {
    font-size: 15px;
    line-height: 20px;
    max-width: 180px;
  }
`;
