import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { Box } from '@mui/system';
import { coupleSelector } from 'entities/couple';
import { ModalContainer } from 'entities/modal';
import { userSelector } from 'entities/user';
import { weddingActions, weddingSelector, WsWeddingStates } from 'entities/wedding';
import { closeSocket, socket } from 'shared/api';
import { useShallowSelector } from 'shared/hooks';
import { collectionRingsMock, CollectionRingsProps } from 'shared/mocks';
import {EngagementType, EngagementTypePath, SexType} from 'shared/types';
import { Layout } from 'shared/ui/layouts';
import { camelize } from 'shared/utils';
import './i18n';

import { routes, wsWeddingRoutes } from './providers/router/routes';
import { useWeb3Auth } from './providers/Web3AuthProvider';
import { GlobalProvider, RouteManager } from './providers';
import 'shared/styles/index.scss';

const App = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const { logout } = useWeb3Auth();
  const isHomePage = pathname === '/';
  const isChakras = pathname === '/chakras';
  const isCheckChakra = pathname === '/check-promo';
  const { isWitness, id: userId } = useShallowSelector(userSelector.getUser);
  const { engagementType } = useShallowSelector(coupleSelector.getCouple);
  const { id: engagementId } = useShallowSelector(weddingSelector.getWedding);

  useEffect(() => {
    if (engagementType && !isWitness) dispatch(weddingActions.getEngagement({ engagementType }));
  }, [dispatch, engagementType, isWitness]);

  useEffect(() => {

    if (socket) {
      socket.onmessage = (event: MessageEvent) => {
        const data = camelize(JSON.parse(event.data));
        const weddingState = data.state as WsWeddingStates;
        if (isWitness && weddingState !== WsWeddingStates.ceremony) {
          closeSocket();
          logout();
        }

        dispatch(
          weddingActions.updateWedding(
            data?.couple?.engagementType === EngagementType.Person
              ? {
                  type: data?.couple?.engagementType,
                  ...data,
                }
              : {
                  type: data?.couple?.engagementType,
                  ...data,
                  members: [
                    data.members[0],
                    {
                      user: {
                        firstName: data?.couple.nftModel?.meta?.nickname,
                        lastName: '',
                        sex: data.members[0].user.sex === SexType.man ? SexType.woman : SexType.man,
                        profileImage: data?.couple.nftModel?.meta?.imageUrl,
                        isOnline: true,
                        contscts: data?.couple.nftModel?.contacts,
                      },
                      token: {
                        meta: {
                          imageUrl:
                            collectionRingsMock[(data?.collection?.name as keyof CollectionRingsProps) || 'gold'],
                        },
                      },
                    },
                  ],
                },
          ),
        );
        if(weddingState == 'select_collection') {
          console.log('selectRing');
          dispatch(
              weddingActions.selectRing({
                collectionId: 'c9ee9455-cd31-4d0d-af9e-a542bf4dec1a'
              }),
          );
          dispatch(weddingActions.confirmRings());
        } else if(weddingState == 'select_diamond') {
          console.log('selectDiamond');
          dispatch(
              weddingActions.selectDiamond({ wbtcAmountUsd: 0.0}),
          );
          dispatch(weddingActions.confirmPreviewRings());
        } else if(weddingState == 'show_room') {
          console.log('confirmPreviewRings');
          dispatch(weddingActions.confirmPreviewRings());
        } else if(weddingState == 'vows' && !data.marriedAt) {
          console.log('setPaymentSuccess');
          dispatch(weddingActions.setPaymentSuccess());
        }
        if (weddingState == 'vows') {
          console.log('selectGodWitness');
          if(data.members[0].vows !== null && data.members[1].vows !== null) {
            dispatch(
                weddingActions.selectGodWitness({
                  godWitnessId: '0cdc5bb6-af87-4d74-85ff-63c2559d5a62',
                }),
            );
            console.log('confirmGodWitness');
            dispatch(weddingActions.confirmGodWitness());
          }
          navigate(wsWeddingRoutes['vows']);
        }
        if (!isHomePage && !isCheckChakra && weddingState != 'vows' && weddingState != 'select_collection' && weddingState != 'select_diamond' && weddingState != 'show_room') {
          if (isChakras) {
            navigate(routes.checkPromo.path);
          } else if(weddingState == 'init' && engagementType) {
              navigate(EngagementTypePath[engagementType]);
          } else {
            navigate(wsWeddingRoutes[weddingState]);
          }
        }


       };
      const socketInterval = setInterval(() => dispatch(weddingActions.sendEmptyMessage()), 30000);
      socket.onclose = () => {
        clearInterval(socketInterval);
        // if(email||(isWitness && weddingState === WsWeddingStates.ceremony)){
        if (userId && !isWitness && engagementType) {
          dispatch(weddingActions.getEngagement({ engagementType }));
        } else {
          navigate(routes.home.path);
        }
      };
    }
    // return () => socket?.close();
  }, [dispatch, userId, engagementId, engagementType, isHomePage, isWitness, navigate]);

  return (
    <Box
      sx={{
        overflowX: 'hidden',
        position: 'relative',
        minHeight: '100%',
      }}
    >
      <Layout>
        <RouteManager />
      </Layout>
      <ModalContainer />
    </Box>
  );
};

export default (
  <GlobalProvider>
    <App />
  </GlobalProvider>
);
