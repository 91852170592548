import { useDispatch } from 'react-redux';
import { Button, CircularProgress, Stack, Typography } from '@mui/material';
import { HeartWithUsers } from 'entities/heart-with-users';
import { weddingActions } from 'entities/wedding';
import { COLOR_WHITE, uiSelector } from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import { checkRequestStatusEquality } from 'shared/utils';
import { useTranslation } from 'react-i18next';

type MatchUserProps = {
  avatar: string;
  firstName: string;
  lastName: string;
};

type MatchContainerProps = {
  femaleProps: MatchUserProps;
  manProps: MatchUserProps;
  partnerFullName: string;
  type?: 'simple' | 'simpleWithName' | 'connection';
};

export const MatchContainer = ({ femaleProps, manProps, partnerFullName, type = 'simple' }: MatchContainerProps) => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const uiState = useShallowSelector(uiSelector.getUI);

    const isStartEngagementLoading = checkRequestStatusEquality(uiState[weddingActions.startEngagement?.type]);

    const handleGetMarried = () => {
        dispatch(weddingActions.startEngagement());
    };

  return (
    <Stack alignItems="center" mt={{ xs: 10, md: 14 }}>
      <HeartWithUsers femaleProps={femaleProps} manProps={manProps} size={'x-large'} type = {type} />
      <Typography variant="h4" mt={4} mb={{ xs: 4, md: 8 }}>
          {t('connection_fiance_engaged')}
      </Typography>
        {/*<Typography variant="h4" className="bold" mb={{ xs: 4, md: 8 }}>
        {partnerFullName}
      </Typography>*/}
      <Button variant="contained" onClick={handleGetMarried} disabled={isStartEngagementLoading} sx={{ width: 231 }}>
        {isStartEngagementLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : t('GetMarried')}
      </Button>
    </Stack>
  );
};
