import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button, CircularProgress, Grid } from '@mui/material';
import { Splide, SplideSlide } from '@splidejs/react-splide';
import { Ring, ringActions, ringSelector } from 'entities/ring';
import { User, UserRole, userSelector, UserStatus } from 'entities/user';
import { weddingActions, weddingSelector } from 'entities/wedding';
import { times } from 'lodash';
import { COLOR_WHITE, uiSelector } from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import { EngagementType, Nullable, SexType } from 'shared/types';
import { Slider, Typography } from 'shared/ui/components';
import { checkRequestStatusEquality } from 'shared/utils';
import { useTranslation } from 'react-i18next';

export const SelectRing = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const uiState = useShallowSelector(uiSelector.getUI);
  const { collections } = useShallowSelector(ringSelector.getRing);
  const { members, type } = useShallowSelector(weddingSelector.getWedding);
  const { id: userId, sex: userSex } = useShallowSelector(userSelector.getUser);

  const [activeSlideIndex, setActiveSlideIndex] = useState<number>(0);
  const [clickSlideIndex, setClickSlideIndex] = useState<Nullable<number>>(null);
  const [userRingId, setUserRingId] = useState<Nullable<string>>(null);

  const currentUser = members.find(({ user: { id } }) => id === userId);
  const partner = members.find(({ user: { id } }) => id !== userId);
  const partnerRingId = partner?.collection?.id || null;
  const isUsersChoseSameRings = partnerRingId && userRingId ? partnerRingId === userRingId : false;

  const currentUserDisplayData = {
    status: UserStatus.You,
    firstName: currentUser?.user.firstName || '',
    lastName: currentUser?.user.lastName || '',
    avatar: currentUser?.user.profileImage,
  };

  const partnerDisplayData = {
    status: partner?.user.isOnline ? UserStatus.Online : UserStatus.Offline,
    firstName: partner?.user.firstName || '',
    lastName: partner?.user.lastName || '',
    avatar: partner?.user.profileImage,
  };
  const firstUserDisplayData = userSex === SexType.woman ? currentUserDisplayData : partnerDisplayData;
  const secondUserDisplayData = userSex === SexType.man ? currentUserDisplayData : partnerDisplayData;

  const isSelectRingLoading = checkRequestStatusEquality(uiState[weddingActions.selectRing?.type]);
  const isConfirmRingsLoading = checkRequestStatusEquality(uiState[weddingActions.confirmRings?.type]);

  const selectRing = useCallback(
    (index: number) => {
      setUserRingId(collections[index]?.id);
      dispatch(
        weddingActions.selectRing({
          collectionId: collections[index]?.id,
        }),
      );
    },
    [collections, dispatch],
  );

  const handleSlideMove = (splide: Splide, index: number) => {
    setActiveSlideIndex(index);
  };

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleSlideClick = (splide: Splide, event: any) => {
    setClickSlideIndex(event.index);
  };

  const handleNext = () => {
    dispatch(weddingActions.confirmRings());
    //dispatch(weddingActions.selectDiamond());
  };

  useEffect(() => {
    dispatch(ringActions.getCollections());
  }, [dispatch]);

  useEffect(() => {
    setUserRingId((prev) => (prev === null ? currentUser?.collection?.id || null : prev));
  }, [currentUser?.collection?.id]);

  useEffect(() => {
    if (clickSlideIndex === activeSlideIndex) {
      selectRing(clickSlideIndex);
    }
  }, [activeSlideIndex, clickSlideIndex, selectRing]);

  return (
    <Grid container justifyContent="space-between" gap={2} sx={{
      alignItems: 'flex-start',
      mx: 'auto',
      maxWidth: '1170px',
      px: { xs: 1.5, md: 3 },
      mb: { xs: 3, sm: 5 },
      mt: { xs: 2, sm: 3 },
    }}>
      <Grid item>
        <User {...firstUserDisplayData} role={UserRole.Female} />
      </Grid>
      <Grid
        item
        container
        order={{ xs: 3, lg: 2 }}
        xs={12}
        lg="auto"
        justifyContent="center"
        direction="column"
        spacing={{ xs: 2, md: 5 }}
      >
        <Grid item mt={{ xs: 0, lg: 1 }}>
          <Typography variant="h3" className="center">
            Select the Rings
          </Typography>
          <Typography variant="body2" className="center" mt={{ xs: 1, md: 3 }}>
            choose one category together
          </Typography>
        </Grid>
        <Grid item mx="auto">
          <Slider onMoved={handleSlideMove} onClick={handleSlideClick}>
            {collections.length
              ? collections.map(({ id, imageUrl, name, priceUsd }) => (
                  <SplideSlide key={id}>
                    <Ring
                      img={imageUrl}
                      category={name}
                      activeBorder={userRingId === id}
                      price={priceUsd}
                      withChecks
                      activeFirstCheck={userRingId === id}
                      activeSecondCheck={partnerRingId === id || (type === EngagementType.Model && userRingId === id)}
                    />
                  </SplideSlide>
                ))
              : times(3, (index) => (
                  <SplideSlide key={index}>
                    <Ring img="" />
                  </SplideSlide>
                ))}
          </Slider>
        </Grid>
        <Grid item mx="auto" mt={{ xs: 7, md: 3 }}>
          <Button
            variant="contained"
            sx={{ minWidth: 189 }}
            onClick={handleNext}
            disabled={
              !userRingId ||
              (!isUsersChoseSameRings && type === EngagementType.Person) ||
              isConfirmRingsLoading ||
              isSelectRingLoading
            }
          >
            {isConfirmRingsLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : t('Next')}
          </Button>
        </Grid>
      </Grid>
      <Grid item order={{ xs: 2, lg: 3 }}>
        <User {...secondUserDisplayData} role={UserRole.Man} />
      </Grid>
    </Grid>
  );
};
