import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Container, CssBaseline, Stack } from '@mui/material';
import { userActions, userSelector } from 'entities/user';
import { socket } from 'shared/api';
import { useShallowSelector } from 'shared/hooks';
import { BlurEllipseBlue, BlurEllipsePurple } from 'shared/ui/components';

import { AboutUs } from './ui/AboutUs';
//import { AboutYou } from './ui/AboutYou';
//import { Footer } from './ui/Footer';
//import { MarriageAmbassadors } from './ui/MarriageAmbasadors';
import { Preview } from './ui/Preview';
import { PreviewRings } from './ui/PreviewRings';
//import { Roadmap } from './ui/Roadmap';
import { SeenOn } from './ui/SeenOn';
//import { Team } from './ui/Team';
import { useTranslation } from 'react-i18next';

//import '@fontsource/dm-sans/700.css';
//import '@fontsource/dm-sans/400.css';
//import '@fontsource/work-sans/800.css';
//import '@fontsource/work-sans/700.css';
//import '@fontsource/work-sans/600.css';
//import '@fontsource/work-sans/400.css';
//import '@fontsource/jetbrains-mono/800.css';
//import '@fontsource/jetbrains-mono/400.css';
//import '@fontsource/inter/600.css';
//import '@fontsource/inter/500.css';
//import '@fontsource/inter/400.css';
import Header from "./ui/MainPage/Header";
import { ContainerMain } from "./ui/MainPage/styles/ContainerMain";
import Hero from "./ui/MainPage/Hero";
import Cards from "./ui/MainPage/Cards";
import WhatIs from "./ui/MainPage/WhatIs";
import Why from "./ui/MainPage/Why";
import HowItWorks from "./ui/MainPage/HowItWorks";
import Love from "./ui/MainPage/Love";
import Vow from "./ui/MainPage/Vow";
import Inspiration from "./ui/MainPage/Inspiration";
import Footer from "./ui/MainPage/Footer";

export const Home = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { isWitness } = useShallowSelector(userSelector.getUser);

  useEffect(() => {
    if (isWitness) {
      dispatch(userActions.updateUserState({ isWitness: false }));
      if (socket) socket.close();
    }
  }, [dispatch, isWitness]);

  return (
    <>
        <Hero />
        <ContainerMain>
            <Cards />
            <WhatIs />
            <Why />
            <HowItWorks />
            <Love />
            <Vow />
            {/*<Inspiration />*/}
        </ContainerMain>
        <Footer />
        {/*
      <CssBaseline />
      <Container
        sx={{
          position: 'relative !important',
          overflowX: 'visible',
          overflowY: 'visible',
          minHeight: '100vh',
          paddingBottom: { xs: 2, sm: 4.5 },
        }}
      >
        <Stack position="relative" zIndex={2} alignItems="center">
          <Preview />
          <PreviewRings />
          <AboutUs />
          <MarriageAmbassadors />
          <AboutYou />
          <SeenOn />
          <Team />
           <Roadmap />
        </Stack>
      </Container>
      <Footer />
      <BlurEllipseBlue
        sx={{
          zIndex: -2,
          position: 'absolute',
          left: { xs: '-50%', sm: '-32%' },
          top: { xs: '-5%', sm: '-14%' },
          width: { xs: 500, sm: 900, md: 1000, lg: 1435 },
          height: { xs: 400, md: 700 },
        }}
      />
      <BlurEllipsePurple
        sx={{
          zIndex: -2,
          position: 'absolute',
          right: { xs: '-50%', sm: '-32%' },
          top: { xs: '7%', sm: '5%' },
          width: { xs: 500, sm: 900, md: 1000, lg: 1435 },
          height: { xs: 400, md: 700 },
        }}
      />
      <BlurEllipseBlue
        sx={{
          zIndex: -2,
          position: 'absolute',
          left: { xs: '-50%', sm: '-32%' },
          top: { xs: '23%', sm: '16%' },
          width: { xs: 500, sm: 900, md: 1000, lg: 1435 },
          height: { xs: 400, md: 700 },
        }}
      />
      <BlurEllipsePurple
        sx={{
          zIndex: -2,
          position: 'absolute',
          right: { xs: '-200%', md: '-32%' },
          top: { xs: '31%', sm: '33%' },
          width: { xs: 500, sm: 900, md: 1000, lg: 1435 },
          height: { xs: 400, md: 700 },
        }}
      />
      <BlurEllipseBlue
        sx={{
          zIndex: -2,
          position: 'absolute',
          left: { xs: '-200%', md: '-32%' },
          top: { xs: '59%', sm: '50%' },
          width: { xs: 500, sm: 900, md: 1000, lg: 1435 },
          height: { xs: 400, md: 700 },
        }}
      />
      <BlurEllipsePurple
        sx={{
          zIndex: -2,
          position: 'absolute',
          right: { xs: '-200%', md: '-32%' },
          top: { xs: '68%', sm: '68%' },
          width: { xs: 500, sm: 900, md: 1000, lg: 1435 },
          height: { xs: 400, md: 700 },
        }}
      />*/}
    </>
  );
};
