
import { styled } from '@mui/material';

export const StyledFooter = styled('footer')`
  margin-top: 200px;
  @media (max-width: 480px) {
    margin-top: 160px;
  }
  background: #fff;
  padding: 50px 0 40px 0;
`;

export const TopFooter = styled('div')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 992px) {
    flex-direction: column;
    justify-content: center;
  }
  div {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex: 1;
    margin-right: 150px;
    @media (max-width: 992px) {
      width: 100%;
      margin-right: 0px;
      justify-content: center;
      flex-wrap: wrap;
    }
  }
  span {
    color: #000;
    font-weight: 600;
    @media (max-width: 992px) {
      width: 100%;
      text-align: center;
      margin-top: 30px;
    }
  }
`;

export const FooterLogo = styled('img')`
  width: 202px;
  height: 70px;
`;

export const StripeLogo = styled('img')`
  width: 130px;
  height: 51px;
  margin-right: 24px;
`;

export const VisaCard = styled('img')`
  width: 75px;
  height: 52px;
`;
export const MasterCard = styled('img')`
  width: 75px;
  height: 52px;
`;

export const PayWrapper = styled('div')`
  display: flex;
  ailign-items: center;
  justify-content: space-between;
  flex-direction: column;
  gap: 9px;
`;

export const MidFooter = styled('div')`
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 992px) {
    width: 100%;
    flex-wrap: wrap-reverse;
    justify-content: center;
    gap: 30px;
    margin-top: 30px;
  }
`;

export const MenuWrapper = styled('div')`
  display: flex;
  justify-content: flex-start;
  gap: 20px;
  a {
    color: #000;
    font-size: 16px;
    font-weight: 600;
  }
`;

export const CompaniesWrapper = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 22px;
  @media (max-width: 992px) {
    width: 100%;
    flex-direction: column;
  }
`;

export const Polygon = styled('img')`
  width: 150px;
  height: 54px;
`;

export const Cloudflare = styled('img')`
  width: 150px;
  height: 54px;
`;

export const Moon = styled('img')`
  width: 150px;
  height: 54px;
`;

export const BotFooter = styled('div')`
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  a {
    color: #000;
    font-size: 16px;
    font-weight: 600;
    text-decoration: underline;
    margin-bottom: 30px;
  }

  div {
    color: #000;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 10px;
    text-align: center;
  }
`;
