import { all, call } from 'redux-saga/effects';

import ceremonyTestify from './ceremonyTestify';
import confirmGodWitness from './confirmGodWitness';
import confirmPreviewRings from './confirmPreviewRings';
import confirmRings from './confirmRings';
import confirmWitnessInvite from './confirmWitnessInvite';
import deleteWitness from './deleteWitness';
import getEngagement from './getEngagement';
import getEngagementId from './getEngagementId';
import getPaymentLink from './getPaymentLink';
import getReferral from './getReferral';
import getWitnessInviteCode from './getWitnessInviteCode';
import selectDiamondAmount from './selectDiamond';
import selectGodWitness from './selectGodWitness';
import selectRing from './selectRing';
import selectVows from './selectVows';
import setPaymentSuccess from './setPaymentSuccess';
import sendEmptyMessage from './sendEmptyMessage';
import startEngagement from './startEngagement';

export function* weddingSagas() {
  yield all(
    [
      getEngagementId,
      getEngagement,
      startEngagement,
      selectRing,
      selectVows,
      confirmRings,
      selectDiamondAmount,
      confirmPreviewRings,
      getPaymentLink,
      getReferral,
      selectGodWitness,
      confirmGodWitness,
      getWitnessInviteCode,
      confirmWitnessInvite,
      setPaymentSuccess,
      deleteWitness,
      ceremonyTestify,
      sendEmptyMessage,
    ].map(call),
  );
}
