import { Box, IconButton, Link, Stack, styled, Typography } from '@mui/material';
import { FOOTER_HEIGHT_LG, FOOTER_HEIGHT_MD } from 'pages/home/constants';
import { CloudflareIcon, MoonpayIcon, PolygonLogo } from 'shared/assets';
import { COLOR_ACCENT, COLOR_BG, COLOR_BLACK, COLOR_WH } from 'shared/config/theme/colors';
import { FontWeights, FontFamilies } from 'shared/config/theme/Typography';
import { useIsDownLayoutMd } from 'shared/hooks';
import { useTranslation } from 'react-i18next';

import { Web3WedLogo } from '../Icons';

import { LINKS, PAY_SERVICES, SOCIALS } from './Footer.helper';

const SocialsWrapper = styled(Stack)(({ theme }) => ({
  paddingBottom: FOOTER_HEIGHT_LG,
  marginBottom: 120,
  marginTop: 76,

  [theme.breakpoints.down('md')]: {
    paddingBottom: FOOTER_HEIGHT_MD,
    marginBottom: 60,
    marginTop: 40,
  },
}));

const FooterWrapper = styled(Stack)(({ theme }) => ({
  backgroundColor: COLOR_WH,
  height: FOOTER_HEIGHT_LG,
  width: '100%',
  position: 'absolute',
  bottom: 0,
  left: 0,
  zIndex: 2,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    height: FOOTER_HEIGHT_MD,
  },
}));
const FooterRow = styled(Stack)(({ theme }) => ({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',

  [theme.breakpoints.down('md')]: {
    alignItems: 'center',
  },
}));

const IconsRow = styled(Stack)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  flexDirection: 'row',

  [theme.breakpoints.down('md')]: {
    flexDirection: 'column',
    justifyContent: 'center',
  },
}));

const SocialButton = styled(IconButton)({
  width: 56,
  height: 56,
  background: 'transparent',
  border: `1px solid ${COLOR_ACCENT}`,
  borderRadius: '50%',
  svg: { color: COLOR_ACCENT },
  '&:hover': {
    background: COLOR_ACCENT,
    svg: { color: COLOR_BG },
  },
});

import {
  StyledFooter,
  TopFooter,
  MidFooter,
  BotFooter,
  FooterLogo,
  PayWrapper,
  MasterCard,
  VisaCard,
  MenuWrapper,
  CompaniesWrapper,
  Polygon,
  Cloudflare,
  Moon,
} from "../MainPage/styles/StyledFooter";
import FooterLogoSrc from "../../../../shared/assets/img/footer/footer-icon.svg";
import VisaCardSrc from "../../../../shared/assets/img/footer/visa.png";
import MasterCardSrc from "../../../../shared/assets/img/footer/mastercard.png";
import PolygonSrc from "../../../../shared/assets/img/footer/polygon.png";
import CloudSrc from "../../../../shared/assets/img/footer/cloud.png";
import MoonSrc from "../../../../shared/assets/img/footer/moon.png";

import { ContainerMain } from "../MainPage/styles/ContainerMain";

export const Footer = () => {
  const isDownMdLayout = useIsDownLayoutMd();

  const { t, i18n } = useTranslation();
  return (
    <>
      <StyledFooter>
        <ContainerMain>
          <TopFooter>
            <div>
              <MenuWrapper>
                <a href="https://web3wed.gitbook.io/web3wed">Whitepaper</a>
                <a href="https://linktr.ee/web3wed">LinkTree</a>
                <a href="https://web3wed.gitbook.io/web3wed/help">Help</a>
                <a href="https://web3wed.gitbook.io/web3wed/terms-of-use">Terms of use</a>
              </MenuWrapper>
            </div>
            <span>We accept</span>
          </TopFooter>
          <MidFooter>
            <FooterLogo src={FooterLogoSrc} alt="Footer Logo" />
            <CompaniesWrapper>
              <Cloudflare src={CloudSrc} alt="Cloudflare Logo" />
              <Polygon src={PolygonSrc} alt="Polygon Logo" />
              <Moon src={MoonSrc} alt="MoonPay Logo" />
            </CompaniesWrapper>
            <PayWrapper>
              <VisaCard src={VisaCardSrc} alt="Visa Card Logo" />
              <MasterCard src={MasterCardSrc} alt="Master Card Logo" />
            </PayWrapper>
          </MidFooter>
          <BotFooter>
            <a href="mailto:hello@web3wed.io">hello@web3wed.io</a>
            <div>COPYRIGHT 2023-2025 W3B0DZ LLC. ALL RIGHTS RESERVED</div>
            <div>
              Shams Business Center, Sharjah Media City Free Zone, Al Messaned,
              Sharjah, UAE.
            </div>
          </BotFooter>
        </ContainerMain>
      </StyledFooter>
    </>
  );
};

