
import { styled } from '@mui/material';
import ring from "../../../../../shared/assets/img/hero/ring.png";
import mobileRing from "../../../../../shared/assets/img/hero/mobile.png";

export const StyledHero = styled('section')`
  margin-top: -90px;
  padding: 200px 0;
  background-image: url(${ring});
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
  display: flex;
  align-items: center;
  justify-content: center;

  @media (max-width: 540px) {
    background-image: url(${mobileRing});
    padding: 300px 0 100px 0;
  }
`;

export const ContentWrapper = styled('div')`
  margin-top: 250px;
  display: flex;
  padding: 0 15px;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 540px) {
    margin-top: 20px;
    margin-bottom: -100px;
  }

  @keyframes fadeInFromTop {
    from {
      opacity: 0;
      transform: translateY(-50px);
    }
    to {
      opacity: 1;
      transform: translateY(0);
    }
  }

  h1 {
    font-size: 48px;
    font-weight: 700;
    line-height: 52.8px;
    text-align: center;
    opacity: 0;
    transform: translateY(-50px);
    animation: fadeInFromTop 1s ease-out forwards;

    @media (max-width: 768px) {
      font-size: 32px;
      line-height: 40.8px;
    }
    @media (max-width: 530px) {
      font-size: 25px;
      line-height: 30.8px;
    }
  }

  p {
    margin-top: 12px;
    font-family: "Tektur", serif;
    font-size: 24px;
    font-weight: 400;
    background: radial-gradient(circle, #2df6de 20%, #3c5dfe 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    text-align: center;
    line-height: 27px;
    opacity: 0;
    transform: translateY(-50px);
    animation: fadeInFromTop 1s ease-out forwards;
    animation-delay: 0.5s;

    @media (max-width: 410px) {
      font-size: 20px;
    }
  }

  button {
    padding: 17px 40px;
    margin-top: 24px;
    border: 2px solid #2df6de;
    box-shadow: 0px 0px 16px -2px rgba(45, 246, 222, 0.6);
    color: #4bfffc;
    font-size: 18px;
    font-weight: 700;
    border-radius: 63px;
    background: transparent;
    font-family: "Montserrat", sans-serif;
    transition: background 0.3s ease;
    opacity: 0;
    transform: translateY(-50px);
    animation: fadeInFromTop 1s ease-out forwards;
    animation-delay: 1s;

    &:hover {
      background: #fff;
      border-color: transparent;
      color: #000;
      cursor: pointer;
    }
  }
`;
