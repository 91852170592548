import {
  StyledVow,
  InfoWrapper,
  InfoItem,
  RegionWrapper,
  InfoItemText,
  VideoIcon,
  PrivateIcon,
  CertIcon,
  RingIcon,
  SubtitleText,
  InfoBtn,
} from "./styles/StyledVow";
import VideoIconSrc from "../../../../shared/assets/img/vow/film.svg";
import PrivateIconSrc from "../../../../shared/assets/img/vow/confidential-discussion.svg";
import RingIconSrc from "../../../../shared/assets/img/vow/ring.svg";
import CertIconSrc from "../../../../shared/assets/img/vow/diploma.svg";
// import FlagsIconSrc from "../assets/img/vow/flags.svg";
import { useTranslation } from 'react-i18next';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useShallowSelector} from "../../../../shared/hooks";
import {userSelector} from "../../../../entities/user";
import {weddingSelector} from "../../../../entities/wedding";
import {modalActions, ModalType} from "../../../../entities/modal";
import {routes, wsWeddingRoutes} from "../../../../app/providers/router/routes";

const Vow = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: userId } = useShallowSelector(userSelector.getUser);
  const { state } = useShallowSelector(weddingSelector.getWedding);
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (!userId) {
      dispatch(
          modalActions.openModal({
            type: ModalType.ConnectWallet,
          }),
      );
    } else {
      navigate(state ? wsWeddingRoutes[state] : routes.connection.path);
    }
  };
  return (
    <StyledVow>
      <h2>{t('vow_title')}</h2>
      <SubtitleText>{t('vow_desc')}</SubtitleText>
      <InfoWrapper>
        <InfoItem>
          <VideoIcon src={VideoIconSrc} alt="Video Icon" />
          <h4>{t('vow_title_1')}</h4>
          <InfoItemText>{t('vow_desc_1')}</InfoItemText>
        </InfoItem>
        <InfoItem>
          <PrivateIcon src={PrivateIconSrc} alt="Private Icon" />
          <h4>
            {t('vow_title_2')}
          </h4>
          <InfoItemText>{t('vow_desc_2')}</InfoItemText>
        </InfoItem>
        <InfoItem>
          <RingIcon src={RingIconSrc} alt="Ring Icon" />
          <h4>{t('vow_title_3')}</h4>
          <InfoItemText>{t('vow_desc_3')}</InfoItemText>
        </InfoItem>
        <InfoItem>
          <CertIcon src={CertIconSrc} alt="Diploma Icon" />
          <h4>{t('vow_title_4')}</h4>
          <InfoItemText>
            {t('vow_desc_4')}
          </InfoItemText>
        </InfoItem>
      </InfoWrapper>
      <span>$ 111.00</span>
      <InfoBtn onClick={handleButtonClick}>{t('vow_desc_5')}</InfoBtn>
      {/*<RegionWrapper>
        <InfoItemText>{t('vow_desc_6')}</InfoItemText>
         <RegionFlags src={FlagsIconSrc} alt="Flags Icon" />
        <a href="#">{t('vow_desc_7')}</a>
      </RegionWrapper>*/}
    </StyledVow>
  );
};

export default Vow;
