import crypto from "crypto";
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

// Секретный ключ (ВАЖНО: Храните секретный ключ в безопасном месте!)
const secret = "7f0f1aa34d1e20aaab1fd8480db04175bbcc416e8d236039c0fb3e5ce5c48f98";

interface DecodeResult {
    valid: boolean;
    error?: string;
    dates?: {
        partner1: string | null;
        partner2: string | null;
    };
    timestamp?: number;
    expectedSig?: string;
    givenSig?: string;
}

function decodeBase36Date(encoded: string): string | null {
    if (!encoded) return null;
    try {
        const num = parseInt(encoded, 36).toString().padStart(6, "0");
        const year = "20" + num.slice(0, 2);
        const month = num.slice(2, 4);
        const day = num.slice(4, 6);
        return `${year}-${month}-${day}`;
    } catch (error) {
        console.error('', error);
        return null; // Важно вернуть null в случае ошибки
    }
}

export function decodePromoCode(code: string): DecodeResult {
    if (!code) {
        return { valid: false, error: '' };
    }

    const [d1, d2, ts, sig] = code.split("-");

    if (!d1 || !d2 || !ts || !sig) {
        return { valid: false, error: '' };
    }

    const payload = `${d1}-${d2}-${ts}`;

    try {
        const hash = crypto
            .createHmac("sha256", secret)
            .update(payload)
            .digest("hex")
            .substring(0, 8)
            .toUpperCase();

        const valid = hash === sig.toUpperCase();

        return {
            valid,
            dates: {
                partner1: decodeBase36Date(d1),
                partner2: decodeBase36Date(d2),
            },
            timestamp: parseInt(ts, 36),
            expectedSig: hash,
            givenSig: sig.toUpperCase(),
        };
    } catch (error) {
        //console.error(t('checkpromo_4'), error);
        return { valid: false, error: '' };
    }
}

interface PromoCodeDecoderProps {
    promoCode: string;
}

const PromoCodeDecoder: React.FC<PromoCodeDecoderProps> = ({ promoCode }) => {
    const { t } = useTranslation();
    const [decodedData, setDecodedData] = useState<DecodeResult | null>(null);

    useEffect(() => {
        if (promoCode) {
            const result = decodePromoCode(promoCode);
            setDecodedData(result);
        } else {
            setDecodedData(null);
        }
    }, [promoCode]);

    if (!promoCode) {
        return <p>{t('checkpromo_6')}</p>;
    }

    if (!decodedData) {
        return <p>{t('checkpromo_7')}</p>; // Или отображение спиннера
    }

    if (!decodedData.valid) {
        return <p>Ошибка: {decodedData.error || t('checkpromo_8')}</p>;
    }

    return (
        <div>
            <p>{t('checkpromo_9')}</p>
            <p>{t('checkpromo_10')}</p>
        </div>
    );
};

export default PromoCodeDecoder;
