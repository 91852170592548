import { useState } from 'react';
import { useDispatch } from 'react-redux';
import {Box, Button, CircularProgress, Divider, Grid, Stack} from '@mui/material';
import BigNumber from 'bignumber.js';
import { Bill } from 'entities/bill';
import { modalActions, ModalType } from 'entities/modal';
import { Ring } from 'entities/ring';
import { User, UserRole, userSelector, UserStatus } from 'entities/user';
import { weddingActions, weddingSelector } from 'entities/wedding';
import { MoonpayBlackIcon } from 'shared/assets';
import { COLOR_DARK, COLOR_WHITE, uiSelector } from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import { SexType } from 'shared/types';
import { Typography } from 'shared/ui/components';
import { checkRequestStatusEquality } from 'shared/utils';
import { useTranslation } from 'react-i18next';

export const Payment = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const uiState = useShallowSelector(uiSelector.getUI);
  const { members, payment } = useShallowSelector(weddingSelector.getWedding);
  const { id: userId, sex: userSex } = useShallowSelector(userSelector.getUser);
  const isUserMan = userSex === SexType.man;
  const [promocodeState, setPromocodeState] = useState('');
  const handleOpenMoonpay = () => {
    dispatch(
      modalActions.openModal({
        type: ModalType.Moonpay,
        data: {
          link: payment?.checkoutUrl || '',
        },
      }),
    );
  }

  const isFirstMemberMan = members[0]?.user.sex === SexType.man;
  const manAddress = isFirstMemberMan ? members[0]?.user.address : members[1]?.user.address;

  const currentUser = members.find(({ user: { id } }) => id === userId);
  const partner = members.find(({ user: { id } }) => id !== userId);

  const currentUserDisplayData = {
    status: UserStatus.You,
    firstName: currentUser?.user.firstName || '',
    lastName: currentUser?.user.lastName || '',
    avatar: currentUser?.user.profileImage,
    ringImg: currentUser?.token?.meta?.imageUrl || '',
    ringPrice: currentUser?.token?.ringPriceUsd || 0,
    diamondPrice: currentUser?.token?.wbtcAmountUsd || 0,
    diamondPriceInToken: currentUser?.token?.wbtcAmount || 0,
  };

  const partnerDisplayData = {
    status: partner?.user.isOnline ? UserStatus.Online : UserStatus.Offline,
    firstName: partner?.user.firstName || '',
    lastName: partner?.user.lastName || '',
    avatar: partner?.user.profileImage,
    ringImg: partner?.token?.meta?.imageUrl || '',
    ringPrice: partner?.token?.ringPriceUsd || 0,
    diamondPrice: partner?.token?.wbtcAmountUsd || 0,
    diamondPriceInToken: partner?.token?.wbtcAmount || 0,
  };

  const firstUserDisplayData = isUserMan ? currentUserDisplayData : partnerDisplayData;
  const secondUserDisplayData = !isUserMan ? currentUserDisplayData : partnerDisplayData;


  const isPayLoading = checkRequestStatusEquality(uiState[weddingActions.confirmPreviewRings?.type]);
  const billData = {
    firstRingPrice: firstUserDisplayData.ringPrice,
    firstDiamondPrice: firstUserDisplayData.diamondPrice,
    firstDiamondPriceInToken: firstUserDisplayData.diamondPriceInToken,
    secondRingPrice: secondUserDisplayData.ringPrice,
    secondDiamondPrice: secondUserDisplayData.diamondPrice,
    secondDiamondPriceInToken: secondUserDisplayData.diamondPriceInToken,
    certificatePrice: payment?.certificatePriceUsd || 0,
    fee: payment?.platformFeeUsd || 0,
    totalPrice: payment?.totalAmountUsd || 0,
  };

  return (
    <Grid container justifyContent="space-between" spacing={2} sx={{
      alignItems: 'flex-start',
      mx: 'auto',
      width: 'calc(100% - 24px)',
      maxWidth: '1170px',
      px: { xs: 1.5, md: 3 },
      mb: { xs: 3, sm: 5 },
      mt: { xs: 2, sm: 3 },
    }}>
      <Grid item container gap={{ xs: 2, md: 3 }} direction="column" xs="auto"
            sx={{
              pl: '0!important'
            }}>
        <Grid item>
          <User {...firstUserDisplayData} role={UserRole.Female} />
        </Grid>
        <Grid item>
          <Ring img={firstUserDisplayData.ringImg} size="small" simple />
        </Grid>
      </Grid>
      <Grid
        item
        container
        order={{ xs: 3, md: 2 }}
        xs={12}
        md="auto"
        direction="column"
        alignItems="center"
        gap={{ xs: 2, sm: 5 }}
        sx={{
          pl: '0!important'
        }}
      >
        <Grid item mt={{ xs: 5, md: 4 }}>
          <Typography variant="h2" className="center white">
            {t('ring_Payment_p1')}
          </Typography>
        </Grid>
        <Grid item>
          <Bill {...billData} setPromocodeState={(v: string) => setPromocodeState(v)} />
        </Grid>
        <Grid item container mt={{ xs: 1, md: 0 }} rowGap={1.75} direction="column" alignItems="center">
          {/* <Typography variant="body2" className="center">
            PLEASE PASTE THIS ADDRESS TO <br />
            <Typography component="span" variant="body2" className="bold center">
              &quot;Polygon wallet address&quot;:
            </Typography>
          </Typography>
          <CopyAddress text={manAddress} />
          <Typography variant="body2" maxWidth={312} className="center">
            It`s very importnat for further proceeding with the wedding.
          </Typography> */}
          <Button
            variant="contained"
            sx={{ gap: 2, minWidth: 253, mt: 4 }}
            id="buyWithMoonPay"
            onClick={handleOpenMoonpay}
          >
            {isPayLoading ? (
              <CircularProgress size={30} sx={{ color: COLOR_WHITE }} />
            ) : (
              <>
                <Box component="img" src={MoonpayBlackIcon} width={78} />
                <Divider orientation="vertical" sx={{ background: COLOR_DARK }} />
                <Typography className="dark semi-bold">
                  {t('ring_Payment_pay')}
                  <Typography component="span" className="dark">
                    &nbsp;${new BigNumber(billData.totalPrice).toFixed(2, 0).toString()}
                  </Typography>
                </Typography>
              </>
            )}
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        container
        gap={{ xs: 2, md: 3 }}
        direction="column"
        order={{ xs: 2, md: 3 }}
        xs="auto"
        alignItems="end"
        sx={{
          pl: '0!important'
        }}
      >
        <Grid item>
          <User {...secondUserDisplayData} role={UserRole.Man} />
        </Grid>
        <Grid item>
          <Ring img={secondUserDisplayData.ringImg} size="small" simple />
        </Grid>
      </Grid>
    </Grid>
  );
};
