import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { State } from 'app/store';
import { EngagementType, Nullable } from 'shared/types';

import {
  GetEngagementReq,
  GetPaymentLinkReq,
  GetReferralReq,
  SelectDiamondReq,
  SelectGodWitnessReq,
  SelectRingReq, SelectVows,
} from '../api';

import {
  CertificateState,
  CollectionState,
  GodWitnessState,
  PaymentState,
  VowsState,
  WeddingMemberState,
  WitnessState,
} from './types';

export enum WsWeddingStates {
  init = 'init',
  selectCollection = 'select_collection',
  selectDiamond = 'select_diamond',
  vows = 'vows',
  vowsAdd = 'vowsAdd',
  showRoom = 'show_room',
  payment = 'payment',
  selectWitness = 'select_witness',
  ceremony = 'ceremony',
  final = 'final',
}

export type WeddingState = {
  type: Nullable<EngagementType>;
  id: string;
  state: Nullable<WsWeddingStates>;
  marriedAt: Nullable<string>;
  members: WeddingMemberState[];
  collection: Nullable<CollectionState>;
  godWitness: Nullable<GodWitnessState>;
  witness: Nullable<WitnessState>;
  invitationCode: Nullable<string>;
  payment: Nullable<PaymentState>;
  vows: Nullable<VowsState>;
  certificate: Nullable<CertificateState>;
  isReferralExist: Nullable<boolean>;
  referralAddress: Nullable<string>;
  referralCodeOwnerAddress: Nullable<string>;
};

export const initialState: WeddingState = {
  type: null,
  id: '',
  state: null,
  marriedAt: null,
  members: [],
  collection: null,
  godWitness: null,
  witness: null,
  invitationCode: null,
  vows: null,
  payment: null,
  certificate: null,
  isReferralExist: null,
  referralAddress: null,
  referralCodeOwnerAddress: null,
};

export const weddingSelector = {
  getWedding: (state: State): WeddingState => state.wedding,
};

const weddingSlice = createSlice({
  name: 'wedding',
  initialState,
  reducers: {
    updateWedding: (state, action: PayloadAction<Partial<WeddingState>>) => ({
      ...state,
      ...action.payload,
    }),

    clearWeddingState: () => ({
      ...initialState,
    }),

    /** For saga */
    getEngagementId(state, action: PayloadAction) {},
    getEngagement(state, action: PayloadAction<GetEngagementReq>) {},
    startEngagement(state, action: PayloadAction) {},
    selectRing(state, action: PayloadAction<SelectRingReq>) {},
    selectVows(state, action: PayloadAction<SelectVows>) {},
    confirmRings(state, action: PayloadAction) {},
    selectDiamond(state, action: PayloadAction<SelectDiamondReq>) {},
    confirmPreviewRings(state, action: PayloadAction) {},
    setPaymentSuccess(state, action: PayloadAction) {},
    getPaymentLink(state, action: PayloadAction<GetPaymentLinkReq>) {},
    getReferral(state, action: PayloadAction<GetReferralReq>) {},
    selectGodWitness(state, action: PayloadAction<SelectGodWitnessReq>) {},
    getWitnessInviteCode(state, action: PayloadAction) {},
    confirmWitnessInvite(state, action: PayloadAction) {},
    confirmGodWitness(state, action: PayloadAction) {},
    ceremonyTestify(state, action: PayloadAction) {},
    deleteWitness(state, action: PayloadAction) {},
    sendEmptyMessage(state, action: PayloadAction) {},
  },
});

export const { reducer: weddingReducer } = weddingSlice;
export const { actions: weddingActions } = weddingSlice;
