import {
  StyledCards,
  RingCard,
  InfoCard,
  TopCards,
  BubbleContent,
  BubbleWrapper,
} from "./styles/StyledCards";
import {
  SliderWrapper,
  SliderItem,
  SliderText,
  GradientOverlay,
} from "./styles/StyledLove";
import { useRef, useState, useEffect } from "react";
import { Splide, SplideSlide } from "@splidejs/react-splide";
import "@splidejs/react-splide/css";
import videoFirst from '../../../../shared/assets/video/cards/W3W_short_vert_01.mp4';
import videoSecond from '../../../../shared/assets/video/cards/W3W_short_vert_02.mp4';
import videoThird from '../../../../shared/assets/video/cards/W3W_short_vert_03.mp4';
import play from "../../../../shared/assets/img/love/love-play.svg";
import bubble from "../../../../shared/assets/img/cards/buble.png";
import firstPoster from "../../../../shared/assets/img/posters/W3W_short_vert_01.jpg";
import secondPoster from "../../../../shared/assets/img/posters/W3W_short_vert_02.jpg";
import thirdPoster from "../../../../shared/assets/img/posters/W3W_short_vert_03.jpg";
import { useTranslation } from 'react-i18next';

const Cards = () => {
  const { t, i18n } = useTranslation();
  const videoRefs = [useRef(null), useRef(null), useRef(null)];
  const [isMobile, setIsMobile] = useState(false);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 992);
    };
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleMouseEnter = (index: any) => {
    if (videoRefs[index].current) {
      // @ts-ignore
      videoRefs[index].current.play();
    }
  };

  const handleMouseLeave = (index: any) => {
    if (videoRefs[index].current) {
      // @ts-ignore
      videoRefs[index].current.pause();
      // @ts-ignore
      videoRefs[index].current.currentTime = 0;
    }
  };

  const items = [
    { video: videoFirst, text: t('cards_item_1'), poster: firstPoster },
    { video: videoSecond, text: t('cards_item_2'), poster: secondPoster },
    { video: videoThird, text: t('cards_item_3'), poster: thirdPoster },
  ];

  return (
    <StyledCards>
      <TopCards>
        <RingCard>
          <div>
            <h3>{t('cards_item_title_1')}</h3>
            <p>
              {t('cards_item_desc_1')}
            </p>
          </div>
        </RingCard>
        <InfoCard>
          <h3>{t('cards_item_title_2')}</h3>
          <p>
            {t('cards_item_desc_2')} <br/> {t('cards_item_desc_2_1')}
          </p>
        </InfoCard>
        <BubbleWrapper>
          <img src={bubble} alt="" />
          <BubbleContent>
            <h3>{t('cards_item_title_3')}</h3>
            <p>
              {t('cards_item_desc_3')} <br/> {t('cards_item_desc_3_1')}
            </p>
          </BubbleContent>
        </BubbleWrapper>
      </TopCards>
      {isMobile ? (
        <Splide
          options={{
            arrows: false,
            pagination: true,
            drag: "free",
            gap: "1rem",
            breakpoints: {
              1200: {
                perPage: 2,
              },
              992: {
                perPage: 1,
              },
            },
          }}
        >
          {items.map((item, index) => (
            <SplideSlide key={item.video}>
              <SliderItem
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <video
                  ref={videoRefs[index]}
                  src={item.video}
                  muted
                  loop
                  playsInline
                  poster={item.poster}
                ></video>
                <GradientOverlay className="gradient" />
                <img src={play} alt="" />
                <SliderText>{item.text}</SliderText>
              </SliderItem>
            </SplideSlide>
          ))}
        </Splide>
      ) : (
        <SliderWrapper>
          {items.map((item, index) => (
            <div key={item.video} style={{ textAlign: "center" }}>
              <SliderItem
                onMouseEnter={() => handleMouseEnter(index)}
                onMouseLeave={() => handleMouseLeave(index)}
              >
                <video
                  ref={videoRefs[index]}
                  src={item.video}
                  muted
                  loop
                  playsInline
                  poster={item.poster}
                ></video>
                <GradientOverlay className="gradient" />
                <img src={play} alt="" />
                <SliderText>{item.text}</SliderText>
              </SliderItem>
            </div>
          ))}
        </SliderWrapper>
      )}
    </StyledCards>
  );
};

export default Cards;
