import {
  StyledHowItWorks,
  CardsWrapper,
  FirstCard,
  SecondCard,
  ThirdCard,
  CardHeader,
} from "./styles/StyledHowItWorks";
import socials from "../../../../shared/assets/img/howitworks/1.png";
import girl from "../../../../shared/assets/img/howitworks/2.png";
import cert from "../../../../shared/assets/img/howitworks/3.png";
import { useTranslation } from 'react-i18next';
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useShallowSelector} from "../../../../shared/hooks";
import {userSelector} from "../../../../entities/user";
import {weddingSelector} from "../../../../entities/wedding";
import {modalActions, ModalType} from "../../../../entities/modal";
import {routes, wsWeddingRoutes} from "../../../../app/providers/router/routes";

const HowItWorks = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { id: userId } = useShallowSelector(userSelector.getUser);
  const { state } = useShallowSelector(weddingSelector.getWedding);
  const { t } = useTranslation();

  const handleButtonClick = () => {
    if (!userId) {
      dispatch(
          modalActions.openModal({
            type: ModalType.ConnectWallet,
          }),
      );
    } else {
      navigate(state ? wsWeddingRoutes[state] : routes.connection.path);
    }
  };
  return (
    <StyledHowItWorks>
      <h2>{t('howit_title')}</h2>
      <p>
        {t('howit_desc')}<br/>{t('howit_desc__1')}
      </p>
      <CardsWrapper>
        <FirstCard>
          <CardHeader>
            <span>1</span>
            <p>{t('howit_items_1')}</p>
          </CardHeader>
          <img src={socials} alt={t('howit_items_1_alt')} />
        </FirstCard>
        <SecondCard>
          <CardHeader>
            <span>2</span>
            <p>{t('howit_items_2')}</p>
          </CardHeader>
          <img src={girl} alt={t('howit_items_2_alt')} />
        </SecondCard>
        <ThirdCard>
          <CardHeader>
            <span>3</span>
            <p>{t('howit_items_3')}</p>
          </CardHeader>
          <img src={cert} alt={t('howit_items_3_alt')} />
        </ThirdCard>
      </CardsWrapper>
      <button onClick={handleButtonClick}>{t('howit_a')}</button>
    </StyledHowItWorks>
  );
};

export default HowItWorks;
