import { EngagementType, EngagementTypeSocketParam } from 'shared/types';

// eslint-disable-next-line import/no-mutable-exports
export let socket: WebSocket | null = null;

// eslint-disable-next-line no-return-assign
export const connectSocket = (token: string, engagementType: EngagementType) => {

  if (socket && socket.readyState !== WebSocket.CLOSED) {
    return socket;
  }
  socket = new WebSocket(
    `${process.env.REACT_APP_WEBSOCKET_PATH}?access_token=${token}&engagement_type=${EngagementTypeSocketParam[engagementType]}`,
  );
  return socket;
};

export const closeSocket = () => {
  socket?.close();
  socket = null;
};
