
import { styled } from '@mui/material';

export const StyledHowItWorks = styled('section')`
  margin-top: 200px;
  @media (max-width: 480px) {
    margin-top: 160px;
  }
  h2 {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    @media (max-width: 725px) {
      text-align: center;
      line-height: 54px;
    }
    @media (max-width: 480px) {
      font-size: 26px;
      line-height: 29px;
    }
  }
  p {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    opacity: 0.8;
    margin-bottom: 50px;
    @media (max-width: 480px) {
      margin-bottom: 10px;
      font-size: 15px;
      line-height: 19px;
    }
  }
  text-align: center;
  color: #fff;
  button {
    padding: 17px 40px;
    border: 2px solid #2df6de;
    box-shadow: 0px 0px 16px -2px rgba(45, 246, 222, 0.6);
    color: #4bfffc;
    font-size: 18px;
    font-weight: 700;
    border-radius: 63px;
    background: transparent;
    font-family: "Montserrat", sans-serif;
    transition: background 0.3s ease;
    &:hover {
      cursor:pointer;
      background: #fff;
      border-color: transparent;
      color: #000;
      img {
        opacity: 0;
        visbility: hidden;
      }
    }
  }
`;

export const CardsWrapper = styled('div')`
  display: flex;
  justify-content: center;
  gap: 30px;
  margin-bottom: 80px;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
  @media (max-width: 480px) {
    margin-bottom: 10px;
  }
`;

const CardBase = styled('div')`
  width: 389px;
  height: 470px;
  padding: 25px 25px 0px 25px;
  border-radius: 20px;
  position: relative;
  display: flex;
  justify-content: space-between;
  align-item: center;
  flex-direction: column;
  transition: transform 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  text-align: center;
  background: #16161f;
  @media (max-width: 480px) {
    width: 319px;
    height: 372px;
  }
  @media (max-width: 375px) {
    width: 250px;
    height: 352px;
  }

  @media (any-hover: hover) {
    &:hover {
      transform: translateY(-10px);
      box-shadow: 0 10px 30px rgba(255, 255, 255, 0.2);
    }
  }

  span {
    font-size: 28px;
    font-weight: bold;
    display: block;
    -webkit-background-clip: text;
    color: transparent;
  }

  img {
    max-width: 100%;
  }
`;

export const CardHeader = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  p {
    font-size: 20px;
    font-weight: 600;
    color: #fff;
    opacity: 1;
    margin-bottom: 0px;
    @media (max-width: 480px) {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const FirstCard = styled(CardBase)`
  background: #16161f;
  transform: rotate(-6deg) translate(70px, 50px);
  @media (max-width: 992px) {
    transform: rotate(-6deg) translate(0px, 50px);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 24px;
    padding: 2px;
  }

  span {
    background: linear-gradient(45deg, #796b1b, #dfc532);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 36px;
    font-weight: 800;
  }
`;

export const SecondCard = styled(CardBase)`
  background: #16161f;
  transform: rotate(-2deg);
  transform-origin: 0 0;
  @media (max-width: 992px) {
    transform: rotate(3deg) translate(20px, -50px);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 24px;
    padding: 0.5px;
    background: linear-gradient(45deg, #ff00cc, #8a2be2);
    -webkit-mask: linear-gradient(#fff 0 0) content-box,
      linear-gradient(#fff 0 0);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
  }

  span {
    background: linear-gradient(45deg, #ce57f5, #6c327d);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 36px;
    font-weight: 800;
  }

  img {
    max-width: 281px;
    max-height: 325px;
    width: 100%;
    @media (max-width: 480px) {
      max-width: 229px;
      max-height: 246px;
      width: 100%;
    }
  }
`;

export const ThirdCard = styled(CardBase)`
  background: #16161f;
  transform: rotate(6deg) translate(-50px, 30px);
  @media (max-width: 992px) {
    transform: rotate(-3deg) translate(20px, -90px);
  }
  &::before {
    content: "";
    position: absolute;
    inset: 0;
    border-radius: 24px;
    padding: 2px;
    box-shadow: 0px 0px 36px 0px rgba(45, 246, 222, 0.6);
    -webkit-mask-composite: xor;
    mask-composite: exclude;
    z-index: 100;
  }

  span {
    background: linear-gradient(45deg, #4bfffc, #2d9997);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-size: 36px;
    font-weight: 800;
  }

  img {
    width: 297px;
    max-height: 313px;
    width: 100%;
    @media (max-width: 480px) {
      max-width: 100%;
      max-height: 221px;
      width: 100%;
    }
  }
`;
