import { Control, Controller } from 'react-hook-form';
import { Checkbox, FormControlLabel, Link, Stack, styled, Typography } from '@mui/material';
import { ConnectionFormState } from 'features/connection/model';
import { COLOR_ACCENT } from 'shared/config';

const StyledCheckBox = styled(Checkbox)(({ theme }) => ({
  margin: theme.spacing(0, 1),
}));

interface AgreementCheckboxProps {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  control: Control<ConnectionFormState, any>;
}

export const AgreementCheckbox = ({ control }: AgreementCheckboxProps) => (
  <Stack direction="row" alignItems="center" justifyContent={"center"}>
    <FormControlLabel
      control={
        <Controller
          name="agree"
          control={control}
          render={({ field: props }) => <StyledCheckBox {...props} checked={props.value} />}
        />
      }
      label={<Typography>I agree to</Typography>}
    />
    <Link
      variant="body1"
      href="https://web3wed.gitbook.io/web3wed/terms-of-use"
      target="_blank"
      rel="noreferrer"
      color={COLOR_ACCENT}
      ml={-1}
    >
      Terms and Conditions
    </Link>
  </Stack>
);
