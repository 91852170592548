import { weddingActions } from 'entities/wedding';
import { error, request, socket, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import { logger, snakeize } from 'shared/utils';
import { put, takeLatest } from 'typed-redux-saga';

export function* sendEmptyMessageSaga({ type }: SagaPayload<typeof weddingActions.sendEmptyMessage>) {
  yield* put(request(type));

  try {
    const data = { emit: 'empty' };

    console.log(socket);
    socket?.send(JSON.stringify(snakeize(data)));

    yield* put(success(type));
  } catch (err) {
    yield* put(error(type, err));
    logger('sendEmptyMessage', err);
  }
}

export default function* listener() {
  yield takeLatest(weddingActions.sendEmptyMessage.type, sendEmptyMessageSaga);
}
