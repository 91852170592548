import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { coupleActions, coupleSelector } from 'entities/couple';
import { userSelector } from 'entities/user';
import { FianceCodeContainer } from 'features/connection/containers';
import { MatchContainer } from 'features/match';
import { useShallowSelector } from 'shared/hooks';
import { EngagementType, EngagementTypePath, SexType } from 'shared/types';

export const FianceCode = () => {
  const navigate = useNavigate();
  const user = useShallowSelector(userSelector.getUser);
  const { engagementType, partner } = useShallowSelector(coupleSelector.getCouple);
  const isPartnerMale = partner?.sex === SexType.man;
  const partnerDisplayData = {
    firstName: partner?.firstName || '',
    lastName: partner?.lastName || '',
    avatar: partner?.profileImage || '',
  };
  const currentUserDisplayData = {
    firstName: user?.firstName || '',
    lastName: user?.lastName || '',
    avatar: user?.profileImage || '',
  };

  const dispatch = useDispatch();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    let interval: any;
    if (partner?.promoCode && !engagementType) {
      interval = setInterval(() => dispatch(coupleActions.getCouple()), 2000);
    } else if (interval) {
      clearInterval(interval);
      interval = null;
    }
    return () => {
      clearInterval(interval);
    };
  }, [partner, dispatch, engagementType]);

  useEffect(() => {
    if (engagementType === EngagementType.Model) {
      navigate(EngagementTypePath[engagementType]);
    }
  }, [engagementType, navigate]);

  return !partner?.promoCode || engagementType !== EngagementType.Person ? (
    <FianceCodeContainer />
  ) : (
    <MatchContainer
      partnerFullName={`${partner?.firstName} ${partner?.lastName}`}
      femaleProps={isPartnerMale ? currentUserDisplayData : partnerDisplayData}
      manProps={isPartnerMale ? partnerDisplayData : currentUserDisplayData}
      type={'connection'}
    />
  );
};
