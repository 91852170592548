import {
  StyledFooter,
  TopFooter,
  MidFooter,
  BotFooter,
  FooterLogo,
  PayWrapper,
  MasterCard,
  VisaCard,
  MenuWrapper,
  CompaniesWrapper,
  Polygon,
  Cloudflare,
  Moon,
} from "./styles/StyledFooter";
import FooterLogoSrc from "../../../../shared/assets/img/footer/footer-icon.svg";
import VisaCardSrc from "../../../../shared/assets/img/footer/visa.png";
import MasterCardSrc from "../../../../shared/assets/img/footer/mastercard.png";
import PolygonSrc from "../../../../shared/assets/img/footer/polygon.png";
import CloudSrc from "../../../../shared/assets/img/footer/cloud.png";
import MoonSrc from "../../../../shared/assets/img/footer/moon.png";

import { ContainerMain } from "./styles/ContainerMain";

const Footer = () => {
  return (
    <StyledFooter>
      <ContainerMain>
        <TopFooter>
          <div>
            <MenuWrapper>
              <a href="https://web3wed.gitbook.io/web3wed">Whitepaper</a>
              <a href="https://linktr.ee/web3wed">LinkTree</a>
              <a href="https://web3wed.gitbook.io/web3wed/help">Help</a>
              <a href="https://web3wed.gitbook.io/web3wed/terms-of-use">Terms of use</a>
            </MenuWrapper>
          </div>
          <span>We accept</span>
        </TopFooter>
        <MidFooter>
          <FooterLogo src={FooterLogoSrc} alt="Footer Logo" />
          <CompaniesWrapper>
            <Cloudflare src={CloudSrc} alt="Cloudflare Logo" />
            <Polygon src={PolygonSrc} alt="Polygon Logo" />
            <Moon src={MoonSrc} alt="MoonPay Logo" />
          </CompaniesWrapper>
          <PayWrapper>
            <VisaCard src={VisaCardSrc} alt="Visa Card Logo" />
            <MasterCard src={MasterCardSrc} alt="Master Card Logo" />
          </PayWrapper>
        </MidFooter>
        <BotFooter>
          <a href="mailto:hello@web3wed.io">hello@web3wed.io</a>
          <div>COPYRIGHT 2023-2025 W3B0DZ LLC. ALL RIGHTS RESERVED</div>
          <div>
            Shams Business Center, Sharjah Media City Free Zone, Al Messaned,
            Sharjah, UAE.
          </div>
        </BotFooter>
      </ContainerMain>
    </StyledFooter>
  );
};

export default Footer;
