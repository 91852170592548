
import { styled } from '@mui/material';

export const StyledLove = styled('section')`
  margin-top: 200px;
  @media (max-width: 480px) {
    margin-top: 160px;
  }
  h2 {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    @media (max-width: 725px) {
      text-align: center;
      line-height: 54px;
    }
    @media (max-width: 480px) {
      font-size: 26px;
      line-height: 29px;
    }
  }
`;

export const SliderWrapper = styled('div')`
  max-width: 1108px;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  margin: -15px auto 0px auto;
  position: relative;
  z-index: 1;
`;

export const SliderItem = styled('div')`
  position: relative;
  width: 364px;
  height: 537px;
  border: 1px solid rgba(255, 255, 255, 0.3);
  border-radius: 45px;
  padding: 6px;
  overflow: hidden;
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;

  @media (max-width: 1200px) {
    width: 300px;
    height: 437px;
  }

  @media (max-width: 992px) {
    width: 100%;
    height: 737px;
  }

  @media (max-width: 420px) {
    max-height: 470px;
  }

  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);

    .gradient {
      opacity: 0;
    }

    img {
      display: none;
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.9;
    transition: opacity 0.3s, transform 0.3s;
    transform: scale(1);
    border-radius: 40px;

    &:hover {
      opacity: 1;
      transform: scale(1.1);
    }
  }
`;

export const GradientOverlay = styled('div')`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 40px;
  background: linear-gradient(
      0deg,
      rgba(15, 15, 26, 0.2) 0%,
      rgba(15, 15, 26, 0.2) 100%
    ),
    linear-gradient(180deg, rgba(15, 15, 26, 0) 0%, #0f0f1a 100%);
  pointer-events: none;
  transition: opacity 0.3s ease-in-out;
`;

export const SliderText = styled('div')`
  position: absolute;
  bottom: 32px;
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 18px;
  font-weight: 600;
  text-align: center;
  width: 100%;
`;

export const BodyLove = styled('div')`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  @media (max-width: 992px) {
    flex-wrap: wrap;
  }
`;

export const SafeWrapper = styled('div')`
  border-radius: 24px;
  padding: 32px 24px;
  background: #16161f;
  max-width: 800px;
  width: 100%;
  h4 {
    font-size: 28px;
    font-weight: 700;
  }
  p {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 300;
    color: #fff;
    opacity: 0.8;
  }
  @media (max-width: 992px) {
    max-width: 100%;
  }
`;

export const TopBrands = styled('div')`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 10px;
  }
`;

export const DisneyIcon = styled('img')``;
export const STBIcon = styled('img')``;
export const AdobeIcon = styled('img')``;
export const JPMIcon = styled('img')``;
export const VKIcon = styled('img')``;

export const LastBrands = styled('div')`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    gap: 10px;
    margin-top: 10px;
  }
`;

export const MasterIcon = styled('img')``;
export const CocaIcon = styled('img')``;
export const InstIcon = styled('img')``;
export const AdidasIcon = styled('img')``;
export const RedditIcon = styled('img')``;

export const AboutWrapper = styled('div')`
  max-width: 341px;
  width: 100%;
  h4 {
    font-size: 28px;
    font-weight: 700;
  }
  @media (max-width: 992px) {
    max-width: 100%;
  }
`;

export const MediaBrands = styled('div')`
  margin-top: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  gap: 10px;
`;

export const YahooIcon = styled('img')``;
export const BenzIcon = styled('img')``;
export const KTLAIcon = styled('img')``;
export const NBCIcon = styled('img')``;
export const APIcon = styled('img')``;
export const CBSIcon = styled('img')``;
export const ChronicleIcon = styled('img')``;

export const CustomPagination = styled('div')`
  display: flex;
  justify-content: center;
  margin-top: 15px;
  gap: 8px;

  .pagination-dot {
    width: 32px;
    height: 3px;
    border-radius: 26px;
    background-color: rgba(255, 255, 255, 0.5);
    border: none;
    cursor: pointer;
    transition: background 0.3s;
  }

  .pagination-dot.active {
    background: radial-gradient(circle, #2df6de 30%, #3c5dfe 100%);
    width: 60px;
  }
`;
