import { useRef } from "react";
import {
  StyledWhatIs,
  HeadingWhatIs,
  VideoWhatIs,
  TextWrapper,
} from "./styles/StyledWhatIs";
import { CircleItem } from "./styles/StyledInspiration";
import play from "../../../../shared/assets/img/love/love-play.svg";
import posterImg from "../../../../shared/assets/img/posters/pic_b3_1.jpg";
import videoThird from '../../../../shared/assets/video/circle/third_circle.mp4';
import { useTranslation } from 'react-i18next';

const WhatIs = () => {
  const { t, i18n } = useTranslation();
  const videoRef = useRef(null);

  const handleMouseEnter = () => {
    if (videoRef.current) {
      // @ts-ignore
      videoRef.current.play();
    }
  };

  const handleMouseLeave = () => {
    if (videoRef.current) {
      // @ts-ignore
      videoRef.current.pause();
      // @ts-ignore
      videoRef.current.currentTime = 0;
    }
  };

  const handleClick = () => {
    window.location.href = 'https://www.youtube.com/watch?v=PAgB1cEZGcQ';
    return null;
  };

  return (
    <StyledWhatIs>
      <HeadingWhatIs>
        <h2>{t('whatis_title_1')}</h2>
        <p>{t('whatis_desc_1')}</p>
      </HeadingWhatIs>
      <VideoWhatIs>
        <CircleItem
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
          onClick={handleClick}
        >
          <video
            ref={videoRef}
            src={videoThird}
            muted
            loop
            playsInline
            poster={posterImg}
          ></video>
          <img src={play} alt="Play icon" />
        </CircleItem>
        <TextWrapper>
          <h4>{t('whatis_title_2')}</h4>
          <span>{t('whatis_desc_2')}</span>
        </TextWrapper>
      </VideoWhatIs>
    </StyledWhatIs>
  );
};

export default WhatIs;
