import { FC } from 'react';
import { Avatar, Box, styled, Typography } from '@mui/material';
import { COLOR_ACCENT, COLOR_DARK, COLOR_PURPURE, COLOR_VIOLET, COLOR_WHITE } from 'shared/config';
import { FontFamilies, FontWeights } from 'shared/config/theme/Typography';
import GoogleIcon from '@mui/icons-material/Google';
import {BorderWrapper} from "../../../features/certificate";

export enum UserStatus {
  Online = 'Online',
  Offline = 'Offline',
  Owns = 'owns the ring',
  You = 'You',
}

export enum UserRole {
  Female = 'Female',
  Man = 'Man',
  Witness = 'Witness',
}

const roleBgMapping: { [key in keyof typeof UserRole]: string } = {
  [UserRole.Female]: COLOR_PURPURE,
  [UserRole.Man]: COLOR_ACCENT,
  [UserRole.Witness]: COLOR_VIOLET,
};

export type UserProps = {
  avatar?: string;
  firstName: string;
  lastName: string;
  email?: string;
  role: UserRole;
  withFullName?: boolean;
  status?: UserStatus;
  id?: number;
  simple?: boolean;
  simpleWithName?: boolean;
  noBorder?: boolean;
  withRing?: boolean;
  size?: 'small' | 'medium' | 'large' | 'mlarge' | 'x-large';
};

const StyledGrid = styled(Box)<{ noBorder?: boolean; smallPadding?: boolean; simpleWithName?: boolean }>`
  width: 100%;
  display: grid;
  grid-template-columns: auto 1fr;
  grid-template-rows: auto auto;
  column-gap: ${({ theme }) => theme.spacing(4)};
  padding: ${({ theme }) => theme.spacing(4)} ${({ theme }) => theme.spacing(2)};

  ${({ smallPadding, theme }) =>
    smallPadding &&
    `
    ${theme.breakpoints.down('md')} {
      padding:${theme.spacing(1)}
    } 
  `}
  ${({ simpleWithName }) =>
    simpleWithName &&
    `
    grid-template-columns: 1fr;
    place-items: center;
  `}
  align-items: center;

  ${({ noBorder, theme }) =>
    !noBorder &&
    `
      border: 2px solid #5C5A75;
      border-radius: 16px;
      width: 191px;
      ${theme.breakpoints.down('sm')} {
          width: 154px;
        } 
    `}
`;

const StyledAvatar = styled(Avatar)<{ role: UserRole }>`
  background: ${({ role }) => roleBgMapping[role]};

  & > p {
    color: ${({ role }) => (role === UserRole.Man ? COLOR_DARK : COLOR_WHITE)};
  }

  &.small {
    width: 40x;
    height: 40px;
  }
  &.medium {
    width: 50px;
    height: 50px;
    @media (max-width: 768px) {
        width: 50px;
        height: 50px;
    }        
  }
  &.large {
    width: 150px;
    height: 150px;
  }
  &.mlarge {
    width: 90px;
    height: 90px;
    @media (max-width: 768px) {
        width: 90px;
        height: 90px;
    }        
  }
  &.x-large {
    width: 150px;
    height: 150px;
  }
  box-shadow: 0px 3.86px 7.71px 0px rgb(155 77 255 / 29%);
  grid-row-start: 1;
  grid-row-end: 3;
  
`;

const StyledId = styled(Typography)`
  grid-row: 1 / 3;
  grid-column: 3;
  margin-right: ${({ theme }) => theme.spacing(3.5)};
  font-family: ${FontFamilies.secondary};
`;

const StyledAvatarTypography = styled(Typography)`
  font-weight: ${FontWeights.Regular};
  &.small {
    font-size: 16px;
    line-height: 24px;
  }
  &.medium {
    font-size: 17px;
    line-height: 25px;
  }
  &.large {
    font-size: 25px;
    line-height: 37px;
  }
  &.mlarge {
    font-size: 25px;
    line-height: 37px;
  }
  &.x-large {
    font-size: 25px;
    line-height: 37px;
  }
`;

const StyledName = styled(Typography)<{ centered?: boolean; smallFont?: boolean }>`
  font-weight: 400;
  line-height: 1.25rem;
  font-size: 1rem;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre-line;

  ${({ smallFont, theme }) =>
    smallFont &&
    `
      font-weight: 700;
      ${theme.breakpoints.down('md')} {
        font-size: 0.75rem;
        line-height: 1rem;
    } 
`}

  ${({ centered }) => centered && 'grid-row: 1 / 3'}
`;


const StyledNameWithPicture = styled(Typography)<{ centered?: boolean; smallFont?: boolean }>`
  font-weight: 300;
  line-height: 1.25rem;
  font-size: 1rem;
  overflow: visible;
  text-overflow: ellipsis;
        font-size: 0.7rem;
  padding: 0;
  
    @media (max-width: 768px) {
        padding: 0;
    }  
  ${({ smallFont, theme }) =>
    smallFont &&
    `
  ${theme.breakpoints.down('md')} {
    font-size: 0.75rem;
    line-height: 1rem;
} 
`}

  ${({ centered }) => centered && 'grid-row: 1 / 3'}
`;
const StyledSocialWithPicture = styled(Typography)<{ centered?: boolean; smallFont?: boolean }>`
  font-weight: 300;
  line-height: 1.25rem;
  font-size: 0.75rem;
  overflow: visible;
  color: #979797;
  text-overflow: ellipsis;
        padding: 0 0 ${({ theme }) => theme.spacing(2)} 0;
        font-size: 0.5rem;
  
    @media (max-width: 768px) {
        padding: 0 0 ${({ theme }) => theme.spacing(2)} 0;
        font-size: 0.5rem;
    }  
  ${({ smallFont, theme }) =>
    smallFont &&
    `
  ${theme.breakpoints.down('md')} {
    font-size: 0.75rem;
    line-height: 1rem;
} 
`}

  ${({ centered }) => centered && 'grid-row: 1 / 3'}
`;

const StyledStatus = styled(Typography)`
  color: ${COLOR_ACCENT};
  font-size: 0.75rem;
  line-height: 0.75rem;
`;
const StyledIcon = styled(Typography)`
  width:35px;
  height:35px;
      position: relative;
    top: -15px;
    @media (max-width: 768px) {
          width:35px;
          height:35px;
            top: -15px;
    }  
  display:flex;
  align-items: center;
  justify-content: center;
  border-radius:100px;
  background: #6D7075;
`;

export const User: FC<UserProps> = ({
  avatar,
  firstName,
  lastName,
  email,
  withFullName = false,
  role,
  status,
  id,
  simple,
  simpleWithName,
  noBorder,
  withRing,
  size = 'small',
}) => {


  if (simple) {
    return (
        <StyledAvatar className={size} src={avatar} role={role}>
          <StyledAvatarTypography className={size}>{`${firstName?.charAt(0)}${lastName?.charAt(
              0,
          )}`}</StyledAvatarTypography>
        </StyledAvatar>
    );
  }
  if (simpleWithName) {
    return (
        <StyledGrid noBorder={noBorder} smallPadding={withRing} simpleWithName={simpleWithName}>
          <StyledAvatar className={size} src={avatar} role={role}>
            <StyledAvatarTypography className={size}>{`${firstName?.charAt(0)}${lastName?.charAt(
                0,
            )}`}</StyledAvatarTypography>
          </StyledAvatar>
            <StyledIcon>
                <GoogleIcon sx={{ color: '#1B1A3E', fontSize: {
                        xs: 'medium',
                        md: 'large',
                    }
                }}/>
            </StyledIcon>
          <StyledNameWithPicture>
            {withFullName ? `${firstName}${withRing ? '\n' : ' '}${lastName}` : firstName}
          </StyledNameWithPicture>
          <StyledSocialWithPicture>
            {email}
          </StyledSocialWithPicture>
        </StyledGrid>
    );
  }

  return (
    <StyledGrid noBorder={noBorder} smallPadding={withRing}>
      <StyledAvatar className={size} src={avatar} role={role}>
        <StyledAvatarTypography className={size}>{`${firstName?.charAt(0)}${lastName?.charAt(
          0,
        )}`}</StyledAvatarTypography>
      </StyledAvatar>
      <StyledName centered={!status} smallFont={withRing}>
        {withFullName ? `${firstName}${withRing ? '\n' : ' '}${lastName}` : firstName}
      </StyledName>
      {status && <StyledStatus>{status}</StyledStatus>}
      {id && <StyledId>#{id}</StyledId>}
    </StyledGrid>
  );
};
