import { FC, useState } from 'react';
import { Box, CircularProgress, styled, Typography } from '@mui/material';
import {
  COLOR_ACCENT,
  COLOR_BG,
  COLOR_BG_MOBILE,
  COLOR_LIGHT_GRAY_ALPHA_2,
  COLOR_WHITE,
} from 'shared/config/theme/colors/colors.constants';
import { CheckCircle } from 'shared/ui/components';

export type RingProps = {
  img: string;
  background?: string;
  category?: string;
  simple?: boolean;
  size?: 'small' | 'medium' | 'large' | 'extra-large';
  price?: number;
  withChecks?: boolean;
  activeBorder?: boolean;
  activeFirstCheck?: boolean;
  activeSecondCheck?: boolean;
  noBorder?: boolean;
};

const RelativeWrapper = styled(Box)`
  position: relative;
`;

const StyledGrid = styled(Box)<{ noBorder?: boolean; noBackground: boolean; activeBorder: boolean }>`
  z-index: -1;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: fit-content;
  background-color: ${({ noBackground }) => (noBackground ? 'transparent' : COLOR_BG)};

  ${({ noBackground, theme }) =>
    !noBackground &&
    `
    ${theme.breakpoints.down('sm')} {
      background-color: ${COLOR_BG_MOBILE};

    } 
  `}

  * {
    box-sizing: border-box;
  }
  border-radius: 16px;

  ${({ noBorder, activeBorder }) =>
    !noBorder && `border: ${activeBorder ? `2px solid ${COLOR_ACCENT}` : '2px solid #5C5A75'}`}
`;

const StyledInner = styled(Box)<{
  backgroundImage?: string;
  withBorder?: boolean;
}>`
  ${({ backgroundImage }) => backgroundImage && `background-image: url(${backgroundImage});`}
  background-repeat: no-repeat;
  background-size: 100%;
  background-position: center;

  display: flex;
  justify-content: center;
  width: fit-content;

  &.small {
    width: 191px;
    height: 191px;
    ${({ theme }) => `${theme.breakpoints.down('sm')} {
      width: 150px;
      height: 150px; 
    }`}
  }

  &.medium {
    width: 264px;
    height: 264px;
    ${({ theme }) => `${theme.breakpoints.down('md')} {
      width: 150px;
      height: 150px; 
    }`}
  }

  &.large {
    width: 340px;
    height: 340px;
    ${({ theme }) => `${theme.breakpoints.down('md')} {
      width: 278px;
      height: 278px; 
    }`}
  }

  &.extra-large {
    width: 100%;
    height: 365px;
    img {
      object-fit: contain;
    }
    ${({ theme }) => `${theme.breakpoints.down('sm')} {
      width: 278px;
      height: 278px; 
    }`}
  }

  border-radius: 16px;

  ${({ withBorder }) =>
    withBorder &&
    `
        border-bottom: 2px solid #5C5A75;
        border-bottom-left-radius: 0px;
        border-bottom-right-radius: 0px;    
        margin-bottom: 2px;
    `}

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
`;

const StyledInfo = styled(Box)`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: ${({ theme }) => theme.spacing(2.5)};
`;

const StyledCategory = styled(Typography)`
  font-size: 1.125rem;
  line-height: 30px;
  text-transform: capitalize;
`;

const StyledPrice = styled(Typography)`
  font-size: 1.25rem;
  color: ${COLOR_ACCENT};
`;

const CheckWrapper = styled(Box)<{ active?: boolean }>`
  zindex: 3;
  position: absolute;
  right: 20px;
  &.first {
    top: 20px;
  }
  &.second {
    top: 60px;
  }
  * {
    stroke: ${({ active }) => (active ? COLOR_ACCENT : COLOR_LIGHT_GRAY_ALPHA_2)};
  }
  ${({ theme }) => `${theme.breakpoints.down('md')} {
    right: 10px;
  }`}
`;

export const Ring: FC<RingProps> = ({
  img,
  background,
  category,
  price,
  simple = false,
  size = 'large',
  withChecks,
  activeBorder = false,
  activeFirstCheck,
  activeSecondCheck,
  noBorder,
}) => {
  const [isImgLoaded, setImgLoaded] = useState(false);
  return (
    <RelativeWrapper>
      <StyledGrid
        noBorder={noBorder}
        activeBorder={!simple && activeBorder}
        noBackground={simple}
        className="border-wrapper"
      >
        <StyledInner backgroundImage={background} withBorder={!simple} className={size}>
          {img ? (
            <img
              src={img.replace("ipfs.io", "web3wed.by")}
              alt="ring"
              style={{ opacity: 0 }}
              onLoad={(e) => {
                (e.target as HTMLImageElement).style.opacity = '1';
                setImgLoaded(true);
              }}
            />
          ) : (
            !isImgLoaded && (
              <CircularProgress size={50} sx={{ color: COLOR_WHITE, marginTop: 'auto', marginBottom: 'auto' }} />
            )
          )}
        </StyledInner>
        {!simple && (
          <StyledInfo>
            <StyledCategory>{category}</StyledCategory>
            <StyledPrice>${price}</StyledPrice>
          </StyledInfo>
        )}
      </StyledGrid>
      {withChecks && (
        <>
          <CheckWrapper className="first" active={activeFirstCheck}>
            <CheckCircle />
          </CheckWrapper>
          <CheckWrapper className="second" active={activeSecondCheck}>
            <CheckCircle />
          </CheckWrapper>
        </>
      )}
    </RelativeWrapper>
  );
};
