
import { styled } from '@mui/material';

export const StyledHeader = styled('header')`
  padding-top: 20px;
    z-index: 5;
    position: relative;
`;

export const Nav = styled('nav')`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  div {
    display: flex;
    align-items: center;
  }
`;

export const Logo = styled('img')`
  @media (max-width: 768px) {
    width: 116px;
    height: 40px;
  }
`;

export const ButtonM = styled('button')`
  background: radial-gradient(
    95.29% 75.23% at 15.56% 11.62%,
    #4bfffc 0%,
    #1a9082 100%
  );
  padding: 12px 20px;
  font-weight: 600;
  font-size: 16px;
  color: #fff;
  height: 43px!important;
  border-radius: 63px;
  font-family: "Montserrat", sans-serif;
  margin-left: 20px;
  cursor: pointer;
  transition: all 0.3s ease;
  &:hover {
    color: #000;
    background: #fff;
  }
  @media (max-width: 768px) {
    padding: 8px 16px;
    margin-left: 0px;
  }
`;

export const LangWrapper = styled('div')`
  display: flex;

  button {
    padding: 10px 20px;
    font-weight: 400;
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.59);
    border: 1px #fff solid;
    background: transparent;
    font-size: 16px;
    color: #fff;
    border-radius: 63px;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    transition: all 0.3s ease;
    &:hover {
      color: #000;
      background: #fff;
    }
    @media (max-width: 768px) {
      padding: 6px 16px;
      margin-left: 0px;
    }
  }
  @media (max-width: 768px) {
    display: none !important;
  }
`;

export const MobileLang = styled('div')`
  display: none !important;
  position: relative;

  @media (max-width: 768px) {
    display: block !important;
  }
`;

export const SelectedLang = styled('button')`
  padding: 6px 16px;
  margin-left: 0px;
  font-weight: 400;
  box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.59);
  border: 1px #fff solid;
  background: transparent;
  font-size: 16px;
  color: #fff;
  border-radius: 63px;
  font-family: "Montserrat", sans-serif;
  cursor: pointer;
  transition: all 0.3s ease;
  margin-right: 10px;
`;

export const Dropdown = styled('div')`
  position: absolute;
  top: 100%;
  left: 0;
  display: flex;
  flex-direction: column;
  width: 60px;
  z-index: 10;
  button {
    padding: 6px 16px;
    margin-left: 0px;
    font-weight: 400;
    box-shadow: 0px 0px 4px rgba(255, 255, 255, 0.59);
    border: 1px #fff solid;
    background: transparent;
    font-size: 16px;
    color: #fff;
    border-radius: 63px;
    font-family: "Montserrat", sans-serif;
    cursor: pointer;
    transition: all 0.3s ease;
  }
`;
