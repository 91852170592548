export const RedirectToUrl = () => {
    window.location.href = 'https://www.youtube.com/watch?v=PAgB1cEZGcQ';
    return null;
};
export const RedirectToUrlAnother = () => {
    window.location.href = 'https://www.youtube.com/@web3wedding';
    return null;
};
export const RedirectToTelegramBot = () => {
    window.location.href = 'https://t.me/Web3WedBot';
    return null;
};
