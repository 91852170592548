import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {useLocation, useNavigate} from 'react-router-dom';
import { alpha, Box, Button, CircularProgress, IconButton, Stack, styled, Typography } from '@mui/material';
// eslint-disable-next-line import/no-extraneous-dependencies
import { WALLET_ADAPTERS } from '@web3auth/base';
import { LOGIN_PROVIDER_TYPE } from '@web3auth/openlogin-adapter';
import { routes } from 'app/providers/router/routes';
import { useWeb3Auth } from 'app/providers/Web3AuthProvider';
import { userSelector } from 'entities/user';
import { Close } from 'pages/home/ui/Icons';
import { TwitchLogo, yandexLogo } from 'shared/assets';
import { COLOR_LIGHT_GRAY_INPUT } from 'shared/config';
import { COLOR_ACCENT, COLOR_BG, COLOR_DARK_AUTH, COLOR_WHITE } from 'shared/config/theme/colors';
import { FontFamilies, FontWeights } from 'shared/config/theme/Typography';
import { useShallowSelector } from 'shared/hooks';
import { Discord, Facebook, Google, Twitter } from 'shared/ui/components/Icon';

import { modalActions, ModalType } from '../model';

export interface IConnectWalletPayload {
  type: ModalType.ConnectWallet;
  data?: null;
}

const SocialLoginButton = styled(Button)({
  background: COLOR_DARK_AUTH,
  borderRadius: '8px',
  height: 46,
  fontFamily: FontFamilies.authModal,
  fontWeight: FontWeights.Bold,
  fontSize: 16,
  lineHeight: '24px',
  color: COLOR_WHITE,
  width: '100%',
  border: 'none',
  '&:hover': {
    background: alpha(COLOR_ACCENT, 0.5),
  },
});

const SocialLoginIconButton = styled(IconButton)(({ theme }) => ({
  borderColor: theme.themeColors.borderMain,
  '&:hover': {
    borderColor: theme.themeColors.borderAccent,
    background: 'transparent',
  },

  '.MuiSvgIcon-root': {
    width: 'unset',
    height: 'unset',
  },
}));

export const ConnectWallet = () => {
  const { login, isLoading } = useWeb3Auth();
  const user = useShallowSelector(userSelector.getUser);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { pathname } = useLocation();
  const isChakras = pathname === '/chakras';

  const handleLogin = async (provider: LOGIN_PROVIDER_TYPE) => {
    await login(WALLET_ADAPTERS.OPENLOGIN, provider);
  };

  useEffect(() => {
    if (user?.id) {
      dispatch(modalActions.closeModal());
      console.log(pathname);
      if(isChakras) {
        navigate(routes.checkPromo.path);
      } else {
        navigate(routes.connection.path);
      }
    }
  }, [isChakras, pathname, dispatch, navigate, user.firstName, user?.id, user.lastName]);

  return (
    <Box
      sx={{
        background: 'linear-gradient(280.89deg, #9B4DFF -3.98%, #2DF6DE 91.81%)',
        width: '100%',
        borderRadius: '16px',
        maxWidth: '510px',
      }}
    >
      <Stack
        spacing={3}
        sx={{
          backgroundColor: 'rgb(25, 24, 54)',
          borderRadius: '16px',
          margin: '3px',
          padding: '24px',
          paddingBottom: 5.5,
        }}
      >
        <Stack width="100%" direction="row" justifyContent="space-between">
          <Typography
            fontFamily={FontFamilies.secondary}
            fontWeight={FontWeights.Medium}
            fontSize={30}
            lineHeight="30px"
          >
            Sign up
          </Typography>
          <Close
            sx={{ color: COLOR_LIGHT_GRAY_INPUT, cursor: 'pointer' }}
            onClick={() => dispatch(modalActions.closeModal())}
          />
        </Stack>

        {isLoading && (
          <Box
            position="absolute"
            zIndex={9999}
            display="flex"
            alignItems="center"
            justifyContent="center"
            width="100%"
            height="100%"
            left={0}
            top={0}
            bgcolor={alpha(COLOR_BG, 0.5)}
          >
            <CircularProgress size={50} sx={{ color: COLOR_WHITE }} />
          </Box>
        )}
        <Stack width="100%" spacing={2}>
          <SocialLoginButton onClick={() => handleLogin('google')}>
            <Google sx={{ mr: 1 }} /> Continue with Google
          </SocialLoginButton>
          <Stack direction="row" gap={2} justifyContent="center">
            <SocialLoginIconButton onClick={() => login(WALLET_ADAPTERS.OPENLOGIN, 'facebook')}>
              <Facebook />
            </SocialLoginIconButton>
            <SocialLoginIconButton onClick={() => login(WALLET_ADAPTERS.OPENLOGIN, 'twitter')}>
              <Twitter />
            </SocialLoginIconButton>
            <SocialLoginIconButton onClick={() => login(WALLET_ADAPTERS.OPENLOGIN, 'discord')}>
              <Discord />
            </SocialLoginIconButton>
            <SocialLoginIconButton onClick={() => login(WALLET_ADAPTERS.OPENLOGIN, 'twitch')}>
              <img src={TwitchLogo} width={28} height={28} alt="" />
            </SocialLoginIconButton>
            <SocialLoginIconButton onClick={() => login(WALLET_ADAPTERS.OPENLOGIN, 'jwt')}>
              <img src={yandexLogo} width={28} height={28} alt="" />
            </SocialLoginIconButton>
          </Stack>
        </Stack>
      </Stack>
    </Box>
  );
};
