import { useDispatch } from 'react-redux';
import {Box, Button, CircularProgress, Grid, TextField, useMediaQuery, useTheme} from '@mui/material';
import { Ring } from 'entities/ring';
import {User, userActions, UserRole, userSelector, UserStatus} from 'entities/user';
import { weddingActions, weddingSelector } from 'entities/wedding';
import { VDayLogo } from 'pages/home/ui/Icons/VDayLogo';
import { COLOR_WHITE, uiSelector } from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import {EngagementType, SexType} from 'shared/types';
import { Typography } from 'shared/ui/components';
import {checkRequestStatusEquality, pinata} from 'shared/utils';
import { useTranslation } from 'react-i18next';
import {FianceCodeContainer} from "../../features/connection/containers";
import {MatchContainer} from "../../features/match";
import {Stack} from "@mui/system";
import {Controller} from "react-hook-form";
import {useEffect, useState} from 'react'
import {AgreementCheckbox, GenderSelectBlock, WeddingTypeBlock} from "../../features/connection/ui";
import {modalActions, ModalType} from "../../entities/modal";

export const VowsSuccess = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isDownMdLayout = useMediaQuery(theme.breakpoints.down('md'));

    const uiState = useShallowSelector(uiSelector.getUI);
    const { members } = useShallowSelector(weddingSelector.getWedding);
    const { id: userId, sex: userSex } = useShallowSelector(userSelector.getUser);
    const isUserMan = userSex === SexType.man;
    const isStart = true;

    const currentUser = members.find(({ user: { id } }) => id === userId);
    const partner = members.find(({ user: { id } }) => id !== userId);

    const currentUserDisplayData = {
        status: UserStatus.You,
        firstName: currentUser?.user.firstName || '',
        lastName: currentUser?.user.lastName || '',
        avatar: currentUser?.user.profileImage,
        ringImg: currentUser?.token?.meta?.imageUrl || '',
    };

    const partnerDisplayData = {
        status: partner?.user.isOnline ? UserStatus.Online : UserStatus.Offline,
        firstName: partner?.user.firstName || '',
        lastName: partner?.user.lastName || '',
        avatar: partner?.user.profileImage,
        ringImg: partner?.token?.meta?.imageUrl || '',
    };

    const firstUserDisplayData = !isUserMan ? currentUserDisplayData : partnerDisplayData;
    const secondUserDisplayData = isUserMan ? currentUserDisplayData : partnerDisplayData;

    const isConfirmPreviewRingsLoading = checkRequestStatusEquality(uiState[weddingActions.confirmPreviewRings?.type]);

    const [selectedFile, setSelectedFile] = useState('');
    const [selectedText, setSelectedText] = useState('');
    const [sendVows, setSendVows] = useState(false);

    const [showNextPage, setShowNextPage] = useState(true);


    const handleFileChange = async (e:any) => {
        if (e.target.files && e.target.files[0]) {
            try {
                const upload = await pinata.upload.file(e.target.files[0]);
                const ipfsUrl = await pinata.gateways.convert(upload.IpfsHash);
                setSelectedFile(ipfsUrl);
            } catch (error) {
                console.log(error);
            }
        }

    };

    const isUserWaiting = ((partner?.vows === null || partner?.vows === undefined) && sendVows);
    useEffect(() => {
        if (isUserWaiting) {
            dispatch(
                modalActions.openModal({
                    type: ModalType.Loading,
                    data: { title: t('ring_RingPrice_waiting') },
                }),
            );
        }
        return () => {
            dispatch(modalActions.closeModal());
        };
    }, [dispatch, isUserWaiting]);

    const handleNext = () => {
        setSendVows(true);
        dispatch(weddingActions.selectVows({videoUrl: selectedFile, text: selectedText}));
    };

    const handleChangeText = (event:any) => {
        setSelectedText(event.target.value);
    };
    const handleNextPage = () => {
        setShowNextPage(!showNextPage);
    };
    return showNextPage ? (
        <Grid container spacing={{ xs: 2, md: 7 }} justifyContent="center">
            <Grid item container xs={11} paddingTop="50px!important" alignItems="center" justifyContent="center">
                <Typography variant="h2" fontSize="22px" className="center">{firstUserDisplayData.firstName} {firstUserDisplayData.lastName} & {secondUserDisplayData.firstName} {secondUserDisplayData.lastName}</Typography>
            </Grid>
            <Grid item container xs={11} paddingTop="10px!important" justifyContent="center" flexDirection="column" alignItems="center">
                <Typography variant="h3" fontSize="32px" className="center">Blockchain Marriage</Typography>
                <Typography variant="h3" fontSize="32px" className="center">Ceremony</Typography>
            </Grid>
            <Grid
                item
                container
                xs={12}
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item >
                    <Ring img={isDownMdLayout ? partnerDisplayData.ringImg : firstUserDisplayData.ringImg} size='small' simple noBorder />
                </Grid>
            </Grid>
            <Grid item container xs={11} md={7} justifyContent='center'>
                <Typography variant="h4" fontSize="18px" textAlign="center" >
                    This one-of-a-kind NFT ring is part of an exclusive collection designed for blockchain marriages.
                    It’s randomly generated, making it completely unique and perfectly meant for your partner.
                    Soon, you’ll share this special moment, presenting the ring as a symbol of your love and commitment,
                    crafted just for them. ✨💍
                </Typography>
            </Grid>
            <Grid item container xs={12} justifyContent='center'>
                <Button variant="contained" onClick={handleNextPage} sx={{ minWidth: 189 }}>
                    {isConfirmPreviewRingsLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : 'Next'}
                </Button>
            </Grid>
        </Grid>
    ) : (
        <Grid container spacing={{ xs: 2, md: 7 }} justifyContent="center">
            <Grid item container xs={12} paddingTop="50px!important" alignItems="center" justifyContent="center">
                <Typography variant="h2" fontSize="22px" className="center">{firstUserDisplayData.firstName} {firstUserDisplayData.lastName} & {secondUserDisplayData.firstName} {secondUserDisplayData.lastName}</Typography>
            </Grid>
            <Grid item container xs={12} paddingTop="10px!important" justifyContent="center" flexDirection="column" alignItems="center">
                <Typography variant="h3" fontSize="32px" className="center">Blockchain Marriage</Typography>
                <Typography variant="h3" fontSize="32px" className="center">Ceremony</Typography>
            </Grid>
            <Grid
                item
                container
                xs={12}
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
                justifyContent="center"
                alignItems="center"
            >
                <Grid item >
                    <Ring img={isDownMdLayout ? partnerDisplayData.ringImg : firstUserDisplayData.ringImg} size='small' simple noBorder />
                </Grid>
            </Grid>
            <Grid item container xs={11} md={7} justifyContent='center' sx={{maxWidth: '600px'}}>
                <Grid item container xs={8} md={5}>
                    <TextField
                        label={'Upload a video of the vow'}
                        placeholder={'Upload a video of the vow'}
                        sx={{ color: '#fff'}}
                        className={'visibleLabel'}
                        value={selectedFile}
                        disabled
                    />
                </Grid>
                <Grid item container xs={4} md={2} justifyContent="center" flexDirection="column" alignItems="center">
                    <Button
                        variant="contained"
                        component="label"
                        htmlFor="contained-button-file"
                        onClick={handleFileChange}
                    >
                        Upload
                        <TextField
                            sx={{ display: "none" }}
                            id="contained-button-file"
                            type="file"
                            onChange={handleFileChange}
                            hidden={true}
                        />
                    </Button>
                </Grid>


            </Grid>
            <Grid item container xs={11} md={7} justifyContent='center' pt={'30px!important'}>
                or / and
            </Grid>
            <Grid item container xs={11} md={7} justifyContent='center' pt={'30px!important'}>
                <Grid item container xs={12} md={7}>
                    <TextField
                        label={'Write a vow'}
                        placeholder={'Write a vow'}
                        sx={{ color: '#fff'}}
                        value={selectedText}
                        onChange={handleChangeText} // Обработчик изменений
                        multiline
                        maxRows={4}
                    />
                </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent='center'>
                <Button variant="contained" onClick={handleNext} sx={{ minWidth: 189, textTransform: 'none' }}>
                    {isConfirmPreviewRingsLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : 'Go to ceremony'}
                </Button>
            </Grid>
        </Grid>
    );
};
