import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import {Button, Container, CssBaseline, Stack, styled} from '@mui/material';
import { userActions, userSelector } from 'entities/user';
import { socket } from 'shared/api';
import { useShallowSelector } from 'shared/hooks';
import { BlurEllipseBlue, BlurEllipsePurple } from 'shared/ui/components';

import { AboutUs } from './ui/AboutUs';
//import { AboutYou } from './ui/AboutYou';
//import { Footer } from './ui/Footer';
//import { MarriageAmbassadors } from './ui/MarriageAmbasadors';
import { Preview } from './ui/Preview';
import { PreviewRings } from './ui/PreviewRings';
//import { Roadmap } from './ui/Roadmap';
import { SeenOn } from './ui/SeenOn';
//import { Team } from './ui/Team';
import { useTranslation } from 'react-i18next';
import React, { useState } from 'react';

//import '@fontsource/dm-sans/700.css';
//import '@fontsource/dm-sans/400.css';
//import '@fontsource/work-sans/800.css';
//import '@fontsource/work-sans/700.css';
//import '@fontsource/work-sans/600.css';
//import '@fontsource/work-sans/400.css';
//import '@fontsource/jetbrains-mono/800.css';
//import '@fontsource/jetbrains-mono/400.css';
//import '@fontsource/inter/600.css';
//import '@fontsource/inter/500.css';
//import '@fontsource/inter/400.css';
import Header from "./ui/MainPage/Header";
import { ContainerMain } from "./ui/MainPage/styles/ContainerMain";
import Hero from "./ui/MainPage/Hero";
import Cards from "./ui/MainPage/Cards";
import WhatIs from "./ui/MainPage/WhatIs";
import Why from "./ui/MainPage/Why";
import HowItWorks from "./ui/MainPage/HowItWorks";
import Love from "./ui/MainPage/Love";
import Vow from "./ui/MainPage/Vow";
import Inspiration from "./ui/MainPage/Inspiration";
import Footer from "./ui/MainPage/Footer";
import WhyRest from "./ui/MainPage/WhyRest";
import HeroRest from "./ui/MainPage/HeroRest";

export const Partnership = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const { isWitness } = useShallowSelector(userSelector.getUser);


    const [password, setPassword] = useState('');
    const [isUnlocked, setIsUnlocked] = useState(false);
    const [errorMessage, setErrorMessage] = useState('');
    const correctPassword = 'allyouneedislove';

    const handleSubmit = (event:any) => {
        event.preventDefault();

        if (password === correctPassword) {
            setIsUnlocked(true);
            setErrorMessage(''); // Clear any previous error message
        } else {
            setIsUnlocked(false);
            setErrorMessage('Incorrect password.');
        }
    };

    const handleChange = (event:any) => {
        setPassword(event.target.value);
    };

    const ButtonM = styled(Button)`
      background: radial-gradient(
        95.29% 75.23% at 15.56% 11.62%,
        #4bfffc 0%,
        #1a9082 100%
      );
      padding: 12px 20px;
      font-weight: 600;
      font-size: 16px;
      color: #fff;
      border-radius: 63px;
      font-family: "Montserrat", sans-serif;
      margin-left: 20px;
      cursor: pointer;
      height: 43px;
      transition: all 0.3s ease;
      &:hover {
        color: #000;
        background: #fff;
      }
      @media (max-width: 768px) {
        padding: 8px 16px;
        margin-left: 0px;
      }
    `;


    if (isUnlocked) {
        return <>
             <HeroRest />
                <ContainerMain>
                    <WhyRest />
                </ContainerMain>
                <Footer />
            </>;
    }


    return (
        <Stack sx={{
            alignItems: 'center',
            display: 'flex',
            justifyContent: 'center'
        }}>
            <Stack sx={{
                width: '800px',
                alignItems: 'center',
                display: 'flex',
                justifyContent: 'center'
            }}>
                <form onSubmit={handleSubmit} style={{display:'flex',flexDirection: 'column',width: '200px',gap: '15px'}}>
                    <label htmlFor="password">Enter Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={handleChange}
                        style={{padding: '10px 12px'}}
                    />
                    <ButtonM type="submit">Unlock</ButtonM>
                </form>
                {errorMessage && <p style={{ color: 'red' }}>{errorMessage}</p>}
            </Stack>
        </Stack>
    );

};
