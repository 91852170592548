import { FC, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { yupResolver } from '@hookform/resolvers/yup';
import { Button, CircularProgress, TextField } from '@mui/material';
import { Stack } from '@mui/system';
import { useWeb3Auth } from 'app/providers/Web3AuthProvider';
import { userActions, userSelector } from 'entities/user';
import {BORDER_RADIUS_XXL, COLOR_WHITE, uiSelector} from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import { EngagementType, EngagementTypePath, RequestStatus, UpdateUserRequest } from 'shared/types';
import { checkRequestStatusEquality, pinata} from 'shared/utils';
import { useTranslation } from 'react-i18next';


import { connectionDefaultValues, ConnectionFormState, connectionValidationSchema } from '../model';
import { AgreementCheckbox, GenderSelectBlock, WeddingTypeBlock } from '../ui';

interface IConnectionContainerProps {
  partnerPromoCode?: string;
}

export const ConnectionContainer: FC<IConnectionContainerProps> = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const uiState = useShallowSelector(uiSelector.getUI);
  const [weddingType, setWeddingType] = useState<EngagementType | null>(EngagementType.Person);

  const { isLoading: isWeb3AuthLoading } = useWeb3Auth();
  const { weddingDate, firstName, lastName, sex, email, profileImage } = useShallowSelector(userSelector.getUser);
  // @ts-ignore
  const {
    handleSubmit,
    control,
    reset,
    getValues,
    formState: { errors, isValid, isSubmitting },
  } = useForm<ConnectionFormState>({
    // @ts-ignore
    resolver: yupResolver(connectionValidationSchema),
    defaultValues: connectionDefaultValues,
    mode: 'all',
    reValidateMode: 'onBlur',
  });

  const isUpdateUserInfoSuccess = checkRequestStatusEquality(
    uiState[userActions.updateUserInfo.type],
    RequestStatus.SUCCESS,
  );

  const onSave: SubmitHandler<UpdateUserRequest> = (data) => {
    dispatch(
      userActions.updateUserInfo({
        email: data.email,
        firstName: data.firstName,
        lastName: data.lastName,
        weddingDate: data.weddingDate,
        profileImage: data.profileImage,
        sex: data.sex,
      }),
    );
  };

  useEffect(() => {
    reset({ weddingDate, profileImage, email, sex, firstName, lastName, agree: false });
  }, [ weddingDate, profileImage, email, sex, firstName, lastName, reset]);

  const handleNavigate = () => {
    if (weddingType) {
      navigate(EngagementTypePath[weddingType]);
    }
  };


  const handleFileChange = async (e:any) => {
      if (e.target.files && e.target.files[0]) {

          try {
              const upload = await pinata.upload.file(e.target.files[0]);
              const ipfsUrl = await pinata.gateways.convert(upload.IpfsHash);
              let data = getValues();
              data.profileImage = ipfsUrl;
              dispatch(userActions.uploadImageProfile({
                  firstName: data.firstName,
                  lastName: data.lastName,
                  weddingDate: data.weddingDate,
                  profileImage: data.profileImage
              }));
          } catch (error) {
              console.log(error);
          }
      }

  };

  useEffect(() => {
    if (isUpdateUserInfoSuccess && weddingType) {
      navigate(EngagementTypePath[weddingType]);
      dispatch({ type: `${userActions.updateUserInfo?.type}_RESET` });
    }
    // Reason: navigate only on updateUserInfoSuccess, not on weddingType change
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dispatch, isUpdateUserInfoSuccess, navigate]);

  return (
    <>
      <Stack
        component="form"
        onSubmit={handleSubmit(onSave)}
        sx={{ alignItems: 'center', width: '100%', maxWidth: '550px', mx: 'auto', mt: { xs: 5, md: 9 }, pl: { xs: 2, md: 'auto'} , pr: { xs: 2, md: 'auto'} }}
      >
        <Stack gap={3} width="100%">
          <Stack spacing={4} width="100%">
              <Stack
                  mt={3}
                  alignItems="center"
                  sx={{
                      width: '100%',
                      flexDirection: 'row'
                  }}
              >
                  <Controller
                      name="profileImage"
                      control={control}
                      render={({ field }) => (
                          <TextField
                              {...field}
                              placeholder={"Your foto for certificate, 400*400"}
                              sx={{ marginRight: '20px', maxWidth: '420px' }}
                              disabled={true}
                          />
                      )}
                  />
                  <Button
                      variant="contained"
                      component="label"
                      htmlFor="contained-button-file"
                      sx={{ padding: '10px 30px', maxWidth: '120px' }}
                  >
                      Upload
                      <Controller
                          name="picture"
                          control={control}
                          render={({ field }) => (
                              <TextField
                                  {...field}
                                  sx={{ display: "none" }}
                                  id="contained-button-file"
                                  error={!!errors.profileImage}
                                  type="file"
                                  onChange={handleFileChange}
                                  hidden={true}
                              />
                          )}
                      />
                  </Button>
              </Stack>
            <Controller
              name="weddingDate"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={'Wedding date'}
                  placeholder={'Wedding date'}
                  disabled={!!weddingDate}
                  error={!!errors.weddingDate}
                  type="date"
                  InputLabelProps={{
                    shrink: true
                  }}
                  helperText={errors.weddingDate ? errors.weddingDate.message : ''}
                />
              )}
            />
            <Controller
              name="firstName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('Name')}
                  placeholder={t('Name')}
                  disabled={!!firstName}
                  error={!!errors.firstName}
                  helperText={errors.firstName ? errors.firstName.message : ''}
                />
              )}
            />
            <Controller
              name="lastName"
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  label={t('FamilyName')}
                  placeholder={t('FamilyName')}
                  disabled={!!lastName}
                  error={!!errors.lastName}
                  helperText={errors.lastName ? errors.lastName.message : ''}
                />
              )}
            />


            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                      <TextField
                          {...field}
                          label={t('Email')}
                          placeholder={t('Email')}
                          disabled={!!email}
                          error={!!errors.email}
                          helperText={errors.email ? errors.email.message : ''}
                      />

              )}
          />
          </Stack>
          <GenderSelectBlock control={control} disabled={!!sex} />
          {((!firstName && !lastName) || !sex) && !isWeb3AuthLoading && <AgreementCheckbox control={control} />}
          <WeddingTypeBlock selectedWeddingType={weddingType} setSelectedWeddingType={(wt) => setWeddingType(wt)} />
        </Stack>

        {(!firstName && !lastName) || !sex ? (
          <Button
            type="submit"
            variant="outlined"
            disabled={isSubmitting || !isValid || isWeb3AuthLoading || !weddingType}
            sx={{ mt: 5, width: 'auto' }}
          >
            {isSubmitting || isWeb3AuthLoading ? (
              <CircularProgress size={30} sx={{ color: COLOR_WHITE }} />
            ) : (
                t('GetMarried')
            )}
          </Button>
        ) : (
          <Button variant="outlined" disabled={!weddingType} onClick={handleNavigate} sx={{ mt: 5, width: 'auto' }}>
            {t('GetMarried')}
          </Button>
        )}
      </Stack>

      {/* {firstName && lastName && promoCode && sex && ( */}
    </>
  );
};
