
import { styled } from '@mui/material';

export const StyledVow = styled('section')`
  margin-top: 200px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  h2 {
    font-size: 48px;
    font-weight: 700;
    text-align: center;
    @media (max-width: 725px) {
      text-align: center;
      line-height: 54px;
    }
    @media (max-width: 480px) {
      font-size: 26px;
      line-height: 29px;
    }
  }
  span {
    margin-top: 80px;
    font-family: "Tektur", serif;
    font-size: 80px;
    font-weight: 600;
    @media (max-width: 480px) {
      font-size: 72px;
    }
  }
  @media (max-width: 480px) {
    margin-top: 160px;
  }
`;

export const SubtitleText = styled('p')`
  margin-top: 12px;
  font-family: "Tektur", serif;
  font-size: 24px;
  font-weight: 400;
  text-align: center;
  background: radial-gradient(circle, #2df6de 20%, #3c5dfe 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
  margin-bottom: 65px;
  @media (max-width: 992px) {
    text-align: center;
  }
  @media (max-width: 480px) {
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 5px;
  }
`;

export const InfoWrapper = styled('div')`
  margin-top: 0px;
  @media (max-width: 480px) {
    margin-top: 24px;
  }
  background: linear-gradient(0deg, #16161f 0%, #16161f 100%);
  display: flex;
  padding: 32px;
  border-radius: 24px;
  align-items: center;
  justify-content: space-between;
  gap: 32px;
  @media (max-width: 992px) {
    flex-wrap: wrap;
    justify-content: center;
  }
`;

export const InfoItem = styled('div')`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  h4 {
    margin-top: 16px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }
`;

export const InfoItemText = styled('p')`
  margin-top: 8px;
  text-align: center;
  font-size: 16px;
  font-weight: 300;
  opacity: 0.8;
`;

export const VideoIcon = styled('img')``;

export const PrivateIcon = styled('img')``;

export const RingIcon = styled('img')``;

export const CertIcon = styled('img')``;

export const InfoBtn = styled('button')`
  padding: 17px 40px;
  margin-top: 24px;
  border: 2px solid #2df6de;
  box-shadow: 0px 0px 16px -2px rgba(45, 246, 222, 0.6);
  color: #4bfffc;
  font-size: 18px;
  font-weight: 700;
  border-radius: 63px;
  background: transparent;
  font-family: "Montserrat", sans-serif;
  transition: background 0.3s ease;
  &:hover {
    cursor:pointer;
    background: #fff;
    border-color: transparent;
    color: #000;
  }
  @media (max-width: 480px) {
    font-size: 15px;
  }
`;

export const RegionWrapper = styled('div')`
  margin-top: 40px;
  max-width: 346px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  a {
    margin-top: 20px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
    text-decoration: underline;
  }
`;

export const RegionFlags = styled('img')``;
