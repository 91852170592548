import {
  StyledWhy,
  CardWrapper,
  FirstCardItem,
  SecondCardItem,
  ThirdCardItem,
  CardHeading,
  CardBottom,
  CardText,
  BlackCardText,
} from "./styles/StyledWhy";
import {
  CircleWrapper,
  CircleItem,
  CircleText,
} from "./styles/StyledInspiration";
import { useRef } from "react";
import heart from "../../../../shared/assets/img/why/heart.svg";
import infinity from "../../../../shared/assets/img/why/infinity.svg";
import play from "../../../../shared/assets/img/inspiration/play.svg";
import firstPoster from "../../../../shared/assets/img/posters/pic_b4_1.jpg";
import secondPoster from "../../../../shared/assets/img/posters/pic_b4_2.jpg";
import thirdPoster from "../../../../shared/assets/img/posters/pic_b4_3.jpg";
import videoFirst from '../../../../shared/assets/video/circle/first_circle.mp4';
import videoSecond from '../../../../shared/assets/video/circle/second_circle.mp4';
import videoThird from '../../../../shared/assets/video/circle/third_circle.mp4';
import { useTranslation } from 'react-i18next';

const Why = () => {
  const { t, i18n } = useTranslation();
  const videoRefs = [useRef(null), useRef(null), useRef(null)];

  const handleMouseEnter = (index:any) => {
    if (videoRefs[index].current) {
      // @ts-ignore
      videoRefs[index].current.play();
    }
  };
  const handleClick = (url:any) => {
    if(url) {
      window.location.href = url;
    }
    return null;
  };

  const handleMouseLeave = (index:any) => {
    if (videoRefs[index].current) {
      // @ts-ignore
      videoRefs[index].current.pause();
      // @ts-ignore
      videoRefs[index].current.currentTime = 0;
    }
  };

  const circles = [
    {
      video: videoFirst,
      text: t('why_items_1'),
      poster: firstPoster,
      url: 'https://www.youtube.com/watch?v=7-zB87DQ3FE'
    },
    { video: videoSecond, text: t('why_items_2'), poster: secondPoster, url: false },
    { video: videoThird, text: t('why_items_3'), poster: thirdPoster, url: 'https://www.youtube.com/watch?v=4MUzb7CTFW0' },
  ];
  return (
    <StyledWhy>
      <h2>{t('why_desc_1')}</h2>
      <p>{t('why_desc_2')}</p>
      <span>
        {t('why_desc_3')} <br/> {t('why_desc_3_1')}
      </span>
      <CardWrapper>
        <FirstCardItem>
          <CardHeading>
            <img src={infinity} alt="" />
          </CardHeading>
          <CardBottom>
            <h4>{t('why_desc_4')}</h4>
            <CardText>
              {t('why_desc_5')} <br/> {t('why_desc_5_1')}
            </CardText>
          </CardBottom>
        </FirstCardItem>
        <SecondCardItem>
          <CardHeading>
            <h4>{t('why_desc_6')}</h4>
          </CardHeading>
          <CardBottom>
            <BlackCardText>
              {t('why_desc_7')} <br/> {t('why_desc_7_1')}
            </BlackCardText>
          </CardBottom>
        </SecondCardItem>
        <ThirdCardItem>
          <CardHeading>
            <img src={heart} alt="" />
          </CardHeading>
          <CardBottom>
            <h4>{t('why_desc_8')}</h4>
            <CardText>
              {t('why_desc_9')} <br/> {t('why_desc_9_1')}
            </CardText>
          </CardBottom>
        </ThirdCardItem>
      </CardWrapper>
      <CircleWrapper>
        {circles.map((item, index) => (
          <div key={item.video} style={{ textAlign: "center" }}>
            <CircleItem
              onMouseEnter={() => handleMouseEnter(index)}
              onMouseLeave={() => handleMouseLeave(index)}
              onClick={() => handleClick(item.url)}
            >
              <video
                ref={videoRefs[index]}
                src={item.video}
                muted
                loop
                playsInline
                poster={item.poster}
              ></video>
              <img src={play} alt="" />
            </CircleItem>
            <CircleText>{item.text}</CircleText>
          </div>
        ))}
      </CircleWrapper>
    </StyledWhy>
  );
};

export default Why;
