import { FC, ReactNode } from 'react';
import { Stack } from '@mui/material';
import { LoaderProvider } from 'pages/home/LoaderProvider';
import { AnimatedWrapper, BlurEllipseBlack, BlurEllipseBlue, BlurEllipsePurple } from 'shared/ui/components';
//import Header from "../../../../pages/home/ui/MainPage/Header";
import { Header } from 'widgets/header';

export interface LayoutProps {
  children?: ReactNode;
}

export const Layout: FC<LayoutProps> = ({ children }) => {
  return (
    <>
      <AnimatedWrapper />
      <LoaderProvider>
        <Header />
        <Stack
          //sx={{
          //  alignItems: 'center',
          //  mx: 'auto',
          //  maxWidth: '1170px',
          //  px: { xs: 1.5, md: 3 },
          //  mb: { xs: 3, sm: 5 },
          //}}
        >
          {children}
        </Stack>
      </LoaderProvider>
      <BlurEllipseBlack
        sx={{
          zIndex: -2,
          position: 'absolute',
          right: '-50%',
          top: { xs: '-40%', md: '-50%' },
          width: { xs: 700, md: 1000, lg: 1435 },
          height: { xs: 500, md: 700 },
        }}
      />
      <BlurEllipseBlue
        sx={{
          zIndex: -2,
          position: 'absolute',
          left: { xs: '-70%', sm: '-40%' },
          bottom: { xs: '35%', sm: 0 },
          width: { xs: 900, md: 1000, lg: 1435 },
          height: { xs: 400, md: 700 },
        }}
      />
      <BlurEllipsePurple
        sx={{
          zIndex: -2,
          position: 'absolute',
          right: { xs: '-60%', sm: '-40%' },
          bottom: 0,
          width: { xs: 700, md: 1000, lg: 1435 },
          height: { xs: 400, md: 700 },
        }}
      />
    </>
  );
};
