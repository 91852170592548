import { toast } from 'react-toastify';
import { userActions } from 'entities/user/model';
import { error, request, success } from 'shared/api';
import { SagaPayload } from 'shared/types/globals';
import {camelize, logger, pinata} from 'shared/utils';
import { call, put, takeLatest } from 'typed-redux-saga';

import { userApi } from '../ajax';

export function* uploadImageProfileSaga({ type, payload }: SagaPayload<typeof userActions.uploadImageProfile>) {
    yield* put(request(type));
    try {
        yield* put(userActions.updateUserState(payload))
        yield* put(success(type));

        //const dataUser = yield* call(userApi.updateUser, {profileImage: data.hash });
        //console.log(dataUser);
        //yield* put(userActions.updateUserState(camelize(dataUser)));
        //yield* put(success(type));

        toast.success('You have successfully upload photo');
    } catch (err) {
        yield* put(error(type, err));
        logger('uploadImageProfile', err);
        toast.error('Something went wrong');
    }
}

export default function* listener() {
    yield takeLatest(userActions.uploadImageProfile.type, uploadImageProfileSaga);
}
