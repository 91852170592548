import { useDispatch } from 'react-redux';
import { Box, Button, CircularProgress, Grid, useMediaQuery, useTheme } from '@mui/material';
import { Ring } from 'entities/ring';
import { User, UserRole, userSelector, UserStatus } from 'entities/user';
import { weddingActions, weddingSelector } from 'entities/wedding';
import { VDayLogo } from 'pages/home/ui/Icons/VDayLogo';
import { COLOR_WHITE, uiSelector } from 'shared/config';
import { useShallowSelector } from 'shared/hooks';
import { SexType } from 'shared/types';
import { Typography } from 'shared/ui/components';
import { checkRequestStatusEquality } from 'shared/utils';
import { useTranslation } from 'react-i18next';
import {useEffect} from "react";
import {modalActions, ModalType} from "../../entities/modal";

export const VowsAdd = () => {
    const { t, i18n } = useTranslation();
    const dispatch = useDispatch();
    const theme = useTheme();
    const isDownMdLayout = useMediaQuery(theme.breakpoints.down('md'));

    const uiState = useShallowSelector(uiSelector.getUI);
    const { members } = useShallowSelector(weddingSelector.getWedding);
    const { id: userId, sex: userSex } = useShallowSelector(userSelector.getUser);
    const isUserMan = userSex === SexType.man;

    const currentUser = members.find(({ user: { id } }) => id === userId);
    const partner = members.find(({ user: { id } }) => id !== userId);

    const currentUserDisplayData = {
        status: UserStatus.You,
        firstName: currentUser?.user.firstName || '',
        lastName: currentUser?.user.lastName || '',
        avatar: currentUser?.user.profileImage,
        ringImg: currentUser?.token?.meta?.imageUrl || '',
    };

    const partnerDisplayData = {
        status: partner?.user.isOnline ? UserStatus.Online : UserStatus.Offline,
        firstName: partner?.user.firstName || '',
        lastName: partner?.user.lastName || '',
        avatar: partner?.user.profileImage,
        ringImg: partner?.token?.meta?.imageUrl || '',
    };

    const firstUserDisplayData = !isUserMan ? currentUserDisplayData : partnerDisplayData;
    const secondUserDisplayData = isUserMan ? currentUserDisplayData : partnerDisplayData;

    const isConfirmPreviewRingsLoading = checkRequestStatusEquality(uiState[weddingActions.confirmPreviewRings?.type]);

    const handleNext = () => {
        dispatch(weddingActions.confirmPreviewRings());
    };

    return (
        <Grid container spacing={{ xs: 2, md: 7 }} justifyContent="center">
            <Grid item container xs={12} justifyContent="space-between" alignItems="center" spacing={2}>
                <Grid item xs={6} lg="auto">
                    <User {...firstUserDisplayData} role={UserRole.Female} />
                </Grid>
                <Grid item order={{ xs: 3, lg: 2 }} xs={12} lg="auto">
                    <Typography variant="h3" className="center">
                        {t('ring_RingPreview_p1')}
                    </Typography>
                </Grid>
                <Grid item container xs={6} lg="auto" order={{ xs: 2, lg: 3 }} justifyContent="end">
                    <User {...secondUserDisplayData} role={UserRole.Man} />
                </Grid>
            </Grid>
            <Grid
                item
                container
                xs={12}
                direction={{ xs: 'column', md: 'row' }}
                spacing={2}
                justifyContent="space-between"
                alignItems="center"
            >
                <Grid item>
                    <Ring img={isDownMdLayout ? partnerDisplayData.ringImg : firstUserDisplayData.ringImg} simple />
                </Grid>
                <Grid item>
                    <Ring img={isDownMdLayout ? currentUserDisplayData.ringImg : secondUserDisplayData.ringImg} simple />
                </Grid>
            </Grid>
            <Grid item container xs={12} justifyContent={{ xs: 'center', md: isUserMan ? 'end' : 'start' }}>
                <Typography variant="h4"> {t('ring_RingPreview_for')} {partnerDisplayData.firstName}</Typography>
            </Grid>
            {/*<Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Typography
          maxWidth={{ xs: '100%', sm: 850 }}
          letterSpacing={1}
          fontSize={{ xs: 18, sm: 24 }}
          lineHeight={1}
          className="center"
          mt={{ xs: 1.5 }}
          mb={-2}
        >
          Make your
        </Typography>
        <Box
          width={{ xs: '100%', lg: 850 }}
          sx={{
            textAlign: 'center',
            img: { maxWidth: { xs: 300, sm: 500, lg: 600 }, width: '100%' },
          }}
        >
          <VDayLogo sx={{ width: { xs: 300, sm: 500, lg: 600 }, height: 'auto' }} />
        </Box>
        <Typography
          maxWidth={{ xs: '100%', sm: 850 }}
          letterSpacing={1}
          fontSize={{ xs: 18, sm: 24 }}
          lineHeight={1}
          mt={{ xs: '-20px', sm: '-35px' }}
          mb={{ xs: 0, sm: 2 }}
          className="center"
        >
          unique
        </Typography>
      </Box>*/}
            <Grid item container xs={12} justifyContent={{ xs: 'center', md: isUserMan ? 'end' : 'start' }}>
                <Button variant="contained" onClick={handleNext} sx={{ minWidth: 189 }}>
                    {isConfirmPreviewRingsLoading ? <CircularProgress size={30} sx={{ color: COLOR_WHITE }} /> : 'Next'}
                </Button>
            </Grid>
        </Grid>
    );
};
