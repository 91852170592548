import {Component, FC, ReactNode} from 'react';
import {Box, styled, Typography, Stack, Paper, CardMedia, Link, IconButton} from '@mui/material';
import { COLOR_ACCENT, COLOR_STROKE } from 'shared/config';
import {Ring} from "../../ring";
import {User, UserRole} from "../../user";
import {ArrowIcon, HeartIcon} from "../../../shared/ui/components";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import {StyledIconButton} from "../../../features/certificate-action-buttons";

type CertificateUserProps = {
    firstName: string;
    lastName: string;
    avatar: string;
    vowsText: string;
    vowsVideo: string;
};

type UserVowsProps = {
    femaleProps: CertificateUserProps;
    manProps: CertificateUserProps;
    size?: string;
};

const StyledGrid = styled(Box)<{ active?: boolean }>`
  display: flex;
  flex-direction: column;
  width: fit-content;
  height: fit-content;
  width: 100%;

  * {
    box-sizing: border-box;
  }

  border: 2px solid;
  border-color: ${({ active }) => (active ? COLOR_ACCENT : COLOR_STROKE)};
  border-radius: 16px;
  
  &.extra-large {
    width: 100%;
    max-width: 100%;
    @media (max-width: 768px) {
        width: calc(100% - 30px)
    }  
  }

  ${({ theme }) =>
    `
      ${theme.breakpoints.down('md')} {
          width: 154px;
        } 
    `}
`;

const StyledInner = styled(Box)<{ backgroundImage?: string }>`
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: fit-content;

  border-radius: 16px;
  border-top: 2px solid #5c5a75;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;

  &.extra-large {
    width: 100%;
  }

  & > img {
    width: 100%;
    height: 100%;
    border-radius: 16px;
  }
 
`;

const StyledInfo = styled(Box)`

  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  padding-bottom: ${({ theme }) => theme.spacing(1.25)};
  gap: ${({ theme }) => theme.spacing(1)};
`;

const Item = styled(Paper)(({ theme }) => ({
    display: 'flex',
    backgroundColor: 'transparent',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    justifyContent: 'center',
    width: '15%',
}));



const ItemTextFirst = styled(Paper)`
    display: flex;
    color: #fff;
    background-color: transparent;
    font-size: 1rem;
    padding: ${({ theme }) => theme.spacing(2)};
    text-align: center;
    justify-content: center;
    border-radius: 10px;
    width: 70%;
    border: 1px solid #2DF6DE;
    position: relative;
    @media (max-width: 768px) {
        font-size: 0.725rem;
    }  
    &::after {
        content: " ";
        position: absolute;
        left: -6px;
        bottom: 29px;
        width: 12px;
        height: 12px;
        border-bottom: 1px solid #2df6de;
        border-left: 1px solid #2df6de;
        background: #18181f;
        transform: rotate(135deg) scaleX(-1);
    }
`;

const ItemTextSecond = styled(Paper)`
    display: flex;
    color: #fff;
    background-color: transparent;
    font-size: 1rem;
    padding: ${({ theme }) => theme.spacing(2)};
    text-align: center;
    justify-content: center;
    border-radius: 10px;
    width: 70%;
    border: 1px solid #2DF6DE;
    position: relative;
    @media (max-width: 768px) {
        font-size: 0.725rem;
    }  
    &::after {
        content: " ";
        position: absolute;
        right: -6px;
        bottom: 29px;
        width: 12px;
        height: 12px;
        border-top: 1px solid #2df6de;
        border-right: 1px solid #2df6de;
        background: #18181f;
        transform: rotate(135deg) scaleX(-1);
    }
`;


export const UserVows: FC<UserVowsProps> = ({
        femaleProps,
        manProps,
        size = 'extra-large',
   }) => {
    return (
        <StyledGrid className={size}>
            <Typography variant="h3" textAlign='center' padding='22px'>Your vows</Typography>
            <StyledInfo>
                <StyledInner className={size}>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            justifyContent: "center",
                            alignItems: "end",
                            padding: '20px',
                        }}>
                        <Item>
                            <User {...femaleProps} role={UserRole.Female} simple size={'medium'} noBorder={true} withRing={true} withFullName={true} firstName={femaleProps.firstName} lastName={femaleProps.lastName}  />
                        </Item>
                        <ItemTextFirst>
                            <Stack>
                                { femaleProps.vowsVideo ?
                                    <CardMedia
                                        component='video'
                                        className={'vrfrew'}
                                        image={femaleProps.vowsVideo}
                                        controls
                                    /> : ''}
                                {femaleProps.vowsText}
                            </Stack>
                        </ItemTextFirst>
                        <Item>
                            { femaleProps.vowsVideo ?
                                <><Link href={femaleProps.vowsVideo} type="video/mp4" target="_blank" rel="noopener noreferrer">
                                    <StyledIconButton>
                                        <FileDownloadOutlinedIcon />
                                    </StyledIconButton></Link></> : ''}
                        </Item>
                    </Stack>
                    <Stack
                        direction="row"
                        spacing={2}
                        sx={{
                            justifyContent: "center",
                            alignItems: "end",
                            padding: '20px',
                        }}>
                        <Item>
                            { manProps.vowsVideo ?
                                <><Link href={manProps.vowsVideo} type="video/mp4" target="_blank" rel="noopener noreferrer">
                                    <StyledIconButton>
                                        <FileDownloadOutlinedIcon />
                                    </StyledIconButton></Link></> : ''}
                        </Item>
                        <ItemTextSecond>
                            { manProps.vowsVideo ?
                                <CardMedia
                                    component='video'
                                    className={'vrfrew'}
                                    image={manProps.vowsVideo}
                                    controls
                                /> : ''}
                            {manProps.vowsText}
                        </ItemTextSecond>
                        <Item>
                            <User {...manProps} role={UserRole.Man} simple size={'medium'} noBorder={true} withRing={true} withFullName={true}  firstName={manProps.firstName} lastName={manProps.lastName}/>
                        </Item>
                    </Stack>
                </StyledInner>
            </StyledInfo>
        </StyledGrid>
    );
};
