export enum Emits {
  EngagementStart = 'engagement.start',
  CollectionSelect = 'collection.select',
  CollectionNext = 'collection.next',
  DiamondAmount = 'diamond.amount',
  DiamondNext = 'diamond.next',
  ShowroomNext = 'showroom.next',
  PaymentPay = 'payment.pay',
  PaymentSuccess = 'payment.succeeded',
  VowsStart = 'vows.start',
  WitnessGodSelect = 'witness.god.select',
  WitnessNext = 'witness.next',
  WitnessInvite = 'witness.invite',
  WitnessInviteNext = 'witness.invite.next',
  CeremonyWitnessDelete = 'ceremony.witness.delete',
  CeremonyTestify = 'ceremony.testify',
}
