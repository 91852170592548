import { StyledHero, ContentWrapper } from "./styles/StyledHero";
import Arrow from "../../../../shared/assets/img/hero/arrow.svg";
import { useTranslation } from 'react-i18next';
import {modalActions, ModalType} from "../../../../entities/modal";
import {routes, wsWeddingRoutes} from "../../../../app/providers/router/routes";
import {useDispatch} from "react-redux";
import {useNavigate} from "react-router-dom";
import {useShallowSelector} from "../../../../shared/hooks";
import {userSelector} from "../../../../entities/user";
import {weddingSelector} from "../../../../entities/wedding";

const Hero = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const { id: userId } = useShallowSelector(userSelector.getUser);
    const { state } = useShallowSelector(weddingSelector.getWedding);
    const { t } = useTranslation();

    const handleButtonClick = () => {
        if (!userId) {
            dispatch(
                modalActions.openModal({
                    type: ModalType.ConnectWallet,
                }),
            );
        } else {
            navigate(state ? wsWeddingRoutes[state] : routes.connection.path);
        }
    };
    return (
        <StyledHero>
            <ContentWrapper>
                <h1>
                    Партнерство с ресторанами <br/>и эксклюзивными локациями/отелями
                </h1>
                <p>Брендированный сервис блокчейн-браков</p>
            </ContentWrapper>
        </StyledHero>
    );
};

export default Hero;
