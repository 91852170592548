import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box, Grid } from '@mui/material';
import { modalActions, ModalType } from 'entities/modal';
import { User, UserRole, userSelector, UserStatus } from 'entities/user';
import { weddingSelector } from 'entities/wedding';
import { DiamondContainer } from 'features/ring/containers';
import { VDayLogo } from 'pages/home/ui/Icons/VDayLogo';
import { useShallowSelector } from 'shared/hooks';
import { SexType } from 'shared/types';
import { Typography } from 'shared/ui/components';
import { useTranslation } from 'react-i18next';

export const RingPrice = () => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { collection, members } = useShallowSelector(weddingSelector.getWedding);
  const { id: userId, sex: userSex } = useShallowSelector(userSelector.getUser);
  const isUserMan = userSex === SexType.man;

  const currentUser = members.find(({ user: { id } }) => id === userId);
  const partner = members.find(({ user: { id } }) => id !== userId);
  const isUserWaiting = currentUser?.state === 'waiting';
  const currentUserDisplayData = {
    status: UserStatus.You,
    firstName: currentUser?.user.firstName || '',
    lastName: currentUser?.user.firstName || '',
    avatar: currentUser?.user.profileImage,
  };

  const partnerDisplayData = {
    status: partner?.user.isOnline ? UserStatus.Online : UserStatus.Offline,
    firstName: partner?.user.firstName || '',
    lastName: partner?.user.firstName || '',
    avatar: partner?.user.profileImage,
  };
  const firstUserDisplayData = !isUserMan ? currentUserDisplayData : partnerDisplayData;
  const secondUserDisplayData = isUserMan ? currentUserDisplayData : partnerDisplayData;

  useEffect(() => {
    if (isUserWaiting) {
      dispatch(
        modalActions.openModal({
          type: ModalType.Loading,
          data: { title: t('ring_RingPrice_waiting') },
        }),
      );
    }
    return () => {
      dispatch(modalActions.closeModal());
    };
  }, [dispatch, isUserWaiting]);
  return (
    <Grid container spacing={{ xs: 2, md: 4 }} justifyContent="center" sx={{
      alignItems: 'flex-start',
      mx: 'auto',
      maxWidth: '1170px',
      px: { xs: 1.5, md: 3 },
      mb: { xs: 3, sm: 5 },
      mt: { xs: 2, sm: 3 },
    }}>
      <Grid item container xs={12} justifyContent="space-between" alignItems="center" spacing={2}>
        <Grid item xs={6} lg="auto">
          <User {...firstUserDisplayData} role={UserRole.Female} />
        </Grid>
        <Grid item order={{ xs: 3, lg: 2 }} xs={12} lg="auto">
          <Typography variant="h3" className="center">
            {t('ring_RingPrice_category')}
            <Typography variant="h3" className="capitalize" display="inline-block">
              &nbsp;{collection?.name || ''}&nbsp;
            </Typography>
            {t('ring_RingPrice_selected')}
          </Typography>
        </Grid>
        <Grid item container xs={6} lg="auto" order={{ xs: 2, lg: 3 }} justifyContent="end">
          <User {...secondUserDisplayData} role={UserRole.Man} />
        </Grid>
      </Grid>
      <Grid
        item
        container
        xs={12}
        direction="column"
        spacing={{ xs: 2, md: 5 }}
        alignItems={{ xs: 'center', md: isUserMan ? 'end' : 'start' }}
      >
        <DiamondContainer
          userPrice={currentUser?.token?.wbtcAmountUsd || 0}
          userPriceInToken={currentUser?.token?.wbtcAmount || 0}
          partnerName={partnerDisplayData.firstName || ''}
          collectionImage={collection?.imageUrl || ''}
          isUserWaiting={isUserWaiting}
        />
      </Grid>
      {/*<Box sx={{ display: { xs: 'none', sm: 'block' } }}>
        <Typography
          maxWidth={{ xs: '100%', sm: 850 }}
          letterSpacing={1}
          fontSize={{ xs: 18, sm: 24 }}
          lineHeight={1}
          className="center"
          mt={{ xs: 1.5 }}
          mb={-2}
        >
          Make your
        </Typography>
        <Box
          width={{ xs: '100%', lg: 850 }}
          sx={{
            textAlign: 'center',
            img: { maxWidth: { xs: 300, sm: 500, lg: 600 }, width: '100%' },
          }}
        >
          <VDayLogo sx={{ width: { xs: 300, sm: 500, lg: 600 }, height: 'auto' }} />
        </Box>
        <Typography
          maxWidth={{ xs: '100%', sm: 850 }}
          letterSpacing={1}
          fontSize={{ xs: 18, sm: 24 }}
          lineHeight={1}
          mt={{ xs: '-20px', sm: '-35px' }}
          mb={{ xs: 0, sm: 2 }}
          className="center"
        >
          unique
        </Typography>
      </Box>*/}
    </Grid>
  );
};
