import {Stack, Typography} from "@mui/material";
import {useTranslation} from "react-i18next";
import {useShallowSelector} from "../../shared/hooks";
import {userSelector} from "../../entities/user";
import {useEffect} from "react";
import {routes} from "../../app/providers/router/routes";
import {navigate} from "@storybook/addon-links";
import {ConnectButton} from "../../features/connect-button";
import {useNavigate} from "react-router-dom";

export const Chakras = () => {
    const { id: userId } = useShallowSelector(userSelector.getUser);
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    useEffect(() => {
        console.log(userId)
        if(userId) {
            navigate(routes.checkPromo.path);
        }
    }, [userId]);
    return (
        <Stack sx={{display: 'flex', alignItems: 'center', padding: '0 30px'}}>
            <Stack maxWidth={550}>
                <Stack direction={{ xs: 'column', sm: 'column' }} spacing={3} mt={{ xs: 4, md: 8 }} mb={1.5}>
                    <Typography>{t('chakras_1')}</Typography>
                    <Typography>{t('chakras_2')}</Typography>
                    <Stack sx={{
                        alignItems: 'center',
                        justifyContent: 'center',
                        display: 'flex',
                        width: '100%',
                        maxWidth: '100%'
                    }}>
                        <ConnectButton />
                    </Stack>
                </Stack>
            </Stack>
        </Stack>
    );
};
