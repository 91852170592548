
import { styled } from '@mui/material';

export const StyledInspiration = styled('section')`
  margin-top: 200px;
  @media (max-width: 480px) {
    margin-top: 160px;
  }
  h3 {
    font-size: 24px;
    font-weight: 300;
    text-align: center;
    opacity: 0.8;
    font-family: "Tektur", serif;
  }
`;
export const CircleWrapper = styled('div')`
  margin-top: 40px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1004px;
  margin: 40px auto 0 auto;
  @media (max-width: 768px) {
    flex-wrap: wrap;
    justify-content: center;
    gap: 25px;
  }
`;

export const CircleItem = styled('div')`
  position: relative;
  width: 185px;
  height: 185px;
  background-image: linear-gradient(90deg, #0e0e17, #0e0e17),
    linear-gradient(90deg, #2df6de, #3c5dfe);
  background-clip: padding-box, border-box;
  background-origin: border-box;
  border: 1px solid transparent;
  border-radius: 100%;
  padding: 16px;
  margin: 20px;
  overflow: hidden;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.3);
  cursor: pointer;
  transition: box-shadow 0.3s ease-in-out;
  flex-shrink: 0;
  @media (max-width: 480px) {
    width: 148px;
    height: 148px;
  }
  &:hover {
    box-shadow: 0px 0px 20px rgba(0, 0, 0, 0.6);

    img {
      display: none;
    }
  }

  img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
    opacity: 0.7;
    transition: opacity 0.3s, transform 0.3s;
    transform: scale(1);
    border-radius: 100%;

    &:hover {
      opacity: 1;
      transform: scale(1.3);
    }
  }
`;
export const CircleText = styled('h5')`
  font-size: 18px;
  font-weight: 300;
  opacity: 0.8;
  max-width: 185px;
  line-height: 20px;
  margin: 0 auto;
  width: 100%;
`;
